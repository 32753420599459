import request from "@/core/services/axios";
import qs from "qs";
export function sheetUpload(year, obj) {
  return request({
    url: "/admin/sys-file/upload/exam-sheet-" + year,
    method: "post",
    data: obj,
  });
}

// 查询答题卡列表
export function exampapersheetPage(obj, query) {
  let dataObj = qs.stringify(query);
  return request({
    url: "/exam/exampapersheet/page?" + dataObj,
    method: "post",
    data: obj,
  });
}

// 答题卡复用
export function exampapersheetDuplicate(obj) {
  return request({
    url: "/exam/exampapersheet/duplicate",
    method: "post",
    data: obj,
  });
}

// 轮询小题修改状态
export function getUpdateQuestionNumProgress(query) {
  return request({
    url: "/exam/exampapersheetmark/getUpdateQuestionNumProgress",
    method: "get",
    params: query,
  });
}

// 拆分或合并小题
export function changeQuestionNum(obj) {
  return request({
    url: "/exam/exampapersheetmark/changeQuestionNum",
    method: "post",
    data: obj,
  });
}

export function addExamPapersheet(obj) {
  return request({
    url: "/exam/exampapersheet",
    method: "post",
    data: obj,
  });
}
// 修改答题卡
export function putExamPapersheet(obj) {
  return request({
    url: "/exam/exampapersheet",
    method: "put",
    data: obj,
  });
}

export function exampapersheetmarkFindMark(obj) {
  return request({
    url: "/exam/exampapersheetmark/findMark",
    method: "post",
    data: obj,
  });
}

// 应用答题卡标注
export function exampapersheetmarkFindApply(obj) {
  let dataObj = qs.stringify(obj);
  return request({
    url: "/exam/exampapersheetmark/apply",
    method: "post",
    data: dataObj,
  });
}

//  取消答题卡
export function exampapersheetCancel(obj) {
  // let dataObj = qs.stringify(obj);
  return request({
    url: "/exam/exampapersheet/cancel",
    method: "post",
    data: obj,
  });
}

// 导出答题卡
export function exampapersheetExport(obj) {
  // let dataObj = qs.stringify(obj);
  return request({
    url: "/exam/exampapersheet/export",
    method: "post",
    data: obj,
  });
}

// 下载答题卡
export function exampapersheetDownload(obj) {
  // let dataObj = qs.stringify(obj);
  return request({
    url: "/exam/exampapersheet/download",
    method: "post",
    data: obj,
  });
}

// 修改答题卡标注
export function saveExampapersheetmark(obj) {
  return request({
    url: "/exam/exampapersheetmark",
    method: "put",
    data: obj,
  });
}

export function getExamPaperSheet(id) {
  return request({
    url: "/exam/exampapersheet/" + id,
    method: "get",
  });
}

// 答题卡PDF接口：
export function exampapersheetcontentPdfImg(id) {
  return request({
    url: "/exam/exampapersheetcontent/pdf/img/" + id,
    method: "get",
  });
}

// 答题卡html PDF接口：
export function exampapersheetcontentHtmlImg(id) {
  return request({
    url: "/exam/exampapersheetcontent/pdf/html/" + id,
    method: "get",
  });
}

// 获取是否可以清空
export function checkClear(data) {
  return request({
    url: "/exam/exampapersheet/checkClear",
    method: "get",
    params: data,
  });
}

// 清空答题卡  退卡等
export function exampapersheetClear(data) {
  return request({
    url: "exam/exampapersheet/clear",
    method: "post",
    params: data,
  });
}

// 通过答题卡id获取答题卡标记列表
export function exampapersheetmarkList(query) {
  return request({
    url: "/exam/exampapersheetmark/list",
    method: "get",
    params: query,
  });
}

// 获取试题列表
export function exampapersheetdetailV2page(query) {
  return request({
    url: "/exam/exampapersheetdetail/v2/page",
    method: "get",
    params: query,
  });
}

// 保存成试题列表
export function exampapersheetdetailUpdateV2list(id, data) {
  return request({
    url: "/exam/exampapersheetdetail/v2/update/list/" + id,
    method: "PUT",
    data: data,
  });
}

// 清空全部答题卡标注
export function exampaperSheetmarkClear(query) {
  return request({
    url: "/exam/exampapersheetmark/clear",
    method: "DELETE",
    params: query,
  });
}

// 清空全部答题卡标注
export function delExampapersheet(id) {
  return request({
    url: "/exam/exampapersheet/" + id,
    method: "DELETE",
  });
}

// 通过UUID查询答题卡内容
export function exampapersheetcontent(id) {
  return request({
    url: "/exam/exampapersheetcontent/" + id,
    method: "get",
  });
}

// 通过UUID查询虚拟的examId
export function virtual(data) {
  return request({
    url: "/exam/exampapersheet/virtual",
    method: "get",
    params: data,
  });
}

// 保存答题卡内容
export function saveExampapersheetcontent(data) {
  return request({
    url: "/exam/exampapersheetcontent",
    method: "post",
    data: data,
  });
}

//上传答题卡图片
// /exam/sheet/ours/<年份>
export function examSheetOurs(data) {
  let year = new Date().getFullYear();
  let url = "/admin/sys-file/upload/" + "exam-sheet-ours-" + year;
  return request({
    url: url,
    method: "post",
    data: data,
  });
}

// 上传图片
export function sheetUploadImg(data) {
  let year = new Date().getFullYear();
  let url = "/admin/sys-file/upload/" + "sheet-upload-img" + year;
  return request({
    url: url,
    method: "post",
    data: data,
  });
}

// 上传试题图片
export function sheetWorkImg(data) {
  let year = new Date().getFullYear();
  let url = "/admin/sys-file/upload/" + "sheet-work-img" + year;
  return request({
    url: url,
    method: "post",
    data: data,
  });
}

// 上传音频文件

export function examIneAudioUpload(data) {
  let year = new Date().getFullYear();
  let url = "/admin/sys-file/upload/" + "examine-audio" + year;
  return request({
    url: url,
    method: "post",
    data: data,
  });
}
