<template>
  <div
    ref="markSheet"
    class="markSheet edit-scroll-style"
    @mousewheel="zoomRegion($event)"
  >
    <!-- @mouseup="mouseupAll" -->
    <!--     @mousemove="mousemoveAll" -->
    <!-- @mouseup="mouseup" -->
    <div
      id="locationBox"
      ref="locationBox"
      v-loading="loadingBox"
      element-loading-text="重新加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="location-box"
      @mousemove="mousemove"
    >
      <canvas id="imgCanvas" ref="imgCanvas"></canvas>
      <!--       @mouseup="mouseup" -->
      <canvas id="lineCanvas" ref="lineCanvas" @mousedown="mousedown"> </canvas>
      <!-- 根据点位信息创建相对应的操作盒子 loadingBox -->
      <!-- 非当前页的操作盒子 主要针对追加区域 -->
      <template v-for="item in otherList">
        <!-- {{ item.priority }} -->
        <template v-for="(v, i) in item.extendList">
          <div
            v-if="
              v.priority == GET_INDEX_SHEET_MARK.priority &&
              editIndexExtend.id != v.id &&
              GET_EDIT_LIST_TYPE
            "
            :ref="'otherList' + (i + 1)"
            :key="v.id + 'otherList'"
            class="noninterference operating-box operating-box-td hidden-div"
            :class="{
              'index-hidden-box': indexHiddenBox.id == v.id,
              'show-tips': flag,
              'visb-hidden':
                (v.subjectiveType == 1 && !v.chooseQuestionLocation) ||
                (v.subjectiveType == 2 &&
                  v.chooseQuestionLocationArr.length !=
                    v.questionNumList.length),
            }"
            :style="{
              left: getline(v, 'left'),
              top: getline(v, 'top'),
              width: getline(v, 'width'),
              height: getline(v, 'height'),
            }"
          >
            <div
              v-if="
                !subjectiveTypeId ||
                (subjectiveTypeId && subjectiveTypeId == v.id)
              "
              :class="{
                'show-tips': flag,
                'show-btn-list': v.subjectiveType && !v.chooseQuestionLocation,
              }"
              class="operating-btn-box"
            >
              <el-button
                v-if="
                  GET_LABELLED_TYPE == 3 ||
                  GET_LABELLED_TYPE == 4 ||
                  GET_LABELLED_TYPE == 5 ||
                  (GET_LABELLED_TYPE == 6 && v.subType != 2)
                "
                type="text"
                @click="editSizeLine(item, 'otherList', i)"
                >调整区域</el-button
              >
              <el-button type="text" @click="delLine(i, 'otherList', item)"
                >删除</el-button
              >
            </div>
          </div>
        </template>
      </template>
      <!-- 当前页的操作盒子 -->
      <!-- {{ sheetLineList }} -->
      <template v-for="(v, i) in sheetLineList">
        <!-- 选做题 的小框 -->
        <div
          v-if="
            v.subjectiveType && v.chooseQuestionLocation && GET_EDIT_LIST_TYPE
          "
          :key="i + 'choose-question'"
          class="choose-question hidden-div noninterference"
          :style="{
            left: getLineStyle(v.chooseQuestionLocation, 'left'),
            top: getLineStyle(v.chooseQuestionLocation, 'top'),
            width: getLineStyle(v.chooseQuestionLocation, 'width'),
            height: getLineStyle(v.chooseQuestionLocation, 'height'),
          }"
        >
          <div
            v-if="
              !subjectiveTypeId ||
              (subjectiveTypeId && subjectiveTypeId == v.id)
            "
            :class="{
              'show-tips': flag,
            }"
            class="operating-btn-box"
          >
            <!-- <el-button @click="editLine(v)">编辑属性</el-button> -->
            <el-button type="text" @click="editSizeLine(v, 'choose')"
              >调整区域</el-button
            >
            <el-button type="text" @click="delLine(i, 'choose')"
              >删除</el-button
            >
          </div>
        </div>
        <!-- 特殊选做题的小框 -->
        <template
          v-if="
            v.subjectiveType == 2 &&
            v.chooseQuestionLocationArr &&
            GET_EDIT_LIST_TYPE
          "
        >
          <!-- {{ v.chooseQuestionLocationArr }} -->
          <div
            v-for="(itemK, index) in v.chooseQuestionLocationArr"
            :key="index + 'choose-question1'"
            :style="{
              left: getLineStyle(itemK, 'left'),
              top: getLineStyle(itemK, 'top'),
              width: getLineStyle(itemK, 'width'),
              height: getLineStyle(itemK, 'height'),
            }"
            class="choose-question hidden-div noninterference"
          >
            <div
              v-if="
                !subjectiveTypeId ||
                (subjectiveTypeId && subjectiveTypeId == v.id)
              "
              :class="{
                'show-tips': flag,
              }"
              class="operating-btn-box"
            >
              <el-button type="text" @click="editSizeLine(v, 'chooseTs', index)"
                >调整区域</el-button
              >
              <el-button
                v-if="index == v.chooseQuestionLocationArr.length - 1"
                type="text"
                @click="delLine(i, 'chooseTs', index)"
                >删除</el-button
              >
            </div>
          </div>
        </template>

        <!-- 补充区域的框 -->
        <template v-if="v.type == 5 && v.extendList && v.extendList.length > 0">
          <template v-for="(item, index) in v.extendList">
            <!-- 如果小框再当前页 -->
            <template
              v-if="
                item.priority == GET_INDEX_SHEET_MARK.priority &&
                GET_EDIT_LIST_TYPE
              "
            >
              <div
                :key="item.id"
                class="operating-box-td noninterference operating-box"
                :class="{
                  'index-hidden-box': editIndexExtend.id == item.id,
                  'hidden-div': true,
                }"
                :style="{
                  left: getLineStyle(item, 'left'),
                  top: getLineStyle(item, 'top'),
                  width: getLineStyle(item, 'width'),
                  height: getLineStyle(item, 'height'),
                }"
              >
                <div
                  v-if="
                    !subjectiveTypeId ||
                    (subjectiveTypeId && subjectiveTypeId == v.id)
                  "
                  :class="{
                    'show-tips': flag,
                  }"
                  class="operating-btn-box"
                >
                  <!-- <el-button @click="editLine(v)">编辑属性</el-button> -->
                  <el-button
                    type="text"
                    @click="editSizeLine(v, 'extendList', index)"
                    >调整区域</el-button
                  >
                  <el-button
                    type="text"
                    @click="delLine(index, 'extendList', v)"
                    >删除</el-button
                  >
                </div>
              </div>
            </template>
          </template>
        </template>
        <!-- 非选做题小框的其他框 -->
        <div
          v-if="!loadingBox && v.source && GET_EDIT_LIST_TYPE"
          :ref="'sheetLine' + (i + 1)"
          :key="i + 'sheetLine'"
          class="noninterference operating-box hidden-div"
          :class="{
            'index-hidden-box': indexHiddenBox.id == v.id && v.id,
            'show-tips': flag,
            'visb-hidden':
              (v.subjectiveType == 1 && !v.chooseQuestionLocation) ||
              (v.subjectiveType == 2 &&
                v.chooseQuestionLocationArr.length != v.questionNumList.length),
            'operating-box-td': v.type == 5,
          }"
          :style="{
            left: getline(v, 'left'),
            top: getline(v, 'top'),
            width: getline(v, 'width'),
            height: getline(v, 'height'),
          }"
        >
          <!-- @mouseout() -->
          <div
            v-if="
              !subjectiveTypeId ||
              (subjectiveTypeId && subjectiveTypeId == v.id)
            "
            :class="{
              'show-tips': flag,
              'show-btn-list': v.subjectiveType && !v.chooseQuestionLocation,
            }"
            class="operating-btn-box"
          >
            <el-button
              v-if="
                GET_LABELLED_TYPE == 1 ||
                GET_LABELLED_TYPE == 3 ||
                GET_LABELLED_TYPE == 4 ||
                GET_LABELLED_TYPE == 5
              "
              type="text"
              @click.stop="editLine(v)"
            >
              编辑属性
            </el-button>
            <el-button
              v-if="
                GET_LABELLED_TYPE == 3 ||
                GET_LABELLED_TYPE == 4 ||
                GET_LABELLED_TYPE == 5 ||
                (GET_LABELLED_TYPE == 6 && v.subType != 2)
              "
              type="text"
              @click.stop="editSizeLine(v)"
            >
              调整区域
            </el-button>
            <el-button
              v-if="GET_LABELLED_TYPE == 5"
              type="text"
              @click.stop="addIndexQuestion(v)"
              >追加区域</el-button
            >
            <el-button type="text" @click.stop="delLine(i)">删除</el-button>
          </div>
        </div>
      </template>
      <!-- 可拖动大小的盒子 -->
      <div
        v-if="indexHiddenBox.id && !editIndexExtend.id"
        ref="rTextareaWrap"
        class="operating-box edit-operating-box"
        :style="{
          left: getline(indexHiddenBox, 'left'),
          top: getline(indexHiddenBox, 'top'),
          width: getline(indexHiddenBox, 'width'),
          height: getline(indexHiddenBox, 'height'),
        }"
        @mousemove.self="mousemoveTest($event)"
        @mousedown.self="mousedownTest($event)"
        @mouseup.self="mouseupTest($event)"
        @mouseout.self="mouseout()"
      >
        <div
          class="circle-box circle-left"
          @mousedown.stop="handleMouseDown($event, 'left')"
        ></div>
        <div
          class="circle-box circle-right"
          @mousedown.stop="handleMouseDown($event, 'right')"
        ></div>
        <div
          class="circle-box circle-top"
          @mousedown.stop="handleMouseDown($event, 'top')"
        ></div>
        <div
          class="circle-box circle-bottom"
          @mousedown.stop="handleMouseDown($event, 'bottom')"
        ></div>
      </div>
      <div
        v-if="editIndexExtend.id"
        ref="rTextareaWrap"
        class="operating-box edit-operating-box"
        :style="{
          left: getline(editIndexExtend, 'left'),
          top: getline(editIndexExtend, 'top'),
          width: getline(editIndexExtend, 'width'),
          height: getline(editIndexExtend, 'height'),
        }"
        @mousemove.self="mousemoveTest($event)"
        @mousedown.self="mousedownTest($event)"
        @mouseup.self="mouseupTest($event)"
        @mouseout.self="mouseout()"
      >
        <div
          class="circle-box circle-left"
          @mousedown="handleMouseDown($event, 'left')"
        ></div>
        <div
          class="circle-box circle-right"
          @mousedown="handleMouseDown($event, 'right')"
        ></div>
        <div
          class="circle-box circle-top"
          @mousedown="handleMouseDown($event, 'top')"
        ></div>
        <div
          class="circle-box circle-bottom"
          @mousedown="handleMouseDown($event, 'bottom')"
        ></div>
      </div>
    </div>

    <!-- 根据点位信息创建相对应的操作盒子 -->
    <el-drawer
      class="model-message-box"
      title="模版结构信息"
      :modal="false"
      :show-close="false"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <div v-if="locationType != 3" class="postion-type-box">
          定位形状：
          <el-radio-group v-model="subType">
            <el-radio :label="1">矩形</el-radio>
            <el-radio :label="2">文字</el-radio>
          </el-radio-group>
        </div>
        <div v-if="locationType == 3" class="postion-tips">
          注意：所有定位线形状必须保持一致！
        </div>
        <div v-if="locationType != 3" class="postion-type-box">框选位置：</div>
        <div v-if="locationType != 3" class="border-radio">
          <div
            v-for="(v, i) in positionList"
            :key="i"
            class="border-radio-list"
            :class="{
              'current-position': v.value == currentPosition,
              'disabled-list': setListDisable(v),
            }"
            @click="changePosition(v.value)"
          >
            {{ v.label }}
            <i class="el-icon-check"></i>
          </div>
          <!-- <div     class="border-radio-list"></div> -->
        </div>
        <div class="model-btn-box">
          <el-button @click="modelClose">取 消</el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="saveLineData()"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 编辑考号 -->
    <el-drawer
      class="model-message-box student-exam-model"
      title="编辑考号"
      :modal="false"
      :show-close="false"
      :visible.sync="studentExamIdDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <div class="model-item">
          <div class="title">考号类型：</div>
          <el-radio-group v-model="subType" size="mini">
            <el-radio
              v-for="(v, i) in optionsTypeList"
              :key="i"
              :label="v.value"
              border
              size="medium"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="subType == 1" class="model-item">
          <div class="title">排列方式：</div>
          <el-radio-group v-model="optionsArrangementMode" size="mini">
            <el-radio
              v-for="(v, i) in optionsArrangeTypeList"
              :key="i"
              :label="v.value"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div v-if="subType == 1" class="model-item">
          <div class="title">考号长度：</div>
          <el-input-number
            v-model="optionsLength"
            :min="1"
            :max="optionsMaxLength"
            label="描述文字"
          ></el-input-number>
        </div>
        <div v-if="subType == 1" class="model-item student-examId-style">
          <div class="title">考号样式：</div>
          <el-radio-group v-model="optionsStyle" size="mini">
            <el-radio
              v-for="(v, i) in studentExamIdStyleList"
              :key="i"
              :label="v.value"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div style="width: 76px; text-align: right">示意图：</div>
        <div
          v-if="subType == 1"
          class="student-exam-id-style edit-scroll-style"
          :class="{ 'style-item-h': optionsArrangementMode == 2 }"
        >
          <div v-for="v in optionsLength" :key="v" class="style-item">
            <template v-if="optionsStyle == 1">
              <div v-for="(m, n) in 10" :key="m">{{ n }}</div>
            </template>
            <template v-if="optionsStyle == 2">
              <div v-for="m in 10" :key="m">
                <template v-if="m == 10">0</template>
                <template v-else>{{ m }}</template>
              </div>
            </template>
          </div>
        </div>
        <div v-if="subType == 2" class="student-exam-img-style">
          <div>贴条形码区</div>
        </div>
        <!-- <div v-if="subType == 3" class="student-exam-img-style">
          <div>手写考号</div>
        </div> -->
        <div class="model-btn-box">
          <el-button @click="modelClose">取 消</el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="saveLineData()"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 编辑尺寸 start -->
    <el-drawer
      class="model-message-box student-exam-model"
      title="编辑尺寸"
      :modal="false"
      :show-close="false"
      :visible.sync="editSizeDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <div class="edit-size-list">
          <div class="edit-size-title"><span>1</span>首选项左上角横坐标：</div>
          <el-input-number
            v-model="leftTopWidthSize"
            label="描述文字"
            @change="handleChange"
          ></el-input-number>
        </div>
        <div class="edit-size-list">
          <div class="edit-size-title"><span>2</span>首选项左上角纵坐标：</div>
          <el-input-number
            v-model="leftTopHeightSize"
            label="描述文字"
            @change="handleChange"
          ></el-input-number>
        </div>
        <div class="edit-size-list">
          <div class="edit-size-title"><span>3</span>选项宽度：</div>
          <el-input-number
            v-model="sizeWidth"
            label="描述文字"
            @change="handleChange"
          ></el-input-number>
        </div>
        <div class="edit-size-list">
          <div class="edit-size-title"><span>4</span>选项高度：</div>
          <el-input-number
            v-model="sizeHeight"
            label="描述文字"
            @change="handleChange"
          ></el-input-number>
        </div>
        <template
          v-if="indexType != 6 || (indexType == 6 && indexSubType != 1)"
        >
          <div class="edit-size-list">
            <div class="edit-size-title"><span>5</span>横向选项间距：</div>
            <el-input-number
              v-model="sizeRight"
              label="描述文字"
              @change="handleChange"
            ></el-input-number>
          </div>
          <div class="edit-size-list">
            <div class="edit-size-title"><span>6</span>纵向选项间距：</div>
            <el-input-number
              v-model="sizeBottom"
              label="描述文字"
              @change="handleChange"
            ></el-input-number>
          </div>
        </template>
        <div class="model-btn-box">
          <el-button @click="cancelEdit()">取 消</el-button>
          <el-button type="primary" :loading="btnLoading" @click="submitEdit"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 编辑尺寸 end -->
    <!-- 客观题参数 -->
    <el-drawer
      class="model-message-box student-exam-model"
      title="编辑客观题"
      :modal="false"
      :show-close="false"
      :visible.sync="choiceQuestionDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <div class="model-item">
          <div class="title">题型：</div>
          <el-radio-group
            v-model="subType"
            size="mini"
            @change="changeOptionsType"
          >
            <el-radio
              v-for="(v, i) in choiceQuestionTypeList"
              :key="i"
              :label="v.value"
              border
              size="medium"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="model-item">
          <div class="title">分数：</div>
          <el-input-number
            v-model="score"
            :min="0.5"
            label="描述文字"
          ></el-input-number>
        </div>
        <div v-if="subType == 2" class="model-item">
          <div class="title">漏选得分：</div>
          <el-input-number
            v-model="scoreMiss"
            :min="0"
            label="描述文字"
          ></el-input-number>
        </div>
        <div class="model-item">
          <div class="title">排列方式：</div>
          <el-radio-group v-model="optionsArrangementMode" size="mini">
            <el-radio
              v-for="(v, i) in optionsArrangeTypeList"
              :key="i"
              :label="v.value"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="model-item">
          <div class="title">选项个数：</div>
          <el-input-number
            v-model="optionsLength"
            :min="1"
            :max="optionsMaxLength"
            label="描述文字"
          ></el-input-number>
        </div>

        <div class="model-item">
          <div class="title">是否连续：</div>
          <!-- 添加判断 如果存在编号或者间隔  禁用这个选项-->
          <el-radio-group
            v-model="optionsContinuous"
            size="mini"
            :disabled="questionSpacing > 1 || questionSerialNumber !== ''"
            @change="checkOptionType"
          >
            <el-radio
              v-for="(v, i) in optionsContinuousList"
              :key="i"
              :label="v.value"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>

        <div class="model-item">
          <div class="title">题量：</div>
          <el-input-number
            v-model="questionNum"
            :min="1"
            :max="10"
            label="描述文字"
            @change="checkQuestionNum"
          ></el-input-number>
        </div>
        <div class="model-item">
          <div class="title">题号间隔：</div>
          <el-input-number
            v-model="questionSpacing"
            :min="1"
            :max="20"
            label="描述文字"
            @change="checkQuestionNumSpacing()"
          ></el-input-number>
        </div>
        <div v-if="optionsContinuous == 2" class="model-item">
          <div class="title">题号编号：</div>
          <el-input
            v-model="questionSerialNumber"
            placeholder=""
            style="width: 80px"
            @change="checkQuestionNumSpacing()"
          >
          </el-input>
        </div>
        <div class="model-item">
          <div class="title">题号：</div>
          <div v-if="optionsContinuous == 1" class="question-num">
            <el-input
              v-model="questionNumStart"
              placeholder=""
              oninput="value=value.replace(/[^0-9]/g,'')"
              @input="checkQuestionNum1"
              @blur="questionNumStart = $event.target.value"
            >
            </el-input>
            <i class="el-icon-minus"></i>
            <el-input v-model="questionNumEnd" disabled placeholder="">
            </el-input>
          </div>
          <div v-else class="question-num question-num-list">
            <!-- <template > -->
            <el-input
              v-for="(item, i) in questionNumList"
              :key="i"
              v-model="item.examQuestionNumber"
              placeholder=""
            >
            </el-input>
            <!-- </template> -->
          </div>
        </div>
        <div style="width: 76px; text-align: right">示意图：</div>
        <div
          class="student-exam-id-style edit-scroll-style"
          style="height: auto"
          :class="{ 'style-item-h': optionsArrangementMode == 2 }"
        >
          <div v-for="v in questionNum" :key="v" class="style-item">
            <template v-if="subType != 3">
              <div v-for="(m, n) in optionsLength" :key="m">
                {{ optionListA[n] }}
              </div>
            </template>
            <template v-else>
              <div v-for="m in optionsLength" :key="m">
                {{ optionListT[m - 1] }}
              </div>
            </template>
          </div>
        </div>
        <div class="model-btn-box">
          <el-button @click="modelClose()">取 消</el-button>
          <el-button type="primary" :loading="btnLoading" @click="saveLineData"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 主观题 -->
    <el-drawer
      class="model-message-box student-exam-model"
      title="编辑主观题"
      :modal="false"
      :show-close="false"
      :visible.sync="subjectiveQuestionDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <!-- <div class="model-item tx-list">
          <div class="title">题型：</div>
          <el-radio-group
            v-model="subType"
            size="mini"
            @change="changeOptionsType"
          >
            <el-radio
              v-for="(v, i) in subjectiveQuestionTypeList"
              :key="i"
              :label="v.value"
              border
              size="medium"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div> -->
        <div class="model-item">
          <div class="title">分数：</div>
          <el-input-number
            v-model="score"
            :min="1"
            label="描述文字"
          ></el-input-number>
        </div>
        <div v-if="subjectiveType == 1" class="model-item">
          <div class="title">题量：</div>
          <el-input-number
            v-model="questionNum"
            :min="1"
            :max="10"
            label="描述文字"
            @change="setQuestionOrder1"
          ></el-input-number>
        </div>
        <div class="model-item">
          <div class="title">题号：</div>
          <div class="question-num">
            <el-input
              v-for="(item, i) in questionNumList"
              :key="i"
              v-model="item.examQuestionNumber"
              placeholder=""
              style="margin-right: 12px; width: 88px"
            >
            </el-input>

            <!-- <el-input v-model="questionNumStart" placeholder=""> </el-input> -->
            <!-- {{ questionNumStart }} -->
            <!-- <i class="el-icon-minus"></i> -->
            <!-- <el-input v-model="questionNumEnd" placeholder=""> </el-input> -->
          </div>
        </div>

        <div v-if="subType == 2" class="model-item">
          <div class="title">是否选做：</div>
          <el-radio-group
            v-model="subjectiveType"
            size="mini"
            @change="checkQuestionType"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <!-- <div v-if="subType == 2 && subjectiveType == 1">
          <el-checkbox v-model="irregular">不规律选做题</el-checkbox>
          <p style="color: red; font-size: 12px">
            选做题识别标记需要单独框选勾选此项。
          </p>
        </div> -->
        <div
          v-if="subType == 2 && subjectiveType == 1"
          class="subjective-type-tips"
        >
          <span>!</span>请框选选做题识别标记！
        </div>

        <div
          v-if="subjectiveType == 1 && irregular == false"
          class="optional-box"
        >
          <div class="model-item">
            <div class="title">排列方式：</div>
            <el-radio-group v-model="optionsArrangementMode" size="mini">
              <el-radio
                v-for="(v, i) in optionsArrangeTypeList"
                :key="i"
                :label="v.value"
              >
                {{ v.label }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="model-item">
            <!-- <div class="title">不规律选做题：</div>
            <el-radio-group v-model="optionsArrangementMode" size="mini">
              <el-radio
                v-for="(v, i) in optionsArrangeTypeList"
                :key="i"
                :label="v.value"
              >
                {{ v.label }}
              </el-radio>
            </el-radio-group> -->
          </div>
        </div>
        <!-- 是否选做： -->
        <div class="model-btn-box">
          <el-button @click="modelClose()">取 消</el-button>
          <el-button type="primary" :loading="btnLoading" @click="saveLineData"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 特殊标记-->
    <el-drawer
      class="model-message-box student-exam-model"
      title="特殊标记"
      :modal="false"
      :show-close="false"
      :visible.sync="specialDrawer"
      direction="rtl"
      :before-close="handleClose"
      size="440px"
    >
      <div class="poistion-box">
        <div class="model-item">
          <div class="title">排列方式：</div>
          <el-radio-group v-model="optionsArrangementMode" size="mini">
            <el-radio
              v-for="(v, i) in optionsArrangeTypeList"
              :key="i"
              :label="v.value"
            >
              {{ v.label }}
            </el-radio>
          </el-radio-group>
        </div>
        <div
          class="student-exam-id-style edit-scroll-style"
          :class="{ 'style-item-h': optionsArrangementMode == 2 }"
        >
          <div class="style-item">
            <div style="padding: 0 10px">A</div>
            <div style="padding: 0 10px">B</div>
          </div>
        </div>
        <div class="model-btn-box">
          <el-button @click="modelClose()">取 消</el-button>
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="saveLineData()"
            >确 定</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { optionItem } from "@/core/util/constdata";
const optionListT = ["T", "F"];
// 定位点 类型
const positionList = [
  {
    label: "左上角",
    value: "leftTopPoint",
  },
  {
    label: "右上角",
    value: "rightTopPoint",
  },
  {
    label: "左下角",
    value: "leftBottomPoint",
  },
  {
    label: "右下角",
    value: "rightBottomPoint",
  },
  {
    label: "客观题参考点",
    value: "assistantPoint",
  },
];
// 考号类型
const optionsTypeList = [
  {
    label: "填涂式",
    value: 1,
  },
  {
    label: "条形码",
    value: 2,
  },
  {
    label: "手写考号",
    value: 3,
  },
];
// 选择题类型
const choiceQuestionTypeList = [
  {
    label: "单选题",
    value: 1,
  },
  {
    label: "多选题",
    value: 2,
  },
  {
    label: "判断题",
    value: 3,
  },
];
//主观题类型
const subjectiveQuestionTypeList = [
  {
    label: "填空题",
    value: 1,
  },
  {
    label: "解答题",
    value: 2,
  },
  {
    label: "语文作文",
    value: 3,
  },
  {
    label: "英语作文",
    value: 4,
  },
];
// 选项是否连续
const optionsContinuousList = [
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 2,
  },
];
// 排列方式
const optionsArrangeTypeList = [
  {
    label: "横向",
    value: 1,
  },
  {
    label: "纵向",
    value: 2,
  },
];
// 考号顺序
const studentExamIdStyleList = [
  {
    label: "0 1 2 3 4 5 6 7 8 9",
    value: 1,
  },
  {
    label: "1 2 3 4 5 6 7 8 9 0",
    value: 2,
  },
];
// 调整框的最大 最小值
const TextAreaWrap = {
  MaxHeight: 2000,
  MinHeight: 20,
  MaxWidth: 2000,
  MinWidth: 20,
};
import { markTypeList } from "@/core/util/constdata";
import { mapGetters } from "vuex";
import {
  exampapersheetmarkFindMark,
  saveExampapersheetmark,
} from "@/core/api/exam/sheet";
export default {
  name: "MarkSheet",
  props: {
    locationType: {
      type: Number,
      default: 0,
    },
    specialLocationType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loadingBox: false,
      subjectiveTypeId: "",
      // oldSizeLine: {},
      indexType: "",
      indexSubType: "",
      flag: false,
      listKey: 1,
      optionListA: optionItem,
      optionListT: optionListT,
      choiceQuestionTypeList: choiceQuestionTypeList,
      subjectiveQuestionTypeList: subjectiveQuestionTypeList,
      optionsTypeList: optionsTypeList,
      optionsContinuousList: optionsContinuousList,
      optionsArrangeTypeList: optionsArrangeTypeList,
      studentExamIdStyleList: studentExamIdStyleList,
      // 特殊标记
      specialDrawer: false,
      // 主观题
      subjectiveQuestionDrawer: false,
      subjectiveType: 0,
      // 分数
      score: 5,
      scoreMiss: 0,
      // 编辑客观题
      choiceQuestionDrawer: false,
      questionNumList: [],
      questionNumStart: 0,
      questionNumEnd: 0,

      // 编辑尺寸
      editSizeDrawer: false,
      leftTopWidthSize: 0,
      leftTopHeightSize: 0,
      sizeWidth: 0,
      sizeHeight: 0,
      sizeRight: 0,
      sizeBottom: 0,
      leftTopSize: 0,
      // 编辑
      // 编辑考号弹出层
      studentExamIdDrawer: false,
      // 选项类型：
      subType: 1,
      // 选项排列方式
      optionsArrangementMode: 2,
      // 选项样式：
      optionsStyle: 1,
      // 选项长度
      optionsLength: 4,
      // 选项个数
      questionNum: 5,
      questionSpacing: 1,

      questionSerialNumber: "",
      // questionSpacing
      //  questionSerialNumber
      // 选项是否连续
      optionsContinuous: 1,
      optionsMaxLength: 4,
      // canvas: {},
      // 定位点列表
      positionList: positionList,
      markTypeList: markTypeList,
      irregular: false,
      // 当前定位点
      currentPosition: "leftTopPoint",

      // 页面缩放比例
      proportion: 1,
      // 右侧抽屉盒子
      saveType: "save",
      saveData: {},
      drawer: false,
      btnLoading: false,
      sheetLineList: [],
      indexImgUrl: "",
      // 当前答题卡 基本标点信息
      indexSheetMark: {},
      allSheetLine: [],
      // 隐藏二次渲染的盒子
      indexHiddenBox: {},
      lineData: {
        //  轴位置
        left: "",
        //  y轴位置
        top: "",
        //  绘制出来的盒子宽度
        width: "",
        //  绘制出来的盒子高度
        height: "",
      },
      //非当前页的判断
      indexExtendQuestion: {},
      editIndexExtend: {},
      otherList: [],
    };
  },
  computed: {
    ...mapGetters([
      "GET_LABELLED_TYPE",
      "GET_INDEX_SHEET_MARK",
      "GET_SHEET_LINE_LIST",
      "GET_QUESTION_LIST",
      "GET_EDIT_LIST_TYPE",
      "GET_SHEET_MARK_LIST",
      "GET_OLDSHEET_MARK_LIST",
      "GET_SHEET_QUESTION_MAX",
      "GET_LAST_QUESTION",
    ]),
  },
  watch: {
    GET_LABELLED_TYPE() {
      this.questionNumList = [];
      this.checkImg();
      if (this.GET_LABELLED_TYPE == 4) {
        this.optionsLength = 4;
        this.questionNum = 5;
      }
    },
  },
  mounted() {
    // 绑定鼠标移动事件
    document.addEventListener("mousemove", this.mousemoveAll);
    document.addEventListener("mouseup", this.mouseupAll);
    // 鼠标点击
    window.addEventListener("mousedown", this.handleonmousedown);
    this.indexSheetMark = JSON.parse(JSON.stringify(this.GET_INDEX_SHEET_MARK));
    if (this.indexSheetMark.urlAdjust) {
      this.indexImgUrl = this.indexSheetMark.urlAdjust;
      this.onloadImg();
    }
  },
  destroyed() {
    // 移除鼠标移动事件
    document.removeEventListener("mousemove", this.mousemoveAll);
    // 移除鼠标放开事件
    document.removeEventListener("mouseup", this.mouseupAll);
    window.removeEventListener("mousedown", this.handleonmousedown);
  },
  methods: {
    //鼠标长按移动盒子 start
    mouseout() {
      this.dragType = false;
      // this.indexQuestion = {};
    },
    // 当鼠标指针在指定的元素中移动时
    mousemoveTest($event) {
      if (!this.dragType) {
        return;
      }
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      const { endMouseTop, endMouseLeft } = this.mouseDragState;

      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - $event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - $event.clientX) * 100).toString(), 10) / 100
      );
      let key = "indexHiddenBox";
      if (this.editIndexExtend.id) {
        key = "editIndexExtend";
      }
      if (endMouseLeft <= $event.clientX) {
        this[key].source.left =
          this[key].source.left + distanceLeft * this.proportion;
      } else {
        this[key].source.left =
          this[key].source.left - distanceLeft * this.proportion;
      }
      //  最小值限制 start
      if (endMouseTop <= $event.clientY) {
        this[key].source.top =
          this[key].source.top + distance * this.proportion;
      } else {
        this[key].source.top =
          this[key].source.top - distance * this.proportion;
      }
      if (this[key].source.top <= 1) {
        this[key].source.top = 1;
      }
      if (this[key].source.left <= 1) {
        this[key].source.left = 1;
      }
      // 最小值限制 end
      // 最大值限制 start
      // 到右侧最大距离
      if (
        this[key].source.left + this[key].source.width >=
        this.viewWidth * this.proportion
      ) {
        this[key].source.left =
          this.viewWidth * this.proportion - this[key].source.width - 1;
      }
      // 到下方最大距离
      if (
        this[key].source.top + this[key].source.height >=
        this.viewHeight * this.proportion
      ) {
        this[key].source.top =
          this.viewHeight * this.proportion - this[key].source.height - 1;
      }
      // console.log(this.indexQuestion);
      // 更新鼠标最后移动的位置（Y轴）
      this.mouseDragState.endMouseTop = $event.clientY;
      this.mouseDragState.endMouseLeft = $event.clientX;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
      this.setLine(1);
      this.setOtherLine();
    },
    // 按下触发 event 鼠标事件v 当前点击的盒子  type 判断是那种拖动 indexF追加区域情况，当前父元素
    mousedownTest($event) {
      let str = "";
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      if (str.indexOf("operating-btn-box") != -1) {
        return;
      }
      // console.log(this.sheetLineList);
      this.mouseDragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: $event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: $event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: $event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: $event.clientX,
      };
      this.dragType = true;
      // this.indexQuestion = Object.assign({}, v);
      // this.indexF = Object.assign({}, indexF);
    },
    // 抬起触发
    async mouseupTest($event) {
      // operating-btn-box
      // console.log($event);
      let str = "";

      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      this.dragType = false;
      await new Promise((resolve) => {
        this.sheetLineList.forEach((item, index) => {
          if (!this.editIndexExtend.id) {
            if (item.id == this.indexHiddenBox.id) {
              item = JSON.parse(JSON.stringify(item));
            }
          } else {
            // 如果是追加区域
            // 如果是追加非当前页
            if (item.extendList) {
              item.extendList.map((index) => {
                if (index.id == this.editIndexExtend.id) {
                  item = JSON.parse(JSON.stringify(this.editIndexExtend));
                }
              });
            }
          }
          if (index == this.sheetLineList.length - 1) resolve();
        });
        if (this.sheetLineList.length == 0) {
          resolve();
        }
      });
      if (this.indexEditType) {
        // 根据试题的 priority 页码 组装好需要提交的数据
        // 获取到修改的项
        if (this.markSubjectiveEdit.length == 0) {
          this.GET_SHEET_MARK_LIST.forEach((item) => {
            if (item.priority == this.subSizeLine.priority) {
              this.markSubjectiveEdit = JSON.parse(item.markSubjective);
            }
          });
        }
        // 获取到需要修改的项
        this.markSubjectiveEdit.map((item) => {
          if (item.id == this.subSizeLine.id) {
            item.extendList.map((index, key) => {
              if (index.id == this.editIndexExtend.id) {
                item.extendList[key] = this.editIndexExtend;
              }
            });
          }
        });
      }
      // console.log(this.indexEditType);
    },
    // end
    handleonmousedown($event) {
      let str = "";
      // console.log($event);
      // console.log($event.composedPath && $event.composedPath());
      const urlPath =
        $event.path || ($event.composedPath && $event.composedPath());
      urlPath.map((index) => {
        str = str + index.className;
      });
      // console.log();
      if (str.indexOf("noninterference") != -1 && !this.lineType) {
        if (urlPath[0].localName == "span") {
          return;
        }
        this.setClickTime = setTimeout(() => {
          let { scrollLeft, scrollTop } = this.$refs.markSheet;
          let data = {
            offsetX: $event.pageX - this.oldLeft + scrollLeft,
            offsetY: $event.pageY - 174 + scrollTop,
          };

          if (str.indexOf("edit-operating-box") != -1) {
            return;
          }
          this.mousedown(data);
        }, 50);
      }
      if (str.indexOf("edit-operating-box") == -1 && this.lineType) {
        this.lineType = false;

        if (
          this.editIndexExtend.id ||
          this.indexHiddenBox.id ||
          (this.oldSizeLine && this.oldSizeLine.id)
        ) {
          // 更新下最新数据
          // console.log("22564d5sa64d6a5s");
          this.upDataNewList();
        }
        this.indexHiddenBox = {};
        this.editIndexExtend = {};
        this.subSizeLine = {};
        this.oldSizeLine = {};
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.setAllLineData();
        this.setLine();
        // // 移除鼠标移动事件
        document.removeEventListener("mousemove", this.handleMouseMove);
        // 移除鼠标放开事件
        document.removeEventListener("mouseup", this.handleMouseUp);
        // 允许用户选择网页中文字;
        document.onselectstart = null;
        // 允许用户拖动元素
        document.ondragstart = null;
      }
    },
    // 更新下新数据
    async upDataNewList() {
      // console.log("22564d5sa64d6a5s");
      const subSizeLine = JSON.parse(JSON.stringify(this.subSizeLine));
      // const editIndexExtend = JSON.parse(JSON.stringify(this.editIndexExtend));
      const indexEditType = JSON.parse(JSON.stringify(this.indexEditType || 0));
      if (indexEditType) {
        // 根据试题的 priority 页码 组装好需要提交的数据
        let markSubjective = this.markSubjectiveEdit;
        let id = "";
        // 获取到修改的项
        await new Promise((resolve) => {
          this.GET_SHEET_MARK_LIST.forEach((item) => {
            if (item.priority == subSizeLine.priority) {
              id = item.id;
              resolve();
            }
          });
        });

        this.saveExampapersheetmark(markSubjective, id, subSizeLine.id);
      } else {
        this.$store.commit("SET_SHEET_LINE_LIST", this.sheetLineList);
        this.$emit("submitIndexImg", 1, subSizeLine.id);
      }
    },
    // 追加区域
    addIndexQuestion(v) {
      if (this.subjectiveTypeId) {
        this.$message({
          message: `请先框选选做题区域再进行追加区域`,
          type: "success",
          showClose: true,
        });
        return;
      }
      this.indexExtendQuestion = Object.assign({}, v);
      let array = this.indexExtendQuestion.questionNumList.map(
        (item) => item.examQuestionNumber
      );
      this.$message({
        message: `请框选${array.join(",")}题其他区域`,
        type: "success",
        showClose: true,
      });
    },
    setZoom(type) {
      // console.log(type);
      if (type) {
        if (this.viewWidth < 4000) {
          this.viewWidth = this.viewWidth / 0.9;
          this.viewHeight = this.viewHeight / 0.9;
        }
      } else {
        if (this.viewWidth > 500) {
          this.viewWidth = this.viewWidth * 0.9;
          this.viewHeight = this.viewHeight / 0.9;
        }
      }
      this.proportion = this.oldWidth / this.viewWidth;
      this.setZoomView();
    },
    setZoomView() {
      // imgCanvas lineCanvas
      // 设置装cavans的盒子大小
      this.$refs.locationBox.style.height =
        (this.oldHeight * this.viewWidth) / this.oldWidth + "px";
      this.$refs.locationBox.style.width = this.viewWidth + "px";
      // this.$refs.locationBox.style.left = (this.viewWidth * 0.1) / 2 + "px";
      this.$refs.imgCanvas.height =
        (this.oldHeight * this.viewWidth) / this.oldWidth;
      this.$refs.imgCanvas.width = this.viewWidth;
      this.$refs.imgCanvas.style.backgroundSize = "100% 100%";
      this.$refs.lineCanvas.height =
        (this.oldHeight * this.viewWidth) / this.oldWidth;
      this.$refs.lineCanvas.width = this.viewWidth;
      this.viewHeight = this.$refs.lineCanvas.height;
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新渲染点
      this.setAllLineData();
      this.setLine();
    },
    // 缩放区域
    zoomRegion($event) {
      if ($event.ctrlKey) {
        $event.preventDefault();
        if ($event.wheelDelta > 0) {
          if (this.viewWidth < 4000) {
            this.viewWidth = this.viewWidth / 0.9;
            this.viewHeight = this.viewHeight / 0.9;
          }
        } else {
          if (this.viewWidth > 500) {
            this.viewWidth = this.viewWidth * 0.9;
            this.viewHeight = this.viewHeight / 0.9;
          }
        }
        this.proportion = this.oldWidth / this.viewWidth;
        this.setZoomView();
        return;
      }
    },
    checkQuestionType(val) {
      if (val == 1) {
        if (this.questionNumEnd == this.questionNumStart) {
          this.questionNumEnd = this.questionNumEnd + 1;
        }
      }
      // console.log(val);
    },
    /**
     * 处理鼠标按下事件
     *
     * @param {MouseEvent} 鼠标事件
     */
    handleMouseDown(event, type) {
      // console.log(event);
      // console.log(type);
      this.editListType = type;
      // 禁止用户选择网页中文字
      document.onselectstart = () => false;
      // 禁止用户拖动元素
      document.ondragstart = () => false;

      // 保存鼠标最后移动的位置（Y轴）
      this.dragState = {
        // 鼠标开始移动的位置（Y轴）
        startMouseTop: event.clientY,
        // 鼠标最后到的位置（Y轴）
        endMouseTop: event.clientY,
        // 鼠标开始移动的位置(x轴)
        startMouseLeft: event.clientX,
        // 鼠标最后到的位置（X轴）
        endMouseLeft: event.clientX,
      };

      // 绑定鼠标移动事件
      document.addEventListener("mousemove", this.handleMouseMove);
      // 绑定鼠标放开事件
      document.addEventListener("mouseup", this.handleMouseUp);
    },
    /**
     * 处理鼠标移动事件
     *
     * @param {MouseEvent} 鼠标事件
     */
    handleMouseMove(event) {
      const { rTextareaWrap } = this.$refs;
      // console.log(rTextareaWrap);
      if (
        !rTextareaWrap ||
        !this.dragState ||
        !this.dragState.endMouseTop ||
        !this.dragState.endMouseLeft
      ) {
        return;
      }
      // 获取鼠标最后移动的位置（Y轴）
      const { endMouseTop, endMouseLeft } = this.dragState;
      const { scrollLeft, scrollTop } = this.$refs.markSheet;

      // 获取当前的高度
      const oldTextAreaHeight = rTextareaWrap.offsetHeight;
      const oldTextAreaWidth = rTextareaWrap.offsetWidth;
      // 当前位置到顶部
      let oldTextAreaTop = rTextareaWrap.offsetTop;
      // 到左边的距离
      let oldTextAreaLeft = rTextareaWrap.offsetLeft;
      // 新的框高度
      let newTextAreaHeight = 0;
      let newTextAreaWidth = 0;

      // 计算鼠标移动的距离
      const distance = Math.abs(
        parseInt(((endMouseTop - event.clientY) * 100).toString(), 10) / 100
      );
      const distanceLeft = Math.abs(
        parseInt(((endMouseLeft - event.clientX) * 100).toString(), 10) / 100
      );
      // console.log(event);
      // 若鼠标向上移动
      if (endMouseTop < event.clientY) {
        // 发送框高度达到最大
        // if (oldTextAreaHeight >= TextAreaWrap.MaxHeight) {
        //   // 修改光标为可被向下移动
        //   // rTextareaWrap.style.cursor = "s-resize";
        //   return false;
        // }
        if (
          this.editListType == "top" &&
          oldTextAreaHeight == TextAreaWrap.MinHeight
        ) {
          newTextAreaHeight = oldTextAreaHeight;
          return;
        }
        // 计算新的发送框高度
        newTextAreaHeight = oldTextAreaHeight + distance;
        // 如果是再上边的线条
        // console.log(oldTextAreaTop);
        // console.log(distance);
        if (this.editListType == "top") {
          oldTextAreaTop = oldTextAreaTop + distance;
          newTextAreaHeight = oldTextAreaHeight - distance;
        }

        // console.log(oldTextAreaTop);
      } else {
        // 若鼠标向下移动
        // 发送框高度达到最小
        // if (oldTextAreaHeight <= TextAreaWrap.MinHeight) {
        //   // 修改光标为可被向上移动
        //   rTextareaWrap.style.cursor = "n-resize";
        //   return false;
        // }
        // console.log(event.clientY);
        // 计算新的发送框高度
        newTextAreaHeight = oldTextAreaHeight - distance;
        if (this.editListType == "top") {
          oldTextAreaTop = oldTextAreaTop - distance;
          newTextAreaHeight = oldTextAreaHeight + distance;
          // console.log(event.clientY);
          if (event.clientY < 174 - scrollTop) {
            newTextAreaHeight = oldTextAreaHeight;
          }
        }
        // console.log(oldTextAreaTop);
      }
      // 若鼠标向右移动
      if (endMouseLeft < event.clientX) {
        // 发送宽度达到最大
        // if (oldTextAreaHeight >= TextAreaWrap.MaxWidth) {
        //   // 修改光标为可被向右移动
        //   rTextareaWrap.style.cursor = "s-resize";
        //   return false;
        // }
        if (
          oldTextAreaWidth == TextAreaWrap.MinWidth &&
          this.editListType == "left"
        ) {
          newTextAreaWidth = oldTextAreaWidth;
          // console.log("就是这个判断");
          return;
        }
        // 计算新的发送框高度
        newTextAreaWidth = oldTextAreaWidth + distanceLeft;
        if (this.editListType == "left") {
          oldTextAreaLeft = oldTextAreaLeft + distanceLeft;
          newTextAreaWidth = oldTextAreaWidth - distanceLeft;
        }
      } else {
        // 若鼠标向左移动
        // 发送框高度达到最小
        // if (oldTextAreaHeight <= TextAreaWrap.MinWidth) {
        //   // 修改光标为可被向右移动
        //   rTextareaWrap.style.cursor = "n-resize";
        //   return false;
        // }
        // console.log(distanceLeft);
        // 计算新的发送框高度
        newTextAreaWidth = oldTextAreaWidth - distanceLeft;
        if (this.editListType == "left") {
          oldTextAreaLeft = oldTextAreaLeft - distanceLeft;
          newTextAreaWidth = oldTextAreaWidth + distanceLeft;
          if (event.clientX < this.oldLeft - scrollLeft) {
            newTextAreaWidth = oldTextAreaWidth;
          }
        }
      }
      // console.log(oldTextAreaTop);
      // console.log(oldTextAreaLeft);

      // 兼容鼠标快速拖动的情况：新的发送框高度不能超过最大值
      if (newTextAreaHeight > TextAreaWrap.MaxHeight) {
        newTextAreaHeight = TextAreaWrap.MaxHeight;
      }

      // 兼容鼠标快速拖动的情况：新的发送框高度不能小于最小值
      if (newTextAreaHeight < TextAreaWrap.MinHeight) {
        newTextAreaHeight = TextAreaWrap.MinHeight;
      }

      // 兼容鼠标快速拖动的情况：新的发送框宽度不能超过最大值
      if (newTextAreaWidth > TextAreaWrap.MaxWidth) {
        newTextAreaWidth = TextAreaWrap.MaxWidth;
      }

      // 兼容鼠标快速拖动的情况：新的发送框宽度不能小于最小值
      if (newTextAreaWidth < TextAreaWrap.MinWidth) {
        newTextAreaWidth = TextAreaWrap.MinWidth;
      }
      // 判断下  如果边距为负值 添加直接最小值
      if (oldTextAreaTop < 1) {
        oldTextAreaTop = 1;
      }
      if (oldTextAreaLeft < 1) {
        oldTextAreaLeft = 1;
      }
      // 判断下left太多了
      if (oldTextAreaLeft >= this.viewWidth) {
        oldTextAreaLeft = this.viewWidth;
      }
      if (oldTextAreaTop >= this.viewHeight) {
        oldTextAreaTop = this.viewHeight;
      }
      // 判断下超过右边
      if (oldTextAreaLeft + newTextAreaWidth >= this.viewWidth) {
        newTextAreaWidth = this.viewWidth - oldTextAreaLeft - 1;
      }
      // 判断下超过高度
      if (oldTextAreaTop + newTextAreaHeight >= this.viewHeight) {
        newTextAreaHeight = this.viewHeight - oldTextAreaTop - 1;
      }
      // 修改发送框高度
      rTextareaWrap.style.height = newTextAreaHeight + "px";
      rTextareaWrap.style.width = newTextAreaWidth + "px";
      // 修改距离
      rTextareaWrap.style.top = oldTextAreaTop + "px";
      rTextareaWrap.style.left = oldTextAreaLeft + "px";
      // 修改光标为可移动
      this.newHeight = newTextAreaHeight;
      this.newWidth = newTextAreaWidth;
      this.newTop = oldTextAreaTop;
      this.newLeft = oldTextAreaLeft;

      // 更新鼠标最后移动的位置（Y轴）
      this.dragState.endMouseTop = event.clientY;
      this.dragState.endMouseLeft = event.clientX;
    },

    /**
     * 处理鼠标放开事件
     */
    handleMouseUp() {
      // 如果值发生了改变
      // console.log(this.sheetLineList);
      // console.log("触发了");
      // let oldId = this.subSizeLine.id;
      if (this.newWidth && this.newHeight) {
        this.sheetLineList.forEach((item) => {
          if (!this.editIndexExtend.id) {
            if (this.subSizeLine.id == item.id) {
              // console.log("已进入");
              item.source.width = parseInt(this.newWidth * this.proportion);
              item.source.height = parseInt(this.newHeight * this.proportion);
              item.source.top = parseInt(this.newTop * this.proportion);
              item.source.left = parseInt(this.newLeft * this.proportion);
            }
          } else {
            // 如果是追加区域
            // 如果是追加非当前页
            if (item.extendList) {
              item.extendList.map((index) => {
                if (this.editIndexExtend.id == index.id) {
                  index.source.width = parseInt(
                    this.newWidth * this.proportion
                  );
                  index.source.height = parseInt(
                    this.newHeight * this.proportion
                  );
                  index.source.top = parseInt(this.newTop * this.proportion);
                  index.source.left = parseInt(this.newLeft * this.proportion);
                }
              });
            }
          }
        });
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.setAllLineData();
        this.setLine();
        if (this.indexEditType) {
          // 根据试题的 priority 页码 组装好需要提交的数据
          // 获取到修改的项
          if (this.markSubjectiveEdit.length == 0) {
            this.GET_SHEET_MARK_LIST.forEach((item) => {
              // let index = Object.assign({}, item);
              if (item.priority == this.subSizeLine.priority) {
                this.markSubjectiveEdit = JSON.parse(item.markSubjective);
                // id = item.id;
              }
            });
          }
          // 获取到需要修改的项
          this.markSubjectiveEdit.map((item) => {
            if (item.id == this.subSizeLine.id) {
              item.extendList.map((index) => {
                if (index.id == this.editIndexExtend.id) {
                  index.source.width = parseInt(
                    this.newWidth * this.proportion
                  );
                  index.source.height = parseInt(
                    this.newHeight * this.proportion
                  );
                  index.source.top = parseInt(this.newTop * this.proportion);
                  index.source.left = parseInt(this.newLeft * this.proportion);
                }
              });
            }
          });
          // console.log(this.subSizeLine);
          // this.saveExampapersheetmark(markSubjective, id, this.subSizeLine.id);
        }
        // 清除所有点
      }
      // 选做题没有内部框选标记的话 就不提交
      // let sheetArr = this.sheetLineList.filter(
      //   (item) =>
      //     !item.subjectiveType ||
      //     (item.subjectiveType == 1 && item.chooseQuestionLocation) || (item.subjectiveType == 2 && item.chooseQuestionLocationArr.length==item.questionNum)
      // );
      // if (!this.indexEditType) {
      //   this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      //   this.$emit("submitIndexImg", 1, oldId);
      // }
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
      this.setLine();
      // // 移除鼠标移动事件
      document.removeEventListener("mousemove", this.handleMouseMove);
      // 移除鼠标放开事件
      document.removeEventListener("mouseup", this.handleMouseUp);
      // 允许用户选择网页中文字;
      document.onselectstart = null;
      // 允许用户拖动元素
      document.ondragstart = null;
    },
    checkQuestionNum1(val) {
      this.questionNumEnd = Number(val) + Number(this.questionNum) - 1;
      this.questionNumList.map((item, index) => {
        item.examQuestionNumber = Number(this.questionNumStart) + index;
      });
    },
    // 修改为题号间隔 题号编号
    checkQuestionNumSpacing() {
      // 判断下  禁止用户输入空
      if (this.questionSpacing === undefined) {
        this.optionsContinuous = 1;
        return;
      }
      this.optionsContinuous = 2;

      this.questionNumList = this.questionNumList.map((item, index) => {
        item.examQuestionNumber =
          this.questionSerialNumber +
          (Number(this.questionNumStart) + index * this.questionSpacing);
        return item;
      });
    },
    // 修改试题数量
    checkQuestionNum() {
      let value = this.questionNum;
      let length = this.questionNumList.length;
      if (value < length) {
        this.questionNumList.splice(value, length - value);
      } else {
        let questionMax = this.GET_SHEET_QUESTION_MAX;
        this.questionNumList.map((item) => {
          if (item.value >= this.GET_SHEET_QUESTION_MAX) {
            questionMax = item.value + 1;
          }
        });
        for (let i = 0; i < value - length; i++) {
          this.questionNumList.push({
            value: Number(questionMax) + i,
            examQuestionNumber:
              this.questionSerialNumber +
              (Number(this.questionNumStart) +
                (i + Number(value) - 1) * this.questionSpacing),
            isOption: 0,
            score: this.score,
            scoreMiss: this.scoreMiss,
          });
        }
      }
      this.questionNumEnd =
        Number(value) * this.questionSpacing +
        Number(this.questionNumStart) -
        1;
    },

    // 添加删除主观题
    setQuestionOrder1() {
      if (this.questionNumList.length > this.questionNum) {
        this.questionNumList.length = this.questionNum;
      } else {
        let number = this.questionNum - this.questionNumList.length;
        let questionMax = this.GET_SHEET_QUESTION_MAX;
        this.questionNumList.map((item) => {
          if (item.value >= this.GET_SHEET_QUESTION_MAX) {
            questionMax = item.value + 1;
          }
        });
        for (let i = 0; i < number; i++) {
          let data = Object.assign(
            {},
            this.questionNumList[this.questionNumList.length - 1]
          );
          data.examQuestionNumber = "";
          data.value = questionMax + i;
          this.questionNumList.push(data);
        }
      }
    },
    // 切换连续方式
    checkOptionType() {
      // this.questionSpacing = 1;
      // this.questionSpacing = "";
      // questionSpacing questionSerialNumber
    },
    // 选择题类型切换
    changeOptionsType(type) {
      // console.log(type);
      if (type == 3) {
        this.optionsLength = 2;
        this.optionsMaxLength = 2;
      } else {
        this.optionsLength = 4;
        this.optionsMaxLength = 26;
      }
      if (type != 2) {
        this.subjectiveType = 0;
      }
    },
    async submitEdit() {
      this.editSizeDrawer = false;
      await new Promise((resolve) => {
        this.sheetLineList.map((item) => {
          if (item.id == this.oldSizeLine.id) {
            if (!this.indexChoose) {
              item.leftTopWidthSize = this.leftTopWidthSize;
              item.leftTopHeightSize = this.leftTopHeightSize;
              resolve();
            } else {
              // 特殊选做题判断
              if (this.chooseTsIndex >= 0) {
                item.chooseQuestionLocationArr[
                  this.chooseTsIndex
                ].leftTopWidthSize = this.leftTopWidthSize;
                item.chooseQuestionLocationArr[
                  this.chooseTsIndex
                ].leftTopHeightSize = this.leftTopHeightSize;
                resolve();
              } else {
                item.chooseQuestionLocation.leftTopWidthSize =
                  this.leftTopWidthSize;
                item.chooseQuestionLocation.leftTopHeightSize =
                  this.leftTopHeightSize;
                resolve();
              }
            }
          }
        });
      });
      let oldId = this.oldSizeLine.id;
      this.oldSizeLine = {};
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      this.$emit("submitIndexImg", 1, oldId);
    },
    // 取消编辑
    cancelEdit() {
      this.sheetLineList.map((item) => {
        if (item.id == this.oldSizeLine.id) {
          Object.assign(item, this.oldSizeLine);
        }
      });
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      // let id=
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      this.$emit("submitIndexImg", 1, this.oldSizeLine.id);
      this.oldSizeLine = {};
      // console.log(this.sheetLineList);
      this.close();
    },
    // 编辑框大小
    handleChange() {
      // console.log(this.sizeRight);
      // console.log(this.oldSizeLine);
      this.sheetLineList.map((item) => {
        if (item.id == this.oldSizeLine.id) {
          if (!this.indexChoose) {
            // 左边距偏移的距离
            let xDis = item.leftTopWidthSize - this.leftTopWidthSize;
            item.points.map((index, i) => {
              index.x = this.oldSizeLine.points[i].x - xDis;
            });
            // console.log(item.points);
            // item.leftTopWidthSize = this.leftTopWidthSize;
            // 上边距偏移的距离
            let yDis = item.leftTopHeightSize - this.leftTopHeightSize;
            item.points.map((index, i) => {
              index.y = this.oldSizeLine.points[i].y - yDis;
            });
            // item.leftTopHeightSize = this.leftTopHeightSize;
            // 小盒子的宽度
            item.calculate.sizeWidth = this.sizeWidth;
            // 小盒子的高度
            item.calculate.sizeHeight = this.sizeHeight;
            if (
              this.indexType != 6 ||
              (this.indexType == 6 && this.indexSubType != 1)
            ) {
              // 右边距
              item.calculate.sizeRight = this.sizeRight;
              // 左边距
              item.calculate.sizeBottom = this.sizeBottom;
            }
          } else {
            // 特殊选做题情况
            if (this.chooseTsIndex >= 0) {
              let xDis =
                item.chooseQuestionLocationArr[this.chooseTsIndex]
                  .leftTopWidthSize - this.leftTopWidthSize;
              item.chooseQuestionLocationArr[this.chooseTsIndex].points.map(
                (index, i) => {
                  index.x =
                    this.oldSizeLine.chooseQuestionLocationArr[
                      this.chooseTsIndex
                    ].points[i].x - xDis;
                }
              );
              // 上边距偏移的距离
              let yDis =
                item.chooseQuestionLocationArr[this.chooseTsIndex]
                  .leftTopHeightSize - this.leftTopHeightSize;
              item.chooseQuestionLocationArr[this.chooseTsIndex].points.map(
                (index, i) => {
                  index.y =
                    this.oldSizeLine.chooseQuestionLocationArr[
                      this.chooseTsIndex
                    ].points[i].y - yDis;
                }
              );
              // 小盒子的宽度
              item.chooseQuestionLocationArr[
                this.chooseTsIndex
              ].calculate.sizeWidth = this.sizeWidth;
              // 小盒子的高度
              item.chooseQuestionLocationArr[
                this.chooseTsIndex
              ].calculate.sizeHeight = this.sizeHeight;
              // 右边距
              item.chooseQuestionLocationArr[
                this.chooseTsIndex
              ].calculate.sizeRight = this.sizeRight;
              // 左边距
              item.chooseQuestionLocationArr[
                this.chooseTsIndex
              ].calculate.sizeBottom = this.sizeBottom;
            } else {
              let xDis =
                item.chooseQuestionLocation.leftTopWidthSize -
                this.leftTopWidthSize;
              item.chooseQuestionLocation.points.map((index, i) => {
                index.x =
                  this.oldSizeLine.chooseQuestionLocation.points[i].x - xDis;
              });
              // 上边距偏移的距离
              let yDis =
                item.chooseQuestionLocation.leftTopHeightSize -
                this.leftTopHeightSize;
              item.chooseQuestionLocation.points.map((index, i) => {
                index.y =
                  this.oldSizeLine.chooseQuestionLocation.points[i].y - yDis;
              });
              // 小盒子的宽度
              item.chooseQuestionLocation.calculate.sizeWidth = this.sizeWidth;
              // 小盒子的高度
              item.chooseQuestionLocation.calculate.sizeHeight =
                this.sizeHeight;
              // 右边距
              item.chooseQuestionLocation.calculate.sizeRight = this.sizeRight;
              // 左边距
              item.chooseQuestionLocation.calculate.sizeBottom =
                this.sizeBottom;
            }
          }
        }
      });
      // console.log(this.sheetLineList);
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制cavas
      this.setAllLineData();
      this.setLine();
    },
    // 设置不是当前项的线条
    setAllLineData() {
      this.allSheetLine = [];
      // 将所有的线条合并 allSheetLine
      let data = this.GET_SHEET_MARK_LIST.filter(
        (i) => i.id == this.GET_INDEX_SHEET_MARK.id
      )[0];
      // console.log(this.markTypeList);
      // console.log(data);
      this.markTypeList.forEach((item) => {
        if (!data) {
          return;
        }
        data[item.key] = data[item.key] || "[]";
        this.allSheetLine = this.allSheetLine.concat(
          JSON.parse(data[item.key])
        );
      });
      this.allSheetLine = this.allSheetLine.filter(
        (item) => item.type != this.GET_LABELLED_TYPE
      );
      this.setAllLine();
    },
    setSheetLineList() {
      // console.log(this.GET_SHEET_LINE_LIST);
      this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      this.subjectiveTypeId = "";
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
      this.setLine();
    },
    callFunction(img) {
      return new Promise((resolve) => {
        img.onload = () => {
          this.oldWidth = img.width;
          this.oldHeight = img.height;
          resolve(true);
        };
      });
    },
    async setImg() {
      // 获取canvas对象
      const imgCanvas = document.getElementById("imgCanvas");
      // 创建img对象
      let img = document.createElement("img");
      // console.log(this.$refs.markSheet);
      // 设置src值
      img.src = encodeURI(this.indexImgUrl);

      const viewWidth =
        this.$refs.markSheet.clientWidth -
        this.$refs.markSheet.clientWidth * 0.1;
      this.oldLeft = (this.$refs.markSheet.clientWidth * 0.1) / 2;
      this.$refs.locationBox.style.left =
        (this.$refs.markSheet.clientWidth * 0.1) / 2 + "px";
      this.viewWidth = viewWidth;
      const _this = this;

      //设置canvas属性 oldWidth oldHeight viewWidth
      await this.callFunction(img);
      _this.oldWidth = img.width;
      _this.oldHeight = img.height;
      // 当前比例
      _this.proportion = _this.oldWidth / _this.viewWidth;
      //重新定义图片宽高
      img.height = (img.height * viewWidth) / img.width;
      img.width = viewWidth;

      imgCanvas.width = viewWidth;
      _this.viewHeight = img.height;
      imgCanvas.height = img.height;
      imgCanvas.style.backgroundSize = `${img.width}px ${img.height}px`;
      imgCanvas.style.backgroundImage = 'url("' + img.src + '")';

      // 设置装cavans的盒子大小
      _this.$refs.locationBox.style.height = img.height + "px";
      _this.$refs.locationBox.style.width = viewWidth + "px";
    },
    // 切换后重新加载
    async checkImg() {
      // console.log("加载");
      this.subjectiveTypeId = "";
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.loadingBox = true;
      this.indexSheetMark = JSON.parse(
        JSON.stringify(this.GET_INDEX_SHEET_MARK)
      );
      this.indexImgUrl = this.indexSheetMark.urlAdjust;
      await this.setImg();
      // 获取cavas需要渲染的当前列表
      // console.log(JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST)));
      this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      // setTimeout(() => {
      this.loadingBox = false;
      // 设置cavas参数
      this.canvas = this.$refs.lineCanvas;
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width = this.viewWidth;
      this.canvas.height = this.oldHeight / (this.oldWidth / this.viewWidth);
      this.setAllLineData();
      this.setLine();
      // }, 400);
    },
    // 加载答题卡
    async onloadImg() {
      this.indexSheetMark = JSON.parse(
        JSON.stringify(this.GET_INDEX_SHEET_MARK)
      );

      this.indexImgUrl = this.indexSheetMark.urlAdjust;
      this.setImg();
      // 获取cavas需要渲染的当前列表
      this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      await this.setImg();

      // 获取cavas需要渲染的当前列表
      this.sheetLineList = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      // console.log(JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST)));
      // console.log(this.GET_SHEET_LINE_LIST);
      // 设置cavas参数
      this.canvas = this.$refs.lineCanvas;
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width = this.viewWidth;
      this.canvas.height = this.oldHeight / (this.oldWidth / this.viewWidth);
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.setAllLineData();
      this.setLine();
    },
    // 定位点标记时 获取当前项是否被禁用
    getRadioDis(val) {
      let type = true;
      this.sheetLineList.forEach((item) => {
        if (val == item.subType) {
          type = false;
        }
      });
      if (this.sheetLineList.length == 0) {
        type = false;
      }
      return type;
    },
    // 编辑信息
    editLine(v) {
      // console.log(v);
      this.saveType = "edit";
      this.saveData = v;
      // 如果是第一步
      if (v.type == 1) {
        // 如果是定位点
        if (this.locationType == 1) {
          this.currentPosition = this.saveData.currentPosition;
          this.drawer = true;
        }
        this.subType = v.subType;
        // console.log(v.subType);
        // this.subType = v.subType;
      }
      if (v.type == 3) {
        // 如果是编辑考号
        this.studentExamIdDrawer = true;
        this.subType = v.subType;
        this.optionsArrangementMode = v.optionsArrangementMode;
        this.optionsStyle = v.optionsStyle;
        this.optionsLength = v.optionsLength;
        this.optionsMaxLength = 14;
      }
      if (v.type == 4) {
        // 如果是编辑客观题

        this.subType = v.subType;
        this.optionsArrangementMode = v.optionsArrangementMode;
        this.optionsStyle = v.optionsStyle;
        this.optionsLength = v.optionsLength;
        this.optionsContinuous = v.optionsContinuous;
        this.questionSerialNumber = v.questionSerialNumber;
        this.questionSpacing = v.questionSpacing || 1;
        this.optionsMaxLength = 26;
        this.questionNum = v.questionNum;
        // 如果试题组是连续的情况
        if (this.optionsContinuous == 1) {
          let orderList = v.questionNumList.map((item) => {
            if (Number(item.examQuestionNumber)) {
              return Number(item.examQuestionNumber);
            } else {
              return;
            }
          });
          // console.log(orderList);
          orderList = orderList.filter((item) => item);
          // 过滤下是否存在中文 如果试题题号在连续且存在中文  就修改为非连续
          if (
            (v.questionNumList.length == orderList.length &&
              this.IncreasingFn(orderList)) ||
            v.questionNumList.length == 1
          ) {
            this.questionNumStart = Number(
              v.questionNumList[0].examQuestionNumber
            );
            this.questionNumEnd =
              this.questionNumStart + v.questionNumList.length - 1;
          } else {
            this.$message({
              showClose: true,
              message:
                "当前试题组中试题题号为非数字，已切换为 '选择题类型' 已切换为非连续！",
              type: "warning",
              duration: 3000,
            });
            this.optionsContinuous = 2;
            this.questionNumStart = v.questionNumList[0].value;
            this.questionNumEnd =
              this.questionNumStart + v.questionNumList.length - 1;
          }
        } else {
          this.optionsContinuous = 2;
          this.questionNumStart = v.questionNumList[0].value;
          this.questionNumEnd =
            this.questionNumStart + v.questionNumList.length - 1;
        }
        this.questionNumList = JSON.parse(JSON.stringify(v.questionNumList));
        this.score = v.questionNumList[0].score;
        this.scoreMiss = v.questionNumList[0].scoreMiss;
        this.startValue = v.questionNumList[0].value;
        this.choiceQuestionDrawer = true;
      }
      if (v.type == 5) {
        // 如果是编辑主观题
        // console.log(v);
        this.subjectiveType = v.subjectiveType;
        if (this.subjectiveType == 2) {
          this.subjectiveType = 1;
          this.irregular = true;
        }
        if (this.subjectiveType) {
          this.optionsArrangementMode = v.optionsArrangementMode;
        }
        this.score = v.questionNumList[0].score;
        // this.scoreMiss = v.questionNumList[0].scoreMiss;
        this.subType = v.subType;
        this.questionNumList = JSON.parse(JSON.stringify(v.questionNumList));
        this.questionNum = v.questionNum;
        // this.questionNumStart = this.;
        // this.questionNumEnd =
        //   v.questionNumList[v.questionNumList.length - 1].value;
        this.subjectiveQuestionDrawer = true;
      }
    },
    //判断是否递增函数
    IncreasingFn(array) {
      let IncreasingFn = false;
      let arrayCount = array.length - 1;
      // console.log(arrayCount,'现在是？');
      for (let i = 0; i < arrayCount; i++) {
        let currentArr = Number(array[i]) + 1;
        let nestArr = Number(array[i + 1]);

        if (i < 1 == arrayCount) {
          currentArr = Number(array[i]);
          nestArr = Number(array[i]);
        }
        if (currentArr != nestArr) {
          IncreasingFn = false;
          break;
        } else {
          IncreasingFn = true;
        }
      }
      return IncreasingFn;
    },

    // 调整尺寸
    editSizeLine(item, type, index) {
      this.markSubjectiveEdit = [];
      this.oldSizeLine = JSON.parse(JSON.stringify(item));
      this.indexSubType = item.subType;
      this.indexType = item.type;
      this.indexChoose = false;
      this.lineType = false;
      this.chooseTsIndex = -1;
      // 如果是选做题
      if (type == "choose") {
        this.indexChoose = true;
        this.leftTopWidthSize = item.chooseQuestionLocation.leftTopWidthSize;
        // 到左上角的纵坐标
        this.leftTopHeightSize = item.chooseQuestionLocation.leftTopHeightSize;
        // 小框的宽度
        this.sizeWidth = item.chooseQuestionLocation.calculate.sizeWidth;
        // 小框的高度
        this.sizeHeight = item.chooseQuestionLocation.calculate.sizeHeight;

        // 右边距
        this.sizeRight = item.chooseQuestionLocation.calculate.sizeRight;
        // 左边距
        this.sizeBottom = item.chooseQuestionLocation.calculate.sizeBottom;

        this.editSizeDrawer = true;
        return;
      }
      // 如果特殊选做题
      if (type == "chooseTs") {
        // console.log(item.chooseQuestionLocationArr);
        let indexObj = item.chooseQuestionLocationArr[index];
        this.chooseTsIndex = index;

        this.indexChoose = true;
        this.leftTopWidthSize = indexObj.leftTopWidthSize;
        // 到左上角的纵坐标
        this.leftTopHeightSize = indexObj.leftTopHeightSize;
        // 小框的宽度
        this.sizeWidth = indexObj.calculate.sizeWidth;
        // 小框的高度
        this.sizeHeight = indexObj.calculate.sizeHeight;

        // 右边距
        this.sizeRight = indexObj.calculate.sizeRight;
        // 左边距
        this.sizeBottom = indexObj.calculate.sizeBottom;

        this.editSizeDrawer = true;
        return;
      }
      // 在当前页的线框
      if (type == "extendList") {
        // console.log(item);
        this.subSizeLine = JSON.parse(JSON.stringify(item));
        this.indexHiddenBox = item;
        this.lineType = true;
        this.editIndexExtend = item.extendList[index];
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // 重新绘制定位点
        this.setAllLineData();
        this.setLine();
        this.$message({
          message: "请点击线条上的四个圆圈进行框大小调整！",
          type: "success",
          showClose: true,
        });
        return;
      } else {
        this.editIndexExtend = {};
        this.indexHiddenBox = {};
      }
      // console.log(item);
      // console.log(type);
      // console.log(index);
      // 追加的不在当前页的线框
      if (type == "otherList") {
        this.lineType = true;
        this.indexEditType = "otherList";
        this.subSizeLine = JSON.parse(JSON.stringify(item));
        this.indexHiddenBox = item;
        // console.log()
        this.editIndexExtend = item.extendList[index];
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // 重新绘制定位点
        this.setAllLineData();
        this.setLine();
        this.$message({
          message: "请点击线条上的四个圆圈进行框大小调整！",
          type: "success",
          showClose: true,
        });
        return;
      } else {
        this.indexHiddenBox = {};
        this.indexEditType = "";
        this.editIndexExtend = {};
      }
      // 如果是主观题  将大框设置为可拖动
      if (item.type == 5 || (item.type == 3 && item.subType != 1)) {
        this.lineType = true;
        this.subSizeLine = JSON.parse(JSON.stringify(item));
        this.indexHiddenBox = item;
        // 清除未识别的信息
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // 重新绘制定位点
        this.setAllLineData();
        this.setLine();
        this.$message({
          message: "请点击线条上的四个圆圈进行框大小调整！",
          type: "success",
          showClose: true,
        });
        return;
      } else {
        this.subSizeLine = {};
      }
      // this.oldSizeLine = JSON.parse(JSON.stringify(item));
      // 到左上角的横坐标
      // console.log(item.leftTopWidthSize);
      this.leftTopWidthSize = item.leftTopWidthSize;
      // 到左上角的纵坐标
      this.leftTopHeightSize = item.leftTopHeightSize;
      // 小框的宽度
      this.sizeWidth = item.calculate.sizeWidth;
      // 小框的高度
      this.sizeHeight = item.calculate.sizeHeight;
      if (
        this.indexType != 6 ||
        (this.indexType == 6 && this.indexSubType != 1)
      ) {
        // 右边距
        this.sizeRight = item.calculate.sizeRight;
        // 左边距
        this.sizeBottom = item.calculate.sizeBottom;
      }

      this.editSizeDrawer = true;
    },
    // 获取当前生成div 的左右边距 宽 高
    delLine(i, type, index) {
      // console.log(i);
      // console.log(type);
      // console.log(index);
      let cutId = "";
      if (type == "otherList") {
        // 根据试题的 priority 页码 组装好需要提交的数据
        let markSubjective = [];
        let id = "";
        // 获取到修改的项
        this.GET_SHEET_MARK_LIST.forEach((item) => {
          // let index = Object.assign({}, item);
          if (item.priority == index.priority) {
            markSubjective = JSON.parse(item.markSubjective);
            id = item.id;
          }
        });
        // 获取到需要修改的项
        markSubjective.map((item) => {
          if (item.id == index.id) {
            item.extendList.splice(i, 1);
          }
        });
        // console.log(index);
        this.saveExampapersheetmark(markSubjective, id, index.id);
        return;
      }
      // 删除选做题区域
      if (type == "choose") {
        this.subjectiveTypeId = this.sheetLineList[i].id;
        delete this.sheetLineList[i].chooseQuestionLocation;
        this.sheetLineList = JSON.parse(JSON.stringify(this.sheetLineList));
      }
      // 删除特殊选做题区域
      if (type == "chooseTs") {
        this.subjectiveTypeId = this.sheetLineList[i].id;
        this.subjectiveShowType == 2;
        this.sheetLineList[i].chooseQuestionLocationArr.splice(index, 1);
        this.sheetLineList = JSON.parse(JSON.stringify(this.sheetLineList));
      }
      // console.log(this.subjectiveTypeId);
      // console.log(this.sheetLineList);
      if (!type) {
        this.subjectiveTypeId = "";
        cutId = this.sheetLineList[i].id;
        this.sheetLineList.splice(i, 1);
      }
      // 删除当前点
      // 删除追加区域
      if (type == "extendList") {
        this.subjectiveTypeId = "";
        this.sheetLineList.map((item) => {
          if (item.id == index.id) {
            item.extendList.splice(i, 1);
          }
        });
        // this.sheetLineList.splice(i, 1);
      }
      if (type != "choose" && type != "chooseTs") {
        // 选做题没有内部框选标记的话 就不提交
        let sheetArr = this.getArr();
        this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);

        if (index) {
          cutId = index.id;
        }
        // console.log(cutId);
        // console.log(index);
        this.$emit("submitIndexImg", 1, cutId);
      }
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新渲染点
      this.setAllLineData();
      this.setLine();
    },
    misjudgement() {
      let Array = [];
      if (this.GET_LABELLED_TYPE == 4) {
        // str = "请先框选考号";
        // this.GET_SHEET_LINE_LIST.map((index) => {
        this.questionNumList.map((item) => {
          if (!item.examQuestionNumber) {
            return;
          }
          let examQuestionNumber = item.examQuestionNumber.toString();
          if (
            examQuestionNumber.indexOf(",") != -1 ||
            examQuestionNumber.indexOf(";") != -1 ||
            examQuestionNumber.indexOf("，") != -1 ||
            examQuestionNumber.indexOf("；") != -1
          ) {
            Array.push(item.examQuestionNumber);
          }
          // });
          // if()
        });
      }
      if (this.GET_LABELLED_TYPE == 5) {
        // str = "请先框选考号";
        // this.GET_SHEET_LINE_LIST.map((index) => {
        this.questionNumList.map((item) => {
          if (!item.examQuestionNumber) {
            return;
          }
          let examQuestionNumber = item.examQuestionNumber.toString();
          if (
            examQuestionNumber.indexOf(",") != -1 ||
            examQuestionNumber.indexOf(";") != -1 ||
            examQuestionNumber.indexOf("，") != -1 ||
            examQuestionNumber.indexOf("；") != -1
          ) {
            Array.push(item.examQuestionNumber);
          }
        });
        // });
      }
      if (Array.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "题号异常提示",
          message: h("p", null, [
            h("span", { style: "color: red" }, Array.join(" ") + "题"),
            h("span", null, "题号存在"),
            h("span", { style: "color: red" }, "；，, ;"),
            h("span", null, "等符号，请修改后重试 "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        });
      }
      return Array.length > 0;
    },
    getline(v, type) {
      let val = (v.source[type] / this.proportion).toFixed(2) + "px";
      return val;
    },
    // 获取选做题的位置
    getLineStyle(v, type) {
      let val = (v.source[type] / this.proportion).toFixed(2) + "px";
      return val;
    },
    // 切换定位点位置
    changePosition(v) {
      if (v == "assistantPoint") {
        this.currentPosition = v;
      }
      if (this.saveData.currentPosition == v) {
        this.currentPosition = v;
        return;
      }
      if (
        this.sheetLineList.filter((item) => item.currentPosition == v).length >
        0
      ) {
        return;
      }
      this.currentPosition = v;
    },
    // 获取当前选项否为禁用状态
    setListDisable(val) {
      let type = false;
      this.sheetLineList.forEach((index) => {
        if (val.value == index.currentPosition) {
          type = true;
        }
      });
      if (this.saveData.currentPosition == val.value) {
        type = false;
      }
      if (val.value == "assistantPoint") {
        type = false;
      }
      return type;
    },
    modelClose() {
      this.saveData = {};
      this.saveType = "save";
      this.close();
    },
    close() {
      // console.log("出发了");
      // 清除未识别的信息
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制定位点
      this.setAllLineData();
      this.setLine();
      // done();
      this.drawer = false;
      this.flag = false;
      this.studentExamIdDrawer = false;
      this.choiceQuestionDrawer = false;
      this.editSizeDrawer = false;
      this.specialDrawer = false;
      this.subjectiveQuestionDrawer = false;
      this.lineData = {};
    },
    handleClose() {
      this.$confirm("确认关闭？", "提示")
        .then(() => {
          this.modelClose();
        })
        .catch(() => {});
    },

    // 多点类型点绘制（页面显示的浅色点）
    setAllLine() {
      // 获取出所有试题列表
      this.questionArrList = [];
      this.GET_SHEET_MARK_LIST.forEach((item) => {
        let markObjective = item.markObjective;
        let markSubjective = item.markSubjective;
        if (!markObjective) {
          markObjective = "[]";
        }
        if (!markSubjective) {
          markSubjective = "[]";
        }
        this.questionArrList = this.questionArrList
          .concat(JSON.parse(markSubjective))
          .concat(JSON.parse(markObjective));
      });
      // console.log(this.questionArrList);
      if (this.GET_LABELLED_TYPE != 5) {
        // 获取出不是当前页 但是再当前页有续题的试题
        this.ctx.strokeStyle = "rgba(255, 0, 0, 0.4)";
        this.ctx.lineWidth = 1;
        this.GET_SHEET_MARK_LIST.forEach((item) => {
          if (item.priority != this.GET_INDEX_SHEET_MARK.priority) {
            if (!item.markSubjective) {
              return;
            }
            let subArr = JSON.parse(item.markSubjective);
            subArr.forEach((index) => {
              if (index.extendList) {
                index.extendList.forEach((i, key) => {
                  if (i.priority == this.GET_INDEX_SHEET_MARK.priority) {
                    let data1 = {
                      left: i.source.left,
                      top: i.source.top,
                      width: i.source.width,
                      height: i.source.height,
                    };
                    this.setStrokeRect(data1);
                    this.setLineName(data1, index, 0, key + 1);
                  }
                });
              }
            });
          }
        });
      }
      if (this.allSheetLine.length == 0) {
        return;
      }
      // console.log(this.allSheetLine);
      this.allSheetLine.forEach((item) => {
        if (!item.source) {
          return;
        }
        let data = {
          left: item.source.left,
          top: item.source.top,
          width: item.source.width,
          height: item.source.height,
        };
        // console.log(item);
        //设置线条颜色，必须放在绘制之前
        this.ctx.strokeStyle = "rgba(0, 0, 255, 0.4)";
        if (item.type == 1 || item.type == 2) {
          this.ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
        }
        if (item.type == 5) {
          this.ctx.strokeStyle = "rgba(255, 0, 0, 0.4)";
        }
        this.ctx.lineWidth = 1;
        this.setStrokeRect(data);
        // 设置线条名称
        this.setLineName(data, item);
        // if ((item.type == 1 && item.subType != 3) || item.type == 2) {
        if (item.type == 1 || item.type == 2) {
          this.setCountPoint(item);
        }
        if (item.type == 3 || item.type == 4) {
          this.setExamIdPotint(item, 1);
        }

        // console.log(this.GET_LABELLED_TYPE);
        // console.log(this.subjectiveType);
        if (item.type == 5 && item.subjectiveType == 1) {
          if (item.chooseQuestionLocation) {
            let data1 = {
              left: item.chooseQuestionLocation.source.left,
              top: item.chooseQuestionLocation.source.top,
              width: item.chooseQuestionLocation.source.width,
              height: item.chooseQuestionLocation.source.height,
            };
            this.setStrokeRect(data1);
            item.chooseQuestionLocation.questionNum = item.questionNum;
            item.chooseQuestionLocation.optionsArrangementMode =
              item.optionsArrangementMode;
            this.setExamIdPotint(
              item.chooseQuestionLocation,
              0,
              item.questionNum
            );
            this.setLineNameChoose(item.chooseQuestionLocation.source, item);
          }
        }
        if (item.type == 5 && item.subjectiveType == 2) {
          // console.log(item.chooseQuestionLocationArr);
          item.chooseQuestionLocationArr.map((index, key) => {
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            // 设置线条名称
            this.setLineTsNameChoose(data1, item, key, 0);
            if (!index.questionNum) {
              index.questionNum = 1;
            }
            this.setExamIdPotint(index, 0);
          });
        }
        if (item.type == 6) {
          if (!item.source) {
            return;
          }
          if (item.subType == 2) {
            this.setCountPoint(item, 0);
          } else {
            this.setExamIdPotint(item, 0, 1);
          }
        }
        // 如果存在追加区域
        if (item.extendList) {
          item.extendList.forEach((index, i) => {
            if (index.priority != item.priority) {
              return;
            }
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            this.setLineName(data1, item, 0, i + 1);
          });
        }
      });
    },
    // 根据传入的坐标 计算框内容 大小
    // 特殊选做题name处理
    setLineTsNameChoose(data, item, key, type) {
      data = JSON.parse(JSON.stringify(data));
      let width = 0;
      let height = 24;
      let str = "";
      let array = [];
      item.questionNumList.forEach((item) => {
        array.push(item.examQuestionNumber);
      });

      str = `${item.questionNumList[key].examQuestionNumber}`;
      width = 15 * str.length;
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      //设置矩形
      if (type) {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
      } else {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.4)";
      }

      this.ctx.fillRect(
        data.left / this.proportion - 1,
        data.top - 1,
        width,
        height
      );

      // 文字
      this.ctx.fillStyle = "#ffffff";
      this.ctx.font = "14px Arial";
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    // 新建一个专门处理选做题问题
    setLineNameChoose(data, item, type) {
      data = JSON.parse(JSON.stringify(data));
      let width = 0;
      let height = 24;
      let str = "";
      let array = [];
      item.questionNumList.forEach((item) => {
        array.push(item.examQuestionNumber);
      });

      str = `选做题${array.join(",")}识别区域`;
      width = 15 * str.length;
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      //设置矩形
      if (type) {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
      } else {
        this.ctx.fillStyle = "rgba(255, 0, 0, 0.4)";
      }

      this.ctx.fillRect(
        data.left / this.proportion - 1,
        data.top - 1,
        width,
        height
      );

      // 文字
      this.ctx.fillStyle = "#ffffff";
      this.ctx.font = "14px Arial";
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    // 获取菜单名称
    setLineName(data, item, type, index) {
      let width = 0;
      let height = 24;
      let str = "";
      // 定位点
      if (item.type == 1) {
        // console.log(item.subType);
        str = "定位点";
        if (item.subType == 3) {
          str = "定位线";
        }
        // if (item.subType == 2) {
        //   str = "文字定位点";
        // }
        width = 55;
      }
      // 标题
      if (item.type == 2) {
        str = "标题";
        width = 45;
      }
      // 考号
      if (item.type == 3) {
        width = 55;
        if (item.subType == 1) {
          str = "填涂式";
        }
        if (item.subType == 2) {
          str = "条形码";
        }
        if (item.subType == 3) {
          str = "输入式";
        }
      }
      // 主观题
      if (item.type == 5) {
        let array = [];
        item.questionNumList.forEach((item) => {
          array.push(item.examQuestionNumber);
        });
        str = `${array.join(",")}(${item.questionNumList[0].score}分)`;
        width = 11 * str.length;

        // console.log(array);
      }
      // 考号
      if (item.type == 6) {
        // width = 55;
        if (item.subType == 1) {
          str = "缺考";
        }
        if (item.subType == 2) {
          str = "学科标记";
        }
        if (item.subType == 3) {
          str = "A,B卷标记";
        }
        width = 22 + 10 * str.length;
      }
      // 如果小于20 框就显示在下方
      if (data.top < 50) {
        data.top = (data.height + data.top) / this.proportion;
      } else {
        data.top = data.top / this.proportion - 23;
      }
      if (index) {
        str = str + `续${index}`;
        width = 11 * str.length;
      }

      // 文字
      this.ctx.fillStyle = "red";
      this.ctx.font = "14px Arial";

      if (item.type == 5) {
        //设置矩形
        if (type) {
          this.ctx.fillStyle = "rgba(255, 0, 0, 0.8)";
        } else {
          this.ctx.fillStyle = "rgba(255, 0, 0, 0.4)";
        }
        this.ctx.fillRect(
          data.left / this.proportion - 1,
          data.top - 1,
          width,
          height
        );
        this.ctx.fillStyle = "#ffffff";
      }
      this.ctx.fillText(str, data.left / this.proportion + 4, data.top + 16);
    },
    // 绘制cavans
    setLine(type) {
      // console.log(this.sheetLineList);
      // 如果是 主观题  获取一下其他页面是否存在当前页面的补充区域

      this.sheetLineList.forEach((item) => {
        // if (item.type == 6) {
        //   console.log(item);
        // }
        if (!item.source) {
          return;
        }
        let data = {
          left: item.source.left,
          top: item.source.top,
          width: item.source.width,
          height: item.source.height,
        };
        //设置线条颜色，必须放在绘制之前
        this.ctx.strokeStyle = "rgba(0, 0, 255, 1)";
        if (item.type == 1 || item.type == 2) {
          this.ctx.strokeStyle = "rgba(0, 0, 0, 1)";
        }
        if (item.type == 5) {
          this.ctx.strokeStyle = "rgba(255, 0, 0, 1)";
        }
        this.ctx.lineWidth = 1;

        // console.log(this.subSizeLine);
        if (
          this.subSizeLine &&
          this.subSizeLine.id == item.id &&
          !this.editIndexExtend.id
        ) {
          // 如果存在追加区域
          if (item.extendList) {
            item.extendList.forEach((index, i) => {
              if (this.editIndexExtend.id == index.id) {
                return;
              }
              if (index.priority != item.priority) {
                return;
              }
              let data1 = {
                left: index.source.left,
                top: index.source.top,
                width: index.source.width,
                height: index.source.height,
              };
              this.setStrokeRect(data1);
              this.setLineName(data1, item, 1, i + 1);
            });
          }
          // 如果是选做题
          if (item.chooseQuestionLocation) {
            let data1 = {
              left: item.chooseQuestionLocation.source.left,
              top: item.chooseQuestionLocation.source.top,
              width: item.chooseQuestionLocation.source.width,
              height: item.chooseQuestionLocation.source.height,
            };
            this.setStrokeRect(data1);
            // console.log(item.chooseQuestionLocation);
            item.chooseQuestionLocation.questionNum = item.questionNum;
            item.chooseQuestionLocation.optionsArrangementMode =
              item.optionsArrangementMode;
            this.setExamIdPotint(
              item.chooseQuestionLocation,
              0,
              item.questionNum
            );
            this.setLineNameChoose(item.chooseQuestionLocation.source, item, 1);
          }
          return;
        }
        this.setStrokeRect(data);
        // 如果存在追加区域
        if (item.extendList) {
          item.extendList.forEach((index, i) => {
            if (this.editIndexExtend.id == index.id) {
              return;
            }
            if (index.priority != item.priority) {
              return;
            }
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            this.setLineName(data1, item, 1, i + 1);
          });
        }
        // 如果是定位点的时候  后台返回的是当前框（基于传给后台的位置的相对位置的坐标）的相对位置
        // 需要重新计算  1左上 2右上 3左下  4右下 （点定位的情况）
        if (this.GET_LABELLED_TYPE == 1 || this.GET_LABELLED_TYPE == 2) {
          this.setCountPoint(item, 1);
        }
        if (this.GET_LABELLED_TYPE == 3) {
          this.setExamIdPotint(item, 0, 10);
        }
        if (this.GET_LABELLED_TYPE == 4) {
          this.setExamIdPotint(item, 0, item.optionsLength);
        }
        if (this.GET_LABELLED_TYPE == 5 && item.subjectiveType == 1) {
          if (item.chooseQuestionLocation) {
            let data1 = {
              left: item.chooseQuestionLocation.source.left,
              top: item.chooseQuestionLocation.source.top,
              width: item.chooseQuestionLocation.source.width,
              height: item.chooseQuestionLocation.source.height,
            };
            this.setStrokeRect(data1);
            // console.log(item.chooseQuestionLocation);
            item.chooseQuestionLocation.questionNum = item.questionNum;
            item.chooseQuestionLocation.optionsArrangementMode =
              item.optionsArrangementMode;
            this.setExamIdPotint(
              item.chooseQuestionLocation,
              0,
              item.questionNum
            );
            this.setLineNameChoose(item.chooseQuestionLocation.source, item, 1);
          }
        }
        if (this.GET_LABELLED_TYPE == 5 && item.subjectiveType == 2) {
          // console.log(item.chooseQuestionLocationArr);
          item.chooseQuestionLocationArr.map((index, key) => {
            let data1 = {
              left: index.source.left,
              top: index.source.top,
              width: index.source.width,
              height: index.source.height,
            };
            this.setStrokeRect(data1);
            // 设置线条名称
            this.setLineTsNameChoose(data1, item, key, 1);
            if (!index.questionNum) {
              index.questionNum = 1;
            }
            this.setExamIdPotint(index, 0);
          });
        }
        if (this.GET_LABELLED_TYPE == 6) {
          if (item.subType == 2) {
            this.setCountPoint(item, 1);
          } else {
            this.setExamIdPotint(item, 0, 1);
          }
        }
        this.setLineName(data, item, 1);
      });
      if (type) {
        return;
      }
      if (this.GET_LABELLED_TYPE == 5) {
        this.otherList = [];
        let pushArr = [];
        // 获取出不是当前页 但是再当前页有续题的试题
        this.ctx.strokeStyle = "rgba(255, 0, 0, 1)";
        this.ctx.lineWidth = 1;
        this.GET_SHEET_MARK_LIST.forEach((item) => {
          if (item.priority != this.GET_INDEX_SHEET_MARK.priority) {
            if (!item.markSubjective) {
              return;
            }
            // 循环主观题列表
            let subArr = JSON.parse(item.markSubjective);
            subArr.forEach((index) => {
              if (index.extendList) {
                index.extendList.forEach((i) => {
                  if (i.priority == this.GET_INDEX_SHEET_MARK.priority) {
                    if (pushArr.indexOf(index.id) == -1) {
                      pushArr.push(index.id);
                      this.otherList.push(index);
                    }
                  }
                });
              }
            });

            this.setOtherLine();
          }
        });
      }
    },
    setOtherLine() {
      this.otherList.map((item) => {
        item.extendList.map((index, key) => {
          if (this.editIndexExtend.id == index.id) {
            return;
          }
          if (index.priority != this.GET_INDEX_SHEET_MARK.priority) {
            return;
          }
          let data1 = {
            left: index.source.left,
            top: index.source.top,
            width: index.source.width,
            height: index.source.height,
          };
          // setInterval(() => {
          this.setStrokeRect(data1);
          this.setLineName(data1, item, 1, key + 1);
        });
      });
    },
    // 如果是小小框  根据位置生成相对位置
    setExamIdPotint(item, type) {
      // if (item.type == 6) {
      // console.log(item);
      // }

      // item 当前项数据 type 为1就是当前线条  颜色深点 其他就是非当前项线条 options 选项个数
      // console.log(item.subType);
      // 如果不是填涂式考号
      if (item.type == 3 && item.subType != 1) {
        return;
      }
      // points 内部框的内容（最左上角到最右下角）
      // 最小框的右边距 有两种情况  横式和竖式答题卡存在区别
      // 右边距 上边距  大盒子宽度  小盒子宽度  小盒子高度  大盒子高度
      let sizeRight, sizeBottom, sizeWidth, sizeHeight, bigHeight, bigWidth;
      // 如果已经是计算过的 就不需要重新计算了
      if (item.calculate && item.calculate.sizeWidth) {
        // console.log("进了");
        sizeRight = item.calculate.sizeRight;
        sizeBottom = item.calculate.sizeBottom;
        bigWidth = item.calculate.bigWidth;
        sizeWidth = item.calculate.sizeWidth;
        sizeHeight = item.calculate.sizeHeight;
        bigHeight = item.calculate.bigHeight;
      } else {
        // childPoints 是最小框的位置以及大小
        // 先计算出 最小框的宽度和高度
        // 左上角到框左边的距离
        item.leftTopWidthSize = item.points[0].x;
        // 左上角到框顶部的距离
        item.leftTopHeightSize = item.points[0].y;
        sizeWidth = item.childPoints[1].x - item.childPoints[0].x;
        bigWidth = item.points[1].x - item.points[0].x;
        sizeHeight = item.childPoints[2].y - item.childPoints[0].y;
        bigHeight = item.points[2].y - item.points[0].y;
        item.calculate = {};
        // 如果是考号 type为3的处理方式和客观题的处理方式不一样
        if (
          (item.type != 3 && item.optionsArrangementMode == 1) ||
          (item.optionsArrangementMode == 2 && item.type == 3)
        ) {
          // console.log("进入的第一步");
          sizeRight = (
            (bigWidth - sizeWidth * item.optionsLength) /
            (item.optionsLength - 1)
          ).toFixed(2);
          sizeBottom = (
            (bigHeight - sizeHeight * item.questionNum) /
            (item.questionNum - 1)
          ).toFixed(2);
        } else {
          // console.log("进入的第er步");
          sizeRight = (
            (bigWidth - sizeWidth * item.questionNum) /
            (item.questionNum - 1)
          ).toFixed(2);
          sizeBottom = (
            (bigHeight - sizeHeight * item.optionsLength) /
            (item.optionsLength - 1)
          ).toFixed(2);
        }
        // 如果是选做的情况 计算方式
        if (item.type == 5) {
          if (item.optionsArrangementMode == 1) {
            sizeRight = (
              (bigWidth - sizeWidth * item.questionNum) /
              (item.questionNum - 1)
            ).toFixed(2);
            sizeBottom = 0;
          } else {
            sizeRight = 0;
            sizeBottom = (
              (bigHeight - sizeHeight * item.questionNum) /
              (item.questionNum - 1)
            ).toFixed(2);
          }
        }
      }
      // 处理下没有下边距和右边距的情况
      if (
        sizeRight == "Infinity" ||
        sizeRight == "NaN" ||
        Number(sizeRight) < 0
      ) {
        sizeRight = 0;
      }
      if (
        sizeBottom == "Infinity" ||
        sizeBottom == "NaN" ||
        Number(sizeBottom) < 0
      ) {
        sizeBottom = 0;
      }
      item.calculate.sizeWidth = sizeWidth;
      item.calculate.bigWidth = bigWidth;
      item.calculate.sizeHeight = sizeHeight;
      item.calculate.bigHeight = bigHeight;
      item.calculate.sizeRight = sizeRight;
      item.calculate.sizeBottom = sizeBottom;
      // console.log(item.calculate);
      // 循环添加出一个数组
      // console.log(new Date().getTime());
      for (let i = 0; i < item.questionNum; i++) {
        // 如果是客观题  需要显示每个题的小题号
        if (item.type == 4) {
          // console.log(item);
          // 正方形
          if (type) {
            this.ctx.fillStyle = "rgba(255, 0, 0, 1)";
          } else {
            this.ctx.fillStyle = "rgba(255, 0, 0, 1)";
          }
          this.ctx.font = "12px Arial";
          let top =
            sizeHeight * i +
            sizeBottom * i +
            item.points[0].y +
            item.source.top;
          let right =
            sizeWidth * i + sizeRight * i + item.points[0].x + item.source.left;
          if (item.optionsArrangementMode == 1) {
            right = item.source.left;
            right =
              right / this.proportion -
              ((item.questionNumList[i].examQuestionNumber.toString().length -
                1) *
                12) /
                this.proportion -
              16;
            top = (top + 16) / this.proportion;
          } else {
            top = item.source.top;
            right = (right + 12) / this.proportion;
            top = (top + 12) / this.proportion - 20;
          }
          this.ctx.fillText(
            item.questionNumList[i].examQuestionNumber,
            right,
            top
          );
        }
        for (let j = 0; j < item.optionsLength; j++) {
          let data = {
            left:
              sizeWidth * j +
              sizeRight * j +
              item.points[0].x +
              item.source.left,
            top:
              sizeHeight * i +
              sizeBottom * i +
              item.points[0].y +
              item.source.top,
            width: sizeWidth,
            height: sizeHeight,
          };
          if (type) {
            this.ctx.fillStyle = "rgba(173, 213, 92, 0.5)";
          }
          // if (type) {
          //   this.ctx.fillStyle = "rgba(4, 195, 145, 0.2)";
          // } else {
          this.ctx.fillStyle = "rgba(173, 213, 92, 0.8)";
          // }
          // console.log(item);
          // console.log(item.optionsArrangementMode);
          // 调换横向  竖向
          if (
            (item.type == 5 && item.optionsArrangementMode == 1) ||
            (item.type == 4 && item.optionsArrangementMode == 2) ||
            (item.type == 6 &&
              item.optionsArrangementMode == 2 &&
              item.subType == 3)
          ) {
            data = {
              left:
                sizeWidth * i +
                sizeRight * i +
                item.points[0].x +
                item.source.left,
              top:
                sizeHeight * j +
                sizeBottom * j +
                item.points[0].y +
                item.source.top,
              width: sizeWidth,
              height: sizeHeight,
            };
          }
          // // 如果是缺考标记 特殊处理下
          if (item.type == 6 && item.subType == 1) {
            data = {
              left: sizeWidth * i + item.points[0].x + item.source.left,
              top: sizeHeight * j + item.points[0].y + item.source.top,
              width: sizeWidth,
              height: sizeHeight,
            };
          }

          this.setfillRect(data);
        }
      }
    },
    // 根据传入的点位绘制cavas设置点位
    setStrokeRect(postion) {
      this.ctx.strokeRect(
        postion.left / this.proportion,
        postion.top / this.proportion,
        postion.width / this.proportion,
        postion.height / this.proportion
      );
    },
    // 绘制大框中的小框  针对定位点
    setCountPoint(item) {
      let data = {};
      data.width = item.points[1].x - item.points[0].x;
      data.height = item.points[3].y - item.points[0].y;
      data.top = item.source.top + item.points[0].y;
      data.left = item.source.left + item.points[0].x;
      this.ctx.lineWidth = 1;
      // if (type) {
      this.ctx.fillStyle = "rgba(173, 213, 92, 0.8)";
      // }
      //  else {
      //   this.ctx.fillStyle = "rgba(4, 195, 145, 0.2)";
      // }
      // // console.log(item);
      // // console.log(type);
      // if (item.type == 6) {
      //   if (!type) {
      //             this.ctx.fillStyle = "rgba(0, 0, 255, 0.8)";
      //   } else {
      //     this.ctx.fillStyle = "rgba(0, 0, 255, 0.2)";
      //   }
      // }
      this.setfillRect(data);
    },
    // 绘制方块
    setfillRect(postion) {
      this.ctx.fillRect(
        postion.left / this.proportion,
        postion.top / this.proportion,
        postion.width / this.proportion,
        postion.height / this.proportion
      );
    },
    // 鼠标落下
    mousedown(e) {
      this.flag = true;
      this.indexHiddenBox = {};
      this.x = e.offsetX; // 鼠标落下时的X
      this.y = e.offsetY; // 鼠标落下时的Y
      //   pointer-events: none;
      // hidden-div
      let practice_item = document.getElementsByClassName("hidden-div");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        practice_item[i].style.pointerEvents = "none";
        practice_item[i].style.userSelect = "none";
      }
    },
    // 鼠标抬起
    mouseupAll() {
      // console.log("触发了");
      // 如果是非抬起状态  不管
      clearTimeout(this.setClickTime);
      let practice_item = document.getElementsByClassName("hidden-div");
      let practice_item_length = practice_item.length;
      for (let i = 0; i < practice_item_length; i++) {
        practice_item[i].style.pointerEvents = "";
        practice_item[i].style.userSelect = "";
      }
      if (this.flag == false) {
        return;
      }
      // 判断下重复请求
      let time = new Date().getTime();
      if (this.time && time - this.time < 1000) {
        return;
      }
      this.time = new Date().getTime();
      // 鼠标抬起后 先判断是否存在值
      this.setCoordinatePoint();
      this.flag = false;
    },
    // 鼠标抬起
    mouseup() {
      // console.log("触发了");
      // 鼠标抬起后 先判断是否存在值
      this.setCoordinatePoint();
      this.flag = false;
    },
    mousemoveAll(e) {
      if (this.flag) {
        // 获取当前盒子 在移动鼠标的时候禁用复制
        let locationBox = document.getElementById("locationBox");
        let { scrollTop, scrollLeft } = this.$refs.markSheet;
        let { left } = this.$refs.locationBox.style;
        left = Number(left.substr(0, left.length - 2));
        let clientX = e.x + scrollLeft;
        let clientY = e.y - 134 + scrollTop;
        let divx1 = locationBox.offsetLeft;
        let divy1 = locationBox.offsetTop;
        let divx2 = locationBox.offsetLeft + locationBox.offsetWidth;
        let divy2 = locationBox.offsetTop + locationBox.offsetHeight;

        if (
          clientX < divx1 ||
          clientX > divx2 ||
          clientY < divy1 ||
          clientY > divy2
        ) {
          let mos = {};

          mos.offsetX = Number(e.offsetX) + scrollLeft - left;
          // 如果Y小于小于当前盒子的位置  直接赋为-1
          if (e.y < 134.44) {
            mos.offsetY = -1;
          } else {
            mos.offsetY = Number(e.offsetY) + scrollTop - 40;
          }
          // 加一个判断  如果说鼠标移出了左边的盒子
          let clientWidth = document.documentElement.clientWidth;
          if (e.offsetX >= clientWidth - 446) {
            mos.offsetY = mos.offsetY - 45;
          }

          this.drawRect(mos);
        }
      } else {
        // console.log("1111");
      }
    },
    // 鼠标移动
    mousemove(e) {
      // if (e) {
      //   return;
      // }
      this.drawRect(e);
    },

    // 绘制图形
    drawRect(e) {
      // console.log(this.flag);
      if (this.flag) {
        let x = this.x;
        let y = this.y;
        let offsetX = e.offsetX;
        let offsetY = e.offsetY;
        if (x < 1) {
          x = 1;
        }
        if (y < 1) {
          y = 1;
        }
        if (x + 1 > this.viewWidth) {
          x = x - 1;
        }
        if (y + 1 > this.viewHeight) {
          y = y - 1;
        }
        if (offsetX < 1) {
          offsetX = 1;
        }
        if (offsetY < 1) {
          offsetY = 1;
        }
        // console.log(offsetX + 5);
        // console.log(this.viewWidth + Number(this.oldLeft));
        if (offsetX + 1 > this.viewWidth) {
          offsetX = this.viewWidth - 1;
        }

        if (offsetY + 1 > this.viewHeight) {
          offsetY = this.viewHeight - 1;
        }
        // console.log(this.viewHeight);
        // console.log(offsetY + "----------");
        // console.log(this.viewHeight);
        this.lineData = {
          //  x轴位置
          left: x,
          //  y轴位置
          top: y,
          //  绘制出来的盒子宽度
          width: offsetX - x,
          //  绘制出来的盒子高度
          height: offsetY - y,
        };
        this.ctx.lineWidth = 1;
        this.ctx.strokeStyle = "rgba(0, 0, 255, 1)";
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.beginPath();
        // console.log(e.offsetX - x);
        // console.log(e.offsetX);
        // console.log(x);
        this.ctx.strokeRect(x, y, offsetX - x, offsetY - y);
        this.setAllLineData();
        this.setLine();
      }
    },
    // 设置坐标点
    setCoordinatePoint() {
      // 坐标点可能是负值 需要重新计算传给后台 this.sheetLineList
      // console.log(this.lineData);
      if (
        !this.lineData.left &&
        !this.lineData.top &&
        !this.lineData.width &&
        !this.lineData.height
      ) {
        return;
      }
      let lineData = Object.assign({}, this.lineData);
      if (lineData.width < 0) {
        lineData.width = Math.abs(lineData.width);
        lineData.left = lineData.left - lineData.width;
      }
      if (lineData.height < 0) {
        lineData.height = Math.abs(lineData.height);
        lineData.top = lineData.top - lineData.height;
      }
      // 如果长宽都小于10 就不添加
      // if (lineData.height < 10 && lineData.width < 10) {
      //   return;
      // }
      this.lineData = lineData;
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.beginPath();
      // console.log(lineData.left);
      // console.log(lineData.top);
      this.ctx.strokeRect(
        lineData.left,
        lineData.top,
        lineData.width,
        lineData.height
      );
      // 根据比例恢复坐标点;
      this.setDefaultPoint();
      this.setAllLineData();
      this.setLine();
    },
    // 根据比例恢复坐标点; 并且判断当前类型进行逻辑处理
    setDefaultPoint() {
      let data = Object.assign({}, this.lineData);
      let times = this.proportion;
      data.left = parseInt(data.left * times);
      data.top = parseInt(data.top * times);
      data.width = parseInt(data.width * times);
      data.height = parseInt(data.height * times);

      // console.log(this.indexPoint);

      // 设定好当前需要提交的值
      // 如果是点定位  最多不能超过4条
      // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.indexPoint = data;
      this.indexPoint.priority = this.GET_INDEX_SHEET_MARK.priority;
      // 如果宽度 高度小于10 不进行后边的操作
      if (this.indexPoint.width < 20 || this.indexPoint.height < 15) {
        // 清除所有点
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        return;
      }
      // 如果是定位点  设置选中的默认值;
      if (this.GET_LABELLED_TYPE == 1) {
        // if (this.sheetLineList.length == 4) {
        //   this.$message({
        //     message: "定位点最多4个",
        //     type: "warning",
        //     showClose: true,
        //   });
        //   this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        //   return;
        // }
        // 点定位的情况
        this.setIndexType();
        // 打开抽屉
        this.drawer = true;
      }
      // 如果是标题
      if (this.GET_LABELLED_TYPE == 2) {
        if (this.sheetLineList.length == 1) {
          this.$message({
            message: "本页已有标题",
            type: "warning",
            showClose: true,
          });
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          return;
        }
        this.submitMark();
      }
      // 如果是考号  打开弹出窗
      this.subType = 1;
      if (this.GET_LABELLED_TYPE == 3) {
        if (this.sheetLineList.length == 3) {
          this.$message({
            message: "本页已有考号",
            type: "warning",
            showClose: true,
          });
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          return;
        }
        this.optionsLength = 12;
        this.optionsArrangementMode = 2;
        this.optionsMaxLength = 14;
        // 选项的个数
        this.questionNum = 10;
        // 打开抽屉
        this.studentExamIdDrawer = true;
      }
      // 如果是客观题
      if (this.GET_LABELLED_TYPE == 4) {
        // this.optionsContinuous = 1;
        this.optionsMaxLength = 26;
        // this.optionsLength = 4;
        this.optionsArrangementMode = 1;
        // this.questionNum = 5;
        this.scoreMiss = 0;
        let array = [];

        this.questionNumStart = this.GET_LAST_QUESTION;
        // console.log(this.questionNumStart);
        this.questionNumEnd = this.GET_LAST_QUESTION + this.questionNum - 1;
        let maxQuestionNumber = "";
        // 保留最后一位题号
        if (this.oldQuestionNumberMax) {
          if (!isNaN(this.oldQuestionNumberMax)) {
            maxQuestionNumber = Number(this.oldQuestionNumberMax);
            this.questionNumStart = maxQuestionNumber + 1;
            this.questionNumEnd = maxQuestionNumber + this.questionNum;
          }
        }

        for (let i = 0; i < this.questionNum; i++) {
          array.push({
            value: Number(this.GET_SHEET_QUESTION_MAX || 1) + i,
            examQuestionNumber:
              this.questionSerialNumber +
              (Number(this.questionNumStart) + i * this.questionSpacing),
            isOption: 0,
            score: this.score,
            scoreMiss: this.scoreMiss,
          });
        }
        this.questionNumList = array;
        this.startValue = this.GET_SHEET_QUESTION_MAX;
        this.choiceQuestionDrawer = true;
      }

      // 如果是在追加区域
      if (this.indexExtendQuestion.id && this.GET_LABELLED_TYPE == 5) {
        this.submitMark();
        return;
      }
      // console.log(this.GET_LABELLED_TYPE);
      if (this.subjectiveTypeId && this.GET_LABELLED_TYPE == 5) {
        this.submitMark();
        return;
      }
      // 如果是主观题
      if (this.GET_LABELLED_TYPE == 5) {
        // 获取最后一道小题题号
        // let questionMaxNum = 1;
        this.subType = 2;
        this.subjectiveType = 0;
        this.questionNum = 1;
        this.optionsArrangementMode = 1;
        this.questionNumList = [];
        for (let i = 0; i < this.questionNum; i++) {
          this.questionNumList.push({
            value: Number(this.GET_SHEET_QUESTION_MAX) + i,
            examQuestionNumber: Number(this.GET_LAST_QUESTION) + i,
            score: this.score,
            isOption: this.subjectiveType ? 1 : 0,
          });
        }

        this.subjectiveQuestionDrawer = true;
      }
      // let
      if (this.GET_LABELLED_TYPE == 6) {
        if (
          this.sheetLineList.filter(
            (i) => i.subType == this.specialLocationType
          ).length > 0
        ) {
          let str = "";
          if (this.specialLocationType == 1) {
            str = "缺考标记";
          }
          if (this.specialLocationType == 2) {
            str = "学科标记";
          }
          if (this.specialLocationType == 3) {
            str = "A,B卷标记";
          }
          this.$message({
            message: `本页已有${str}`,
            type: "warning",
            showClose: true,
          });
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          return;
        }
        // this.sheetLineList.length
        if (this.specialLocationType != 3) {
          this.submitMark();
        } else {
          this.optionsArrangementMode = 1;
          this.specialDrawer = true;
        }
      }
      // specialLocationType
    },
    // 设置默认左上 右上 左下 右下值
    setIndexType() {
      // 左上角
      if (
        this.indexPoint.left < this.oldWidth / 2 &&
        this.indexPoint.top < this.oldHeight / 2
      ) {
        this.currentPosition = this.positionList[0].value;
      }
      // 右上角
      if (
        this.indexPoint.left > this.oldWidth / 2 &&
        this.indexPoint.top < this.oldHeight / 2
      ) {
        this.currentPosition = this.positionList[1].value;
      }
      // 左下角
      if (
        this.indexPoint.left < this.oldWidth / 2 &&
        this.indexPoint.top > this.oldHeight / 2
      ) {
        this.currentPosition = this.positionList[2].value;
      }
      // 右下角
      if (
        this.indexPoint.left > this.oldWidth / 2 &&
        this.indexPoint.top > this.oldHeight / 2
      ) {
        this.currentPosition = this.positionList[3].value;
      }

      // 判断下 如果赋值的当前项在选择列表中已经存在  就直接把下一项的值传过去
      let array = [];
      this.positionList.forEach((item) => {
        array.push(item.value);
      });
      let pointType = false;
      // 过滤下已被选中的
      this.sheetLineList.forEach((index) => {
        array = array.filter((item) => item != index.currentPosition);
        if (this.currentPosition == index.currentPosition) {
          pointType = true;
        }
      });
      // 将未被选中的第一项赋值给选中
      if (pointType) {
        this.currentPosition = array.filter(
          (i) => i != this.currentPosition
        )[0];
      }
      if (!this.currentPosition) {
        this.currentPosition = "assistantPoint";
      }
      // console.log(this.currentPosition);
    },
    // 编辑定位点的时候
    setPoint() {
      this.sheetLineList = this.sheetLineList.map((item) => {
        if (item.currentPosition == this.saveData.currentPosition) {
          item.currentPosition = this.currentPosition;
          // 如果是点定位  可能是 正方形  可能是三角形

          item.subType = this.subType;
        }
        return item;
      });
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      // console.log(sheetArr);
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      this.$emit("submitIndexImg", 1);
    },
    // 编辑考号的时候
    setExamIdPoint() {
      this.saveData.subType = this.subType;
      this.saveData.optionsLength = this.optionsLength;
      this.saveData.optionsArrangementMode = this.optionsArrangementMode;
      this.saveData.optionsStyle = this.optionsStyle;
      this.sheetLineList.map((item) => {
        if (item.id == this.saveData.id) {
          Object.assign(item, this.saveData);
        }
      });
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      // console.log(JSON.parse(JSON.stringify(this.saveData)));
      this.$emit("submitIndexImg", 1, this.saveData.id);
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制cavas
      this.setAllLineData();
      this.setLine();
    },
    // 编辑客观题
    setchoiceQuestionPoint() {
      this.saveData.subType = this.subType;
      this.saveData.optionsLength = this.optionsLength;
      this.saveData.optionsContinuous = this.optionsContinuous;
      this.saveData.questionNum = this.questionNum;
      this.saveData.questionSerialNumber = this.questionSerialNumber;
      this.saveData.questionSpacing = this.questionSpacing;

      // 如果是连续题号 直接累加添加
      if (this.saveData.optionsContinuous == 1) {
        let array = [];
        for (let i = 0; i < this.questionNum; i++) {
          array.push({
            value: Number(this.startValue) + i,
            examQuestionNumber: Number(this.questionNumStart) + i,
            isOption: 0,
            score: this.score,
            scoreMiss: this.scoreMiss,
            templateId: this.saveData.id,
          });
        }
        this.saveData.questionNumList = array;
      } else {
        this.questionNumList.map((item) => {
          item.score = this.score;
          item.scoreMiss = this.scoreMiss;
        });
        this.saveData.questionNumList = this.questionNumList;
      }
      this.saveData.optionsArrangementMode = this.optionsArrangementMode;
      this.saveData.optionsStyle = this.optionsStyle;
      this.sheetLineList.map((item) => {
        if (item.id == this.saveData.id) {
          Object.assign(item, this.saveData);
        }
      });
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      this.$emit("submitIndexImg", 1, this.saveData.id);
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制cavas
      this.setAllLineData();
      this.setLine();
    },
    // 修改主观题信息
    setSubjectiveQuestionPoint() {
      this.saveData.subType = this.subType;
      let type = false;
      this.questionNumList.forEach((item) => {
        item.score = this.score;
        item.isOption = this.subjectiveType ? 1 : 0;
        if (!item.examQuestionNumber) {
          type = true;
        }
      });
      if (type) {
        this.$message({
          message: "题号不能为空",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.saveData.questionNumList = this.questionNumList;
      this.saveData.questionNum = this.questionNumList.length;
      this.saveData.subjectiveType = this.subjectiveType;
      if (this.saveData.subjectiveType == 1) {
        if (this.irregular) {
          this.saveData.subjectiveType = 2;
        }
      }

      // irregular
      if (this.subjectiveType) {
        this.saveData.optionsArrangementMode = this.optionsArrangementMode;
      }
      if (
        this.saveData.subjectiveType == 1 &&
        !this.saveData.chooseQuestionLocation
      ) {
        // console.log("111111111");
        this.subjectiveTypeId = this.saveData.id;
      } else {
        this.subjectiveTypeId = "";
      }
      if (this.saveData.subjectiveType == 2) {
        if (this.saveData.chooseQuestionLocationArr) {
          if (
            this.saveData.chooseQuestionLocationArr.length !=
            this.saveData.questionNum
          ) {
            this.subjectiveTypeId = this.saveData.id;
            this.subjectiveShowType = 2;
          } else {
            this.subjectiveTypeId = "";
            this.subjectiveShowType = "";
          }
        } else {
          this.subjectiveShowType = 2;
          this.subjectiveTypeId = this.saveData.id;
          this.saveData.chooseQuestionLocationArr = [];
        }

        // &&this.saveData.chooseQuestionLocationArr
      }
      if (
        this.GET_LABELLED_TYPE == 5 &&
        this.saveData.subjectiveType == 1 &&
        this.saveData.questionNum < 2
      ) {
        this.$message({
          message: "选做题至少两道题!",
          type: "warning",
          showClose: true,
        });
        return;
      }

      this.sheetLineList.map((item) => {
        if (item.id == this.saveData.id) {
          Object.assign(item, this.saveData);
        }
      });

      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      // console.log(this.saveData);
      this.$emit("submitIndexImg", 1, this.saveData.id);
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制cavas
      this.setAllLineData();
      this.setLine();
      this.saveType = "save";
      this.saveData = {};
      if (this.questionNumList && this.questionNumList.length > 0) {
        this.$store.commit(
          "SET_LAST_QUESTION",
          this.questionNumList[this.questionNumList.length - 1]
            .examQuestionNumber
        );
      }

      this.subjectiveQuestionDrawer = false;
    },
    // 判断如果新增的数据和以前的数据冲突  就强行让用户删除后提交
    getQuestionRepetitive() {
      let itemMarkSubjective = new Array();
      let itemMarkObjective = new Array();
      this.GET_SHEET_MARK_LIST.forEach((item) => {
        let indexMarkSubjective = new Array();
        let indexMarkObjective = new Array();
        // 主观题数组
        if (!item.markSubjective) {
          indexMarkSubjective = [];
        } else {
          indexMarkSubjective = JSON.parse(item.markSubjective);
        }
        // 客观题数组
        if (!item.markObjective) {
          indexMarkObjective = [];
        } else {
          indexMarkObjective = JSON.parse(item.markObjective);
        }
        // console.log(itemMarkSubjective)
        itemMarkSubjective = itemMarkSubjective.concat(indexMarkSubjective);
        itemMarkObjective = itemMarkObjective.concat(indexMarkObjective);
      });
      let array = [];
      itemMarkSubjective.forEach((item) => {
        item.questionNumList.forEach((index) => {
          this.questionNumList.map((itemC) => {
            if (itemC.value == index.value && item.id != this.saveData.id) {
              array.push(index.examQuestionNumber);
            }
          });
        });
      });
      itemMarkObjective.forEach((item) => {
        item.questionNumList.forEach((index) => {
          this.questionNumList.map((itemC) => {
            // console.log(item);
            if (itemC.value == index.value && item.id != this.saveData.id) {
              array.push(index.examQuestionNumber);
            }
          });
        });
      });
      // console.log(this.saveData);

      if (array.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "数据异常",
          message: h("p", null, [
            h("span", null, "试题"),
            h("span", { style: "color: red" }, array.join(",")),
            h("span", null, "唯一值重复，请删除后在添加试题！ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return true;
      } else {
        return false;
      }
    },
    getQuestionBlank() {
      if (this.GET_LABELLED_TYPE != 5) {
        return false;
      }
      let arr = [];
      this.questionNumList.map((item) => {
        let str = item.examQuestionNumber.toString();
        if (str.indexOf("  ") != -1) {
          arr.push(str);
        } else {
          let newStr = str.trim();
          if (newStr != str) {
            arr.push(str);
          }
        }
      });
      // console.log(arr);
      if (arr.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "数据异常",
          message: h("p", null, [
            h("span", null, "试题"),
            h("span", { style: "color: red" }, arr.join(",")),
            h("span", null, "题号包含首尾空格或连续空格，请修改后提交！ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return true;
      } else {
        return false;
      }
      // console.log();
    },
    // 判断是编辑还是保存  保存就是直接提交  编辑就是修改本地数据
    saveLineData() {
      if (this.optionsLength == undefined) {
        this.$message({
          message: `请输入选项个数`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.questionNum == undefined) {
        this.$message({
          message: `请输入题量`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (this.score == undefined) {
        this.$message({
          message: `请输入题量`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      // 添加判断  判断唯一标识value是否重复  如果重复则不允许添加试题
      if (this.getQuestionRepetitive()) return;
      // 添加判断 如果试题题号中存在多个空格  禁止提交
      if (this.getQuestionBlank()) return;
      // 添加判断  保留最后一位题号
      if (this.GET_LABELLED_TYPE == 4 || this.GET_LABELLED_TYPE == 5) {
        this.oldQuestionNumberMax =
          this.questionNumList[
            this.questionNumList.length - 1
          ].examQuestionNumber;
      }
      if (this.saveType == "save") {
        // 如果是添加试题  需要判断题号是否重复  如果重复  直接拒绝提交 前端先做判断
        let questionErrList = [];
        if (this.GET_LABELLED_TYPE == 4 || this.GET_LABELLED_TYPE == 5) {
          this.questionArrList.map((item) => {
            this.questionNumList.map((index) => {
              item.questionNumList.forEach((i) => {
                // index.questionNumList.forEach((arr) => {
                if (index.examQuestionNumber == i.examQuestionNumber) {
                  questionErrList.push(index.examQuestionNumber.toString());
                }
                // });
              });
            });
          });
          // console.log(questionErrList);
          this.questionNumList.forEach((item) => {
            this.questionNumList.forEach((index) => {
              if (
                item.examQuestionNumber == index.examQuestionNumber &&
                item.value != index.value
              ) {
                questionErrList.push(index.examQuestionNumber.toString());
              }
            });
          });
          // console.log(questionErrList);
        }
        // console.log(questionErrList);
        questionErrList = [...new Set(questionErrList)];
        if (questionErrList.length > 0) {
          this.$message({
            message: `题号${questionErrList.join(",")}重复，请修改后提交`,
            type: "warning",
            showClose: true,
          });
          return;
        }
        // this.subjectiveTypeId
        this.submitMark();
      } else {
        // 本地修改数据 如果是定位点
        if (this.saveData.type == 1) {
          this.setPoint();
        }
        // 如果是考号
        if (this.saveData.type == 3) {
          this.setExamIdPoint();
        }
        // 如果是修改试题  需要判断题号是否重复  如果重复  直接拒绝提交 前端先做判断
        // let questionEditType = false;
        let questionErrList = [];
        if (this.saveData.type == 4 || this.saveData.type == 5) {
          this.questionArrList.map((item) => {
            if (item.id != this.saveData.id) {
              this.questionNumList.map((index) => {
                item.questionNumList.forEach((i) => {
                  if (index.examQuestionNumber == i.examQuestionNumber) {
                    questionErrList.push(index.examQuestionNumber.toString());
                    // questionEditType = true;
                  }
                });
              });
            }
          });
          this.questionNumList.forEach((item) => {
            this.questionNumList.forEach((index) => {
              if (
                item.examQuestionNumber == index.examQuestionNumber &&
                item.value != index.value
              ) {
                questionErrList.push(index.examQuestionNumber.toString());
              }
            });
          });
        }

        questionErrList = [...new Set(questionErrList)];

        if (questionErrList.length > 0) {
          this.$message({
            message: `题号${questionErrList.join(",")}重复，请修改后提交`,
            type: "warning",
            showClose: true,
          });
          return;
        }
        if (this.misjudgement()) return;

        // 如果是客观题
        if (this.saveData.type == 4) {
          this.setchoiceQuestionPoint();
        }
        // 如果是主观题
        if (this.saveData.type == 5) {
          this.setSubjectiveQuestionPoint();
          return;
        }
        // studentExamIdDrawer
        this.saveType = "save";
        this.saveData = {};
        this.drawer = false;
        this.studentExamIdDrawer = false;
        this.subjectiveQuestionDrawer = false;
        this.choiceQuestionDrawer = false;
      }
    },
    setExamFrame(item) {
      let data = JSON.parse(JSON.stringify(item));
      data.source = {};
      data.points = {};
      data.source.top = data.top;
      data.source.left = data.left;
      data.source.width = data.width;
      data.source.height = data.height;
      // console.log(data);
      delete data.left;
      delete data.top;
      delete data.width;
      delete data.height;
      data.id = new Date().getTime();
      // 如果是框选的选做题 记录下状态 下次框选只能选择标记
      if (data.subjectiveType) {
        this.subjectiveTypeId = data.id;
        this.subjectiveShowType = data.subjectiveType;
        // console.log(data);
        if (this.subjectiveShowType == 2) {
          // console.log(data);
          data.chooseQuestionLocationArr = [];
          this.$message({
            message: `请在框选"选做题${data.questionNumList[0].examQuestionNumber}"识别标记！`,
            type: "warning",
            showClose: true,
            duration: 3000,
          });
        } else {
          this.$message({
            message: "请在框选选做题识别标记！",
            type: "warning",
            showClose: true,
          });
        }
      }
      if (this.questionNumList && this.questionNumList.length > 0) {
        this.$store.commit(
          "SET_LAST_QUESTION",
          this.questionNumList[this.questionNumList.length - 1]
            .examQuestionNumber
        );
      }

      // 添加成功后 合并对象 将其添加在显示在页面的列表组里面
      this.sheetLineList.push(Object.assign(data));
      // 选做题没有内部框选标记的话 就不提交
      let sheetArr = this.getArr();

      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // 重新绘制cavas
      this.setAllLineData();
      this.setLine();
      // 关闭弹窗
      this.btnLoading = false;
      this.drawer = false;
      this.studentExamIdDrawer = false;
      this.choiceQuestionDrawer = false;
      this.subjectiveQuestionDrawer = false;
      this.lineData = {};
      if (!this.subjectiveTypeId) {
        this.$emit("submitIndexImg", 1, data.id);
      }
    },
    // 提交修改的项
    saveExampapersheetmark(markSubjective, id, cutId) {
      let data = {
        sheetUuid: this.$route.query.sheetId,
        id: id,
        examPaperId: this.GET_INDEX_SHEET_MARK.examPaperId,
        markSubjective: JSON.stringify(markSubjective),
        cutId: cutId,
      };
      if (this.$route.query.password) {
        data.password = "WENTONGCLOUD";
      }
      this.otherList = [];
      // 清除所有点
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      // console.log(data);
      saveExampapersheetmark(data)
        .then(() => {
          // 如果是在当前页的话
          this.sheetLineList.map((item) => {
            if (item.id == this.indexExtendQuestion.id) {
              item.extendList = this.indexExtendQuestion.extendList;
            }
          });
          if (id != this.GET_INDEX_SHEET_MARK.id) {
            this.$store.commit("SET_PRIORITY", data.markSubjective);
          } else {
            this.$store.commit("SET_SHEET_LINE_LIST", this.sheetLineList);
          }
          this.indexExtendQuestion = {};

          // 重新绘制cavas
          this.setAllLineData();
          this.setLine();
          loading.close();
        })
        .catch(() => {
          loading.close();
          // 重新绘制cavas
          this.setAllLineData();
          this.setLine();
        });
    },
    // 添加追加区域
    setAddLocation(data) {
      let subData = {
        source: {
          left: data.left,
          top: data.top,
          height: data.height,
          width: data.width,
        },
        priority: data.priority,
        id: new Date().getTime(),
      };
      if (!this.indexExtendQuestion.extendList) {
        this.indexExtendQuestion.extendList = [];
      }
      this.indexExtendQuestion.extendList.push(subData);

      // 根据试题的 priority 页码 组装好需要提交的数据
      let markSubjective = [];
      let id = "";
      // 获取到修改的项
      this.GET_SHEET_MARK_LIST.forEach((item) => {
        // let index = Object.assign({}, item);
        if (item.priority == this.indexExtendQuestion.priority) {
          markSubjective = JSON.parse(item.markSubjective);
          id = item.id;
        }
      });
      // 获取到需要修改的项
      markSubjective.map((item) => {
        if (item.id == this.indexExtendQuestion.id) {
          item.extendList = this.indexExtendQuestion.extendList;
        }
      });
      // console.log(markSubjective);
      this.saveExampapersheetmark(
        markSubjective,
        id,
        this.indexExtendQuestion.id
      );
      this.lineData = {};
      // console.log(markSubjective);
    },
    // 判断下选做题框选是否在当前框位置内
    setLineLocation(data) {
      // let subObj = this.sheetLineList.filter(
      //   (item) => item.id == this.subjectiveTypeId
      // )[0];
      // console.log(this.sheetLineList);
      // console.log(this.subjectiveTypeId);
      // console.log(subObj);
      // if (
      //   data.left + data.width > subObj.source.left + subObj.source.width ||
      //   data.top + data.height > subObj.source.top + subObj.source.height ||
      //   data.left < subObj.source.left ||
      //   data.top < subObj.source.top
      // ) {
      // this.$message({
      //   message: "请在选做题框内框选识别标记！",
      //   type: "warning",
      //   showClose: true,
      // });
      // // 清除所有点
      // this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // // 重新绘制cavas
      // this.setAllLineData();
      // this.setLine();
      // return;
      // }
      data.optionsLength = 1;
      this.exampapersheetmarkFindMark(data);
    },
    // 提交
    submitMark() {
      let data = {
        id: this.indexSheetMark.id,
        sheetUuid: this.indexSheetMark.sheetUuid,
        type: this.GET_LABELLED_TYPE,
      };
      // console.log(this.questionNumStart);
      // 当前如果是标注定位点
      if (this.GET_LABELLED_TYPE == 1) {
        data.currentPosition = this.currentPosition;

        data.subType = this.subType;
      }
      // 线定位 判断直接提交 不需要识别
      // if (this.GET_LABELLED_TYPE == 1 && this.locationType == 3) {
      //   Object.assign(data, this.indexPoint);
      //   this.setExamFrame(data);
      //   return;
      // }
      //
      // 如果框选标题
      if (this.GET_LABELLED_TYPE == 2) {
        // data.currentPosition = this.indexPoint;
      }
      // 如果框选考号
      if (this.GET_LABELLED_TYPE == 3) {
        data.subType = this.subType;
        data.optionsLength = this.optionsLength;
        data.optionsArrangementMode = this.optionsArrangementMode;
        data.optionsStyle = this.optionsStyle;
        data.questionNum = 10;
      }
      // 如果框选客观题（选择题）
      if (this.GET_LABELLED_TYPE == 4) {
        //   optionsContinuous questionNum questionNumStart questionNumEnd
        data.subType = this.subType;
        data.optionsLength = this.optionsLength;
        data.optionsContinuous = this.optionsContinuous;
        data.questionNum = this.questionNum;
        data.optionsArrangementMode = this.optionsArrangementMode;
        data.questionSerialNumber = this.questionSerialNumber;
        data.questionSpacing = this.questionSpacing;
        if (data.optionsContinuous == 1) {
          let array = [];
          for (let i = 0; i < this.questionNum; i++) {
            array.push({
              value: Number(this.startValue) + i,
              examQuestionNumber: Number(this.questionNumStart) + i,
              isOption: 0,
              score: this.score,
              scoreMiss: this.scoreMiss,
            });
          }
          data.questionNumList = array;
        } else {
          this.questionNumList.map((item) => {
            item.score = this.score;
            item.scoreMiss = this.scoreMiss;
          });
          data.questionNumList = this.questionNumList;
        }
        data.optionsStyle = this.optionsStyle;
      }
      if (this.misjudgement()) return;
      // console.log(this.questionNumStart);
      // console.log(this.GET_LABELLED_TYPE);
      if (this.subjectiveTypeId && this.GET_LABELLED_TYPE == 5) {
        Object.assign(data, this.indexPoint);
        this.setLineLocation(data);
        return;
      }
      // 如果是框选追加区域
      if (this.indexExtendQuestion.id && this.GET_LABELLED_TYPE == 5) {
        Object.assign(data, this.indexPoint);
        this.setAddLocation(data);
        return;
      }

      // 主观题
      if (this.GET_LABELLED_TYPE == 5) {
        //判断框选了几道题
        data.subType = this.subType;
        this.questionNumList.forEach((item) => {
          item.score = this.score;
          item.isOption = this.subjectiveType ? 1 : 0;
        });

        data.questionNumList = this.questionNumList;
        data.subjectiveType = this.subjectiveType;
        // 如果勾选了不规律选做题

        if (this.irregular && data.subjectiveType == 1) {
          data.subjectiveType = 2;
        }
        data.questionNum = this.questionNumList.length;
        data.optionsArrangementMode = this.optionsArrangementMode;
      }
      let type = false;
      this.questionNumList.forEach((item) => {
        if (!item.examQuestionNumber) {
          type = true;
        }
      });
      if (type && this.GET_LABELLED_TYPE == 5) {
        this.$message({
          message: "题号不能为空",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (
        this.GET_LABELLED_TYPE == 5 &&
        data.subjectiveType &&
        data.questionNum < 2
      ) {
        this.$message({
          message: "选做题至少两道题!",
          type: "warning",
          showClose: true,
        });
        return;
      }

      // 其他标记
      if (this.GET_LABELLED_TYPE == 6) {
        data.subType = this.specialLocationType;

        if (this.specialLocationType == 3) {
          data.optionsLength = 2;
          data.optionsArrangementMode = this.optionsArrangementMode;
          data.questionNum = 1;
        }
        if (this.specialLocationType == 1) {
          data.optionsLength = 1;
          data.optionsArrangementMode = this.optionsArrangementMode;
          data.questionNum = 1;
        }
      }

      Object.assign(data, this.indexPoint);
      // 如果是考号 但是是条形码 和手写考号 不需要后台判断 直接前端添加
      if (
        (this.GET_LABELLED_TYPE == 3 && data.subType != 1) ||
        this.GET_LABELLED_TYPE == 5
      ) {
        this.setExamFrame(data);
        return;
      }
      this.exampapersheetmarkFindMark(data);
    },
    async exampapersheetmarkFindMark(data) {
      this.btnLoading = true;
      exampapersheetmarkFindMark(data)
        .then((res) => {
          // 根据相对坐标生成新的坐标点
          delete data.left;
          delete data.top;
          delete data.width;
          delete data.height;
          delete data.count;
          delete data.sheetUuid;
          data.id = new Date().getTime();
          // 如果是选择题的时候 给每项都加一个templateId
          if (this.GET_LABELLED_TYPE == 4) {
            data.questionNumList.map((index) => {
              index.templateId = data.id;
            });
          }
          // console.log(data.subjectiveType);
          if (this.subjectiveTypeId && this.GET_LABELLED_TYPE == 5) {
            this.sheetLineList = this.sheetLineList.map((item) => {
              if (this.subjectiveTypeId == item.id) {
                // 普通选做题
                if (item.subjectiveType == 1) {
                  data.id = item.id;
                  item.chooseQuestionLocation = Object.assign(
                    data,
                    res.data.data
                  );
                  this.subjectiveTypeId = "";
                  this.subjectiveShowType = "";
                } else {
                  // 特殊选做题  针对选做题答题卡未对齐情况

                  data.id = item.id;
                  data.questionNum = 1;
                  if (!item.chooseQuestionLocationArr) {
                    item.chooseQuestionLocationArr = [];
                  }
                  item.chooseQuestionLocationArr.push(
                    Object.assign(data, res.data.data)
                  );
                  if (
                    item.chooseQuestionLocationArr.length ==
                    item.questionNumList.length
                  ) {
                    this.subjectiveTypeId = "";
                    this.subjectiveShowType = "";
                  }
                }
              }
              return item;
            });
          } else {
            this.sheetLineList.push(Object.assign(data, res.data.data));
          }
          // console.log(this.sheetLineList);
          // 添加成功后 合并对象 将其添加在显示在页面的列表组里面

          // 清除所有点
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
          // 重新绘制cavas
          this.setAllLineData();

          // 等待循环执行完成
          this.setLine();
          // 选做题没有内部框选标记的话 就不提交
          let sheetArr = this.getArr();
          this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
          this.$emit("submitIndexImg", 1, data.id);
          // 延迟提交
          // 关闭弹窗
          if (this.questionNumList && this.questionNumList.length > 0) {
            this.$store.commit(
              "SET_LAST_QUESTION",
              this.questionNumList[this.questionNumList.length - 1]
                .examQuestionNumber
            );
          }
          // console.log(11111);
          this.btnLoading = false;
          this.drawer = false;
          this.studentExamIdDrawer = false;
          this.specialDrawer = false;
          this.choiceQuestionDrawer = false;
          this.lineData = {};
        })
        .catch(() => {
          this.btnLoading = false;
          this.close();
        });
    },
    getArr() {
      let sheetArr = this.sheetLineList.filter(
        (item) =>
          !item.subjectiveType ||
          (item.subjectiveType == 1 && item.chooseQuestionLocation) ||
          (item.subjectiveType == 2 &&
            item.chooseQuestionLocationArr.length == item.questionNum)
      );
      return sheetArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.markSheet {
  width: calc(100vw - 474px);
  height: calc(100vh - 140px);
  position: relative;
  overflow: scroll;

  // padding-bottom: 40px;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .location-box {
    position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .edit-operating-box.operating-box {
    border: 1px solid #f45454;
    background: rgba(255, 0, 0, 0.2);
    opacity: 1;
    z-index: 20;
    cursor: move;

    .circle-box {
      cursor: all-scroll;
    }

    .circle-box {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #ffffff;
      border-radius: 50%;
      border: 2px solid #f45454;
      z-index: 10;
    }

    // circle-left circle-right circle-top circle-bottom
    .circle-left {
      top: 50%;
      left: -6px;
      cursor: ew-resize;
    }

    .circle-right {
      top: 50%;
      right: -6px;
      cursor: ew-resize;
    }

    .circle-top {
      left: 50%;
      top: -6px;
      cursor: s-resize;
    }

    .circle-bottom {
      left: 50%;
      bottom: -6px;
      cursor: s-resize;
    }
  }

  .choose-question {
    position: absolute;
    // border: 1px solid #f45454;
    cursor: pointer;
    z-index: 20;
    opacity: 0;

    &:hover {
      opacity: 1;
      z-index: 21;

      .operating-btn-box {
        opacity: 1;

        // visibility: unset;
        display: block;
      }
    }

    .operating-btn-box {
      opacity: 0;
      position: absolute;
      left: -1px;
      bottom: -24px;
      white-space: nowrap;
      background: rgba(0, 0, 0, 0.6);
      padding: 5px 8px;
      line-height: 12px;
      display: none;

      // visibility: hidden;
      .el-button {
        color: #ffffff;
        font-size: 12px;
      }
    }
  }

  .operating-box-td.operating-box {
    // cursor: move;
    // &:hover {
    //   background: rgba(255, 0, 0, 0.2);
    // }
  }

  // .mark-sheet
  .operating-box {
    position: absolute;
    // border: 1px solid #f45454;

    opacity: 0;

    &:hover {
      opacity: 1;
      z-index: 19;

      .operating-btn-box {
        opacity: 1;
        // visibility: unset;
        display: block;
      }
    }

    .operating-btn-box {
      opacity: 0;
      position: absolute;
      left: -1px;
      bottom: -24px;
      white-space: nowrap;
      background: rgba(0, 0, 0, 0.6);
      padding: 5px 8px;
      line-height: 12px;
      display: none;

      // visibility: hidden;
      .el-button {
        color: #ffffff;
        font-size: 12px;
      }
    }
  }

  .index-hidden-box {
    display: none;
  }

  .student-exam-model {
    .student-exam-img-style {
      margin-top: 24px;
      height: 240px;
      border: 1px solid #e7e7e7;
      // text-align: center;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        // display:inline-block;
        padding: 36px 56px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.65);
        background-color: #f8f8f8;
        border: 1px dashed #979797;
        font-size: 18px;
      }
    }

    .student-exam-id-style {
      margin-top: 24px;
      border: 1px solid #979797;
      padding: 12px;
      overflow: scroll;
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .style-item {
        display: flex;
        justify-content: center;
        width: 100%;
        // .style-item {

        // }
        div {
          margin: 3px;
          padding: 0 5px;
          border: 1px solid #979797;
          font-size: 12px;
        }
      }
    }

    .style-item-h.student-exam-id-style {
      .style-item {
        display: block;
        width: auto;
      }
    }

    .student-examId-style {
      align-items: flex-start;

      .el-radio {
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }

    .tx-list {
      .el-radio {
        margin: 8px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .optional-box {
      background-color: #fbfbfb;
      padding: 12px;

      .model-item {
        .title {
          width: 96px;
          // flex-shrink: 0;
          // text-align: right;
        }
      }
    }

    .model-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      .question-num-list {
        margin-left: -8px;

        .el-input {
          margin: 8px;
          // &:first-child {
          //   margin-left: 0;
          // }
        }
      }

      .question-num {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .el-input {
          width: 44px;
          margin-bottom: 4px;

          // padding: 0;
          ::v-deep .el-input__inner {
            padding: 0;
            text-align: center;
          }
        }
      }

      .title {
        width: 76px;
        flex-shrink: 0;
        text-align: right;
      }
    }

    .el-radio.is-bordered + .el-radio.is-bordered {
      margin-left: 0;
    }

    .el-radio {
      margin-bottom: 0;
    }

    .el-radio--mini {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      ::v-deep.el-radio__input {
        display: none;
      }
    }
  }

  .model-message-box {
    .subjective-type-tips {
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      padding: 13px;
      margin-bottom: 24px;

      span {
        margin-right: 6px;
        padding: 0 6px;
        font-size: 12px;
        letter-spacing: 0;
        border-radius: 50%;
        background: #f17e5e;
        color: #ffffff;
      }
    }

    .postion-tips {
      padding: 12px 30px;
      background: #fdf0ee;
      border: 1px dashed #ff7575;
      color: #777473;
      position: relative;
      margin-bottom: 24px;

      &::after {
        position: absolute;
        content: "!";
        letter-spacing: 0;
        font-size: 12px;
        width: 13px;
        height: 13px;
        color: #ffffff;
        text-align: center;
        background-color: #f17e5e;
        border-radius: 50%;
        left: 12px;
        top: 15px;
        line-height: 13px;
      }
    }

    .postion-type-box {
      // padding: 24px;
      margin-bottom: 24px;

      .el-radio {
        margin-bottom: 0;
      }
    }

    .border-radio {
      .border-radio-list {
        // border: 1px solid $primary-color;
        border: 1px solid #d9d9d9;
        padding: 12px 24px;
        border-radius: 2px;
        margin-bottom: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #525252;
        cursor: pointer;

        i {
          display: none;
          font-size: 16px;
        }
      }

      .current-position {
        border: 1px solid $primary-color;
        color: $primary-color;

        i {
          color: $primary-color;
          display: block;
        }
      }

      .disabled-list {
        cursor: help;
        cursor: not-allowed;
        color: #afafaf;
      }

      // .el-radio-group {
      //   display: block;
      // }
      // .el-radio {
      //   width: 100%;
      //   margin: 0 0 24px 0;
      // }
    }

    .model-btn-box {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 24px;
      z-index: 10;

      .el-button {
        width: 184px;
      }

      background-color: #ffffff;

      .el-button + .el-button {
        margin-left: 24px;
      }
    }

    .poistion-box {
      padding: 24px 24px 100px;
      height: calc(100vh - 165px);
      overflow-y: scroll;

      .edit-size-list {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        // position: relative;
        // &::after{
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        //   width: 12px;

        // }
        .el-input-number {
          width: 140px;
        }

        .edit-size-title {
          width: 182px;
          flex-shrink: 1;

          > span {
            letter-spacing: 0;
            padding: 0 4px;
            font-size: 12px;
            border-radius: 50%;
            margin-right: 6px;
            line-height: 14px;
            color: #0a0c0b;
            border: 1px solid #0a0c0b;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      // border: 1px solid #DEE1E7;
      &::-webkit-scrollbar-thumb {
        background-color: #dee1e7;
      }
    }

    ::v-deep.el-drawer__body {
      position: relative;
    }

    ::v-deep.el-drawer__header {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #080a09;
    }

    // .el-drawer__wrapper {
    ::v-deep.el-drawer__container {
      top: auto;
      // width: 440px;
      left: auto;

      .el-drawer {
        top: auto;
        height: calc(100vh - 89px);
      }
    }

    // }
  }

  // padding: 40px;
  canvas {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // top: 40px;
  }

  .show-tips {
    display: none !important;
  }

  .show-btn-list {
    display: block !important;
  }

  .visb-hidden {
    background-color: rgba(255, 0, 0, 0.1);
    pointer-events: none;
    opacity: 1;

    .operating-btn-box {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
</style>
