<template>
  <div class="templateHeader hidden-div">
    <span class="a-link" @click="goback()">
      <i class="el-icon-back"></i>{{ examInformation.examName }}
      <template v-if="examInformation.subjectName">
        / {{ examInformation.subjectName }}
      </template>
    </span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TemplateHeader",
  props: {
    examInformation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.templateHeader {
  background-color: $primary-color;
  padding: 10px 24px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  .a-link {
    cursor: pointer;
  }
}
</style>
