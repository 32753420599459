<template>
  <div class="indexTypeBox hidden-div">
    <div class="type-list-box">
      <div
        v-for="(v, i) in markTypeList"
        :key="i"
        class="type-list"
        :class="{ 'index-succ': GET_LABELLED_TYPE == v.value }"
        @click="checkType(v, i)"
      >
        <i v-if="v.value != 1" class="el-icon-more"></i>
        <div class="index-number">{{ v.value }}</div>
        {{ v.label }}
      </div>
    </div>

    <div class="btn-box">
      <!-- <el-switch
        v-model="editListType"
        active-text="开启编辑"
        inactive-text="关闭编辑"
        @change="changeType"
      >
      </el-switch> -->
      <!-- <el-tooltip
        class="item"
        effect="dark"
        content="关闭后将隐藏线条操作"
        placement="bottom"
      >
        <el-button
          class="icon-btn"
          icon="el-icon-question"
          type="text"
        ></el-button>
      </el-tooltip> -->
      <!-- <el-button type="text" class="color">新手指引</el-button> -->
      <!-- <span class="color text-span">|</span> -->
      <el-button type="text" @click="exampaperSheetmarkClear(1)"
        >清空当前项</el-button
      >
      <span class="color text-span">|</span>
      <el-button type="text" @click="exampaperSheetmarkClear()"
        >清空全部痕迹</el-button
      >
      <template v-if="$route.query.password">
        <el-button
          type="text"
          :loading="btnLoading"
          @click="scanrecordCheckRecognition(1)"
        >
          重新识别客观题
        </el-button>
        <el-button
          type="text"
          :loading="btnLoading"
          @click="scanrecordCheckRecognition(2)"
        >
          重新识别主观题
        </el-button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { markTypeList } from "@/core/util/constdata";
import { scanrecordCheckRecognition } from "@/core/api/exam/exam";
// GET_LABELLED_TYPE
export default {
  name: "IndexTypeBox",
  data() {
    return {
      markTypeList: markTypeList,
      currentType: 1,
      editListType: true,
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "GET_LABELLED_TYPE",
      "GET_OLDSHEET_MARK_LIST",
      "GET_INDEX_SHEET_MARK",
      "GET_SHEET_MARK_LIST",
    ]),
  },
  created() {
    // console.log();
  },
  methods: {
    scanrecordCheckRecognition(type) {
      let data = {
        examPaperId: this.GET_INDEX_SHEET_MARK.examPaperId,
        type: type,
      };

      this.$confirm("确定重新开始裁切？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.btnLoading = true;
          scanrecordCheckRecognition(data)
            .then(() => {
              this.btnLoading = false;
              this.$message({
                message: "裁切成功！",
                type: "success",
              });
            })
            .catch(() => {
              this.btnLoading = false;
            });
        })
        .catch(() => {
          // this.setSwitch();
        });
    },

    changeType() {
      this.$store.commit("SET_EDIT_LIST_TYPE", this.editListType);
    },
    exampaperSheetmarkClear(val) {
      this.$emit("exampaperSheetmarkClear", val);
    },
    // markExamNo	考号信息 3
    // markLocation	定位信息 1
    // markObjective	客观题信息 4
    // markOther	其他信息 6
    // markSubjective	主观题信息  5
    // markTitle	标题信息 2

    checkType(v) {
      let str = "不能直接进行之后的步骤";
      if (this.GET_LABELLED_TYPE == v.value) {
        return;
      }
      let type = false;
      try {
        this.GET_OLDSHEET_MARK_LIST.map((index) => {
          if (v.value == 1) {
            // this.$store.commit("SET_LABELLED_TYPE", 1);
            // console.log(index);
          }
          if (v.value == 2) {
            // console.log(index.markLocation);
            let array = JSON.parse(index.markLocation || "[]");
            if (!index.markLocation) {
              type = true;
              if (array.length < 4) {
                str = "定位点至少四个！";
              }
            }
          }
          if (v.value == 3) {
            if (index.markTitle) {
              type = false;
              throw new Error();
            } else {
              type = true;
            }
          }
          if (v.value == 4) {
            if (!index.markExamNo) {
              type = true;
            }
            if (index.markExamNo) {
              type = false;
            }
          }
          if (v.value == 5) {
            if (!index.markObjective) {
              type = true;
            }
          }
          if (v.value == 6) {
            if (!index.markSubjective) {
              type = true;
            }
          }
          // 如果已经标完了考号 可以经行之后的步骤
          if (
            (v.value == 4 || v.value == 5 || v.value == 6) &&
            index.markExamNo
          ) {
            // console.log("进入了判断");
            type = false;
            throw new Error();
          }
        });
      } catch {
        //
      }
      if (type) {
        this.$message({
          message: str,
          type: "warning",
        });
        return;
      }
      // 判断试卷是否已保存
      // let key = this.markTypeList.filter(
      //   (i) => i.value == this.GET_LABELLED_TYPE
      // )[0].key;
      // let array = [];
      // this.GET_SHEET_MARK_LIST.forEach((item, index) => {
      //   let itemKey = this.GET_OLDSHEET_MARK_LIST[index][key];
      //   if (item[key] != itemKey) {
      //     array.push(index + 1);
      //   }
      //   if (!itemKey) {
      //     itemKey = "[]";
      //     if (item[key] == itemKey) {
      //       array.splice(array.length - 1, 1);
      //     }
      //   }
      // });
      // console.log(array);
      // console.log(this.GET_SHEET_MARK_LIST);
      // console.log(this.GET_OLDSHEET_MARK_LIST);
      // console.log(array);
      // if (array.length > 0) {
      //   this.$message({
      //     message: `第${array.join(
      //       ","
      //     )}面试卷未保存，请点击'下一页'或者'完成'保存`,
      //     type: "warning",
      //   });
      //   return;
      // }
      this.$store.commit("SET_LABELLED_TYPE", v.value);
      this.$emit("setSheetList");
    },
    // GET_OLDSHEET_MARK_LIST
  },
};
</script>
<style lang="scss" scoped>
.indexTypeBox {
  padding: 14px 24px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;

  .btn-box {
    .el-button {
      color: #ff4141;
    }

    .el-tooltip {
      margin-right: 24px;
    }

    .icon-btn.el-button {
      color: $primary-color;
    }

    .color {
      color: #b6b6b6;
    }

    .color.el-button {
      border-bottom: 1px solid #b6b6b6;
    }

    .text-span {
      margin: 0 24px;
    }
  }

  .type-list-box {
    display: flex;
    color: #525252;

    .type-list {
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .el-icon-more {
      margin: 0 24px;
    }

    .index-number {
      font-size: 12px;
      margin-right: 4px;
      width: 15px;
      height: 15px;
      text-align: center;
      border: 1px solid #525252;
      border-radius: 50%;
      line-height: 15px;
      letter-spacing: 0;
      display: inline-block;
    }

    .index-succ {
      color: $primary-color;

      .index-number {
        border-color: $primary-color;
        color: $primary-color;
      }
    }
  }
}
</style>
