<template>
  <div
    v-loading="btnLoading"
    element-loading-text="数据请求中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="thirdPartyAnswerSheet"
  >
    <templateHeader :exam-information="examInformation">
      <!-- <slot> -->
      <el-button
        type="text"
        class="down-btn"
        :loading="exportBtnLoading"
        @click="exampapersheetExport"
        >下载模板</el-button
      >
      <!-- </slot> -->
    </templateHeader>
    <indexTypeBox
      @setSheetList="setSheetList"
      @exampaperSheetmarkClear="exampaperSheetmarkClear"
    ></indexTypeBox>
    <!-- 定位操作区域 start -->
    <div class="location-operating-box">
      <!-- 框选定位信息区域 -->
      <div class="location-operating-area">
        <div class="location-operating-tips hidden-div">
          <template v-if="GET_LABELLED_TYPE == 1">
            <div v-if="locationType == 1">
              提示：请勿框选出界，确保框选区域略大于定位点黑色图块，每面均需框选。
            </div>
            <div v-if="locationType == 3">
              提示：请框选一段没有干扰的直线，每面均需框选。
            </div>
            <!-- {{ locationType }} -->
            <!-- <el-radio-group v-model="locationType">
              <el-radio :label="1" :disabled="getLocationType(1)"
                >点定位</el-radio
              >
              <el-radio :label="3" :disabled="getLocationType(3)"
                >线定位</el-radio
              >
            </el-radio-group> -->
          </template>
          <template v-if="GET_LABELLED_TYPE == 2">
            提示：请框选一行较长的完整文字
          </template>
          <template v-if="GET_LABELLED_TYPE == 3">
            提示：请框选完整的考号区域，仅正面需要框选。
          </template>
          <template v-if="GET_LABELLED_TYPE == 4">
            提示：请勿框选题号；不同小块（一般为5题），不同题型分开框选。
          </template>
          <template v-if="GET_LABELLED_TYPE == 5">
            提示：请确保框选区域略大于作答区域。
          </template>
          <template v-if="GET_LABELLED_TYPE == 6">
            <div></div>
            <el-radio-group v-model="specialLocationType">
              <el-radio
                v-for="(v, i) in specialLocation"
                :key="i"
                :label="v.value"
                >{{ v.label }}</el-radio
              >
              <!-- <el-radio :label="3">线定位</el-radio> -->
            </el-radio-group>
          </template>
        </div>
        <!-- 绘制答题卡的canvas -->
        <div class="location-operating-box">
          <markSheet
            ref="marksheet"
            v-loading="sheetLoading"
            :special-location-type="specialLocationType"
            :location-type="locationType"
            @submitIndexImg="submitIndexImg"
          ></markSheet>
          <div id="location-parameter" class="check-sheet hidden-div">
            <!-- <el-button>默认按钮</el-button> -->
            <el-button
              v-for="(v, i) in sheetMarkList"
              :key="i"
              style="margin-left: 0"
              class="btn-check-sheet"
              :disabled="btnLoading"
              :type="v.id == GET_INDEX_SHEET_MARK.id ? 'primary' : ''"
              @click="checkImg(v)"
            >
              第{{ (i + 1) | setName(numberList) }}张<template
                v-if="i % 2 == 0"
              >
                正面 </template
              ><template v-else> 反面 </template>
            </el-button>
            <!-- <div
              v-for="(v, i) in sheetMarkList"
              :key="i"
              class="check-list"
              :class="{ 'index-check': v.id == GET_INDEX_SHEET_MARK.id }"
              @click="checkImg(v)"
            >
              第{{ (i + 1) | setName(numberList) }}张<template
                v-if="i % 2 == 0"
              >
                正面
              </template>
              <template v-else> 反面 </template>
            </div> -->
            <div class="zoom-box-btn">
              <el-button type="text" icon="el-icon-zoom-in" @click="zoom(1)"
                >放大</el-button
              >
              <el-button type="text" icon="el-icon-zoom-out" @click="zoom(0)"
                >缩小</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧操作参数区域 -->
      <div
        v-if="GET_LABELLED_TYPE != 6"
        id="location-parameter1"
        class="location-parameter edit-scroll-style hidden-div"
      >
        <div class="location-parameter-title">模版结构信息</div>
        <div class="location-parameter-item">
          定位方式：{{ locationType | setName(locationTypeList) }}
        </div>
        <div class="location-parameter-item">
          总分：{{ objectiveScore + subjectiveScore }}分
          (修改试题分数丶题号后请点击保存按钮)
        </div>
        <div class="location-parameter-item">
          客观题列表：{{ objectiveScore }}分
          <el-button type="text" @click="sortList">自动排序</el-button>
          <el-tooltip
            class="item"
            effect="dark"
            content="自动排序只能支持纯数字,客观题排序"
            placement="top-start"
          >
            <el-button icon="el-icon-question" type="text"></el-button>
          </el-tooltip>
        </div>
        <span class="tips">点击下方输入框中的空隙可上下拖动试题顺序</span>
        <div class="qustion-list-box">
          <div class="qustion-order-list">
            <div>题号</div>
            <div>分数</div>
            <div>漏选得分</div>
            <div>类型</div>
          </div>
          <SlickList
            v-model="objQuestionList"
            :lock-to-container-edges="true"
            class="edit-scroll-style qustion-order-list-box"
            lock-axis="y"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(v, index) in objQuestionList"
              :key="index"
              :index="index"
              class="qustion-order-list"
            >
              <el-input
                v-model="v.examQuestionNumber"
                placeholder="请输入内容"
              ></el-input>
              <el-input v-model="v.score" placeholder="请输入内容"></el-input>
              <el-input
                v-model="v.scoreMiss"
                placeholder="请输入内容"
              ></el-input>
              <!-- {{ v.selectType }} -->
              <el-dropdown>
                <span class="el-dropdown-link">
                  <template v-if="v.selectType == 1">单选</template>
                  <template v-if="v.selectType == 2">多选</template>
                  <template v-if="v.selectType == 3">判断</template>
                  <i
                    v-if="v.selectType != 3"
                    class="el-icon-arrow-down el-icon--right"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="v.selectType == 2"
                    @click.native="v.selectType = 1"
                    >单选</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="v.selectType == 1"
                    @click.native="v.selectType = 2"
                    >多选</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </SlickItem>
          </SlickList>
          <div v-if="objQuestionList.length > 0" class="btn-box">
            <el-button
              type="primary"
              :loading="qLoading"
              :disabled="btnLoading"
              @click="checkQuesion()"
            >
              保存
              <i class="el-icon-edit el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <div class="location-parameter-item">
          主观题列表：{{ subjectiveScore }}分
        </div>
        <div class="qustion-list-box">
          <div class="qustion-list">
            <div>题号</div>
            <div>分数</div>
            <!-- <div>漏选得分</div> -->
          </div>
          <SlickList
            v-model="subQuestionList"
            :lock-to-container-edges="true"
            class="edit-scroll-style qustion-order-list-box"
            lock-axis="y"
            :distance="0.5"
            @input="getChangeLists"
          >
            <SlickItem
              v-for="(v, index) in subQuestionList"
              :key="index"
              :index="index"
              class="sub-qustion-order-list"
              :class="{ 'hidden-list': !showScore(v) }"
            >
              <div class="question-box-list">
                <el-input
                  v-model="v.examQuestionNumber"
                  placeholder="请输入题号"
                ></el-input>
                <el-input v-model="v.score" placeholder="请输入分数"></el-input>
              </div>
              <template v-if="v.isOption">
                <template v-for="(item, indexk) in subQuestionList">
                  <div
                    v-if="
                      !showScore(item) &&
                      item.examQuestionGroup == v.examQuestionGroup
                    "
                    :key="indexk"
                    class="question-box-list"
                  >
                    <el-input
                      v-model="item.examQuestionNumber"
                      placeholder="请输入题号"
                    ></el-input>
                    <!-- {{ item.examQuestionNumber }} -->
                  </div>
                </template>
              </template>
            </SlickItem>
          </SlickList>
          <div
            v-if="subQuestionList.length > 0"
            class="btn-box"
            style="margin-bottom: 24px"
          >
            <el-button
              type="primary"
              :loading="qLoading"
              :disabled="btnLoading"
              @click="checkQuesion()"
            >
              保存
              <i class="el-icon-edit el-icon--right"></i>
            </el-button>
          </div>
        </div>
        <!-- <el-table :data="questionList" border style="width: 100%">
          <el-table-column prop="date" label="题型" align="center">
          </el-table-column>
          <el-table-column prop="name" label="题号" align="center">
          </el-table-column>
          <el-table-column prop="address" label="排列方式" align="center">
          </el-table-column>
          <el-table-column prop="address" label="选项个数" align="center">
          </el-table-column>
        </el-table>
        <div class="location-parameter-item">试题列表：</div> -->
        <div class="location-parameter-btn">
          <el-button
            type="primary"
            :loading="btnLoading"
            :disabled="qLoading"
            @click="checkSheet()"
          >
            <template
              v-if="
                GET_SHEET_MARK_LIST.length > 0 &&
                GET_SHEET_MARK_LIST[GET_SHEET_MARK_LIST.length - 1].id ==
                  GET_INDEX_SHEET_MARK.id
              "
              >完 成</template
            >
            <template v-else>下 一 页</template>
          </el-button>
        </div>
      </div>

      <!-- 特殊标记的盒子 -->
      <div
        v-else
        id="location-parameter2"
        class="location-parameter edit-scroll-style hidden-div"
      >
        <template v-for="(v, i) in specialLocation">
          <div
            v-if="v.value == specialLocationType"
            :key="i"
            class="location-parameter-title"
          >
            框选{{ v.label }}
          </div>
        </template>
        <template v-if="specialLocationType == 1">
          <p>缺考标记用于识别缺考学生，请框选试卷中缺考区域</p>
          <div class="tips-img">
            <img src="@/static/sheet/isSpecial1.png" alt="" />
          </div>
          <p class="err-tips">注意：不框选缺考标记，无法识别缺考学生。</p>
          <div>若无缺考标记，请勾选“无合适缺考标记”</div>
          <div class="special-check">
            <el-checkbox
              v-model="noSpecial"
              label="无合适缺考标记"
              :disabled="showDis(1)"
              border
              @change="checkSpecial(1)"
            ></el-checkbox>
          </div>
        </template>
        <template v-if="specialLocationType == 2">
          <p>学科标记用于识别扫错学科，请框线试卷中学科</p>
          <div class="tips-img">
            <img src="@/static/sheet/isSpecial2.png" alt="" />
          </div>
          <p class="err-tips">注意：不框选学科标记，将无法识别学科异常</p>
          <div>若无学科标记，请勾选“无合适学科标记”</div>
          <div class="special-check">
            <el-checkbox
              v-model="noSpecial"
              label="无合适学科标记"
              :disabled="showDis(2)"
              border
              @change="checkSpecial(2)"
            ></el-checkbox>
          </div>
        </template>
        <template v-if="specialLocationType == 3">
          <p>AB卷标记用于识别AB卷，请框选试卷中AB卷标记区域</p>
          <div class="tips-img">
            <img src="@/static/sheet/isSpecial3.png" alt="" />
          </div>
        </template>
        <div class="location-parameter-btn">
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="getQuestionSort()"
          >
            保存并应用模板
          </el-button>
        </div>
      </div>
      <!-- 检查模版-->
      <el-drawer
        title="检查模版"
        :modal="false"
        :show-close="false"
        :visible.sync="drawer"
        direction="rtl"
        :before-close="handleClose"
        size="380px"
      >
        <div class="poistion-box edit-scroll-style">
          <p>请检查识别区域的框选效果</p>
          <p>
            1、请仔细检查设置的选项数量、考号位数、题型、排列方式等是否正确；<br />
            2、请检查各填涂区的边缘与红线是否重合；
          </p>
          <template v-for="(v, i) in examineSheetMarkList">
            <div v-if="v.type == 3 && v.id == indexId" :key="i">
              <p>{{ v.type | setName(markTypeList) }}</p>
              <el-table
                v-if="v.subType == 1"
                :data="[v]"
                border
                style="width: 100%"
              >
                <el-table-column prop="date" label="排列方式" align="center">
                  <template slot-scope="scope">
                    {{
                      scope.row.optionsArrangementMode
                        | setName(optionsArrangeTypeList)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="optionsLength"
                  label="考号长度"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="address" label="考号样式" align="center">
                  <template slot-scope="scope">
                    {{
                      scope.row.optionsStyle | setName(studentExamIdStyleList)
                    }}
                  </template>
                </el-table-column>
              </el-table>

              <div class="img-box">
                <img
                  :src="v.source_url + `?time=${new Date().getTime()}`"
                  alt=""
                />
              </div>
            </div>
            <div v-if="v.type == 4 && v.id == indexId" :key="i">
              <p>{{ v.type | setName(markTypeList) }}</p>
              <el-table :data="[v]" border style="width: 100%">
                <el-table-column prop="date" label="排列方式" align="center">
                  <template slot-scope="scope">
                    {{
                      scope.row.optionsArrangementMode
                        | setName(optionsArrangeTypeList)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="optionsLength"
                  label="选项个数"
                  align="center"
                >
                </el-table-column>

                <el-table-column prop="address" label="题号" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.questionNumList | getNumList(1) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="optionsLength"
                  label="分数"
                  align="center"
                >
                  <template slot-scope="scope">
                    {{ scope.row.questionNumList | getNumList(2) }}
                  </template>
                </el-table-column>
              </el-table>
              <div class="img-box">
                <img
                  :src="v.source_url + `?time=${new Date().getTime()}`"
                  alt=""
                />
              </div>
            </div>
            <div v-if="v.type == 5 && v.id == indexId" :key="i">
              <p style="color: red">
                {{ v.questionNumList | getNumList(1) }}题
                {{ v.questionNumList[0].score }}分
              </p>
              <div class="img-box">
                <img
                  :src="v.source_url + `?time=${new Date().getTime()}`"
                  alt=""
                />
              </div>
            </div>
            <div v-if="v.type == 6 && v.id == indexId" :key="i">
              <p>{{ v.type | setName(markTypeList) }}</p>
              <span style="color: #2474ed">{{
                v.subType | setName(specialLocation)
              }}</span>
              <div class="img-box">
                <img
                  :src="v.source_url + `?time=${new Date().getTime()}`"
                  alt=""
                />
              </div>
            </div>
          </template>
        </div>
        <div class="btn-list-box">
          <p>1、若框选有问题，请直接在左侧调整该区域</p>
          <p>
            2、若确认无误，请点击
            <el-button type="text" @click="goNext()">查看下一个</el-button>
          </p>
          <p>
            3、若要继续调整、修改模版，请点击
            <el-button type="text" @click="close">取消</el-button>
          </p>
        </div>
      </el-drawer>
    </div>

    <!-- 定位操作区域 end -->

    <!-- 修订记录 -->
    <el-dialog
      title="三方制卡页面功能修改"
      :visible.sync="versionUpdating"
      class="version-updating"
      width="548px"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="massage edit-scroll-style">
        <div style="line-height: 21px">
          1.取消 “滚动鼠标滚轮键” 放大缩小图片功能；
        </div>
        <div style="line-height: 21px">
          2.增加“ctrl键+滚动鼠标滚轮键”放大缩小图片功能；
        </div>
        <div style="line-height: 21px">
          3.增加“shift键+滚动鼠标滚轮键”左右滑动图片功能；
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="versionUpdating = false">
          我知道了
        </el-button>
      </span>
    </el-dialog>
    <!-- 客观题顺序不一致对比 -->
    <el-dialog
      title="校验客观题顺序"
      :visible.sync="objectiveType"
      width="548px"
      :show-close="false"
    >
      <div>当前客观题排序：</div>
      <div style="line-height: 21px" v-html="oldObjArr"></div>
      <div>检测排序：</div>
      <div style="line-height: 21px">
        {{ newObjArr }}
      </div>
      <div>如果当前客观题排序正确，请点击 “忽略”</div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="objectiveType = false">取 消</el-button>
        <el-button type="primary" @click="getScore()"> 忽 略 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const specialLocation = [
  {
    value: 1,
    label: "缺考标记",
  },
  {
    value: 2,
    label: "学科标记",
  },
  {
    value: 3,
    label: "AB卷标记",
  },
];
//
const locationTypeList = [
  {
    value: 1,
    label: "点定位",
  },
  {
    value: 3,
    label: "线定位",
  },
];
const numberList = [
  {
    value: 1,
    label: "一",
  },
  {
    value: 2,
    label: "一",
  },
  {
    value: 3,
    label: "二",
  },
  {
    value: 4,
    label: "二",
  },
  {
    value: 5,
    label: "三",
  },
  {
    value: 6,
    label: "三",
  },
  {
    value: 7,
    label: "四",
  },
  {
    value: 8,
    label: "四",
  },
  {
    value: 9,
    label: "五",
  },
  {
    value: 10,
    label: "五",
  },
];
const studentExamIdStyleList = [
  {
    label: "0123456789",
    value: 1,
  },
  {
    label: "1234567890",
    value: 2,
  },
];
import {
  exampapersheetmarkFindApply,
  getExamPaperSheet,
  exampapersheetmarkList,
  saveExampapersheetmark,
  exampaperSheetmarkClear,
  exampapersheetdetailV2page,
  exampapersheetdetailUpdateV2list,
  exampapersheetExport,
} from "@/core/api/exam/sheet";
import { mapGetters } from "vuex";
import { getSubjectList } from "@/core/util/util";
import { radixSort } from "./js/sort_question";
import { getStore, setStore } from "@/core/util/store";
// import { compareArray } from "@/core/util/SortByMix";
import templateHeader from "./component/templateHeader.vue";
import markSheet from "./component/markSheet.vue";
import indexTypeBox from "./component/indexTypeBox.vue";
import {
  markTypeList,
  optionsTypeList,
  optionsArrangeTypeList,
  choiceQuestionTypeList,
} from "@/core/util/constdata";
import { fileDownloadByUrl } from "@/core/util/util";
import { SlickList, SlickItem } from "vue-slicksort";
// import { json } from "body-parser";
export default {
  name: "ThirdPartyAnswerSheet",
  filters: {
    getNumList(list, type) {
      let array = [];
      if (type == 1) {
        array = list.map((item) => item.examQuestionNumber);
      }
      if (type == 2) {
        array = list.map((item) => item.score);
      }

      return array.join(",");
    },
  },
  components: {
    templateHeader,
    markSheet,
    indexTypeBox,
    SlickItem,
    SlickList,
  },
  data() {
    return {
      objectiveType: false,
      oldObjArr: "",
      newObjArr: "",
      versionUpdating: false,
      // 是否可以编辑
      editListType: false,
      // 检查标记弹框
      drawer: false,
      // 检查的数据地址
      examineSheetMarkList: [],
      indexId: 0,
      optionsTypeList: optionsTypeList,
      optionsArrangeTypeList: optionsArrangeTypeList,
      studentExamIdStyleList: studentExamIdStyleList,
      choiceQuestionTypeList: choiceQuestionTypeList,
      locationTypeList: locationTypeList,
      specialLocation: specialLocation,

      markTypeList: markTypeList,
      examInformation: {},
      subjectOptions: [],
      locationType: 1,
      sheetMarkList: [],
      numberList: numberList,
      // 试题列表
      questionList: [],
      subQuestionList: [],
      objQuestionList: [],
      // // subQuestionList objQuestionList
      // 当前图片
      indexUrl: "",
      specialLocationType: 1,
      btnLoading: false,
      exportBtnLoading: false,
      qLoading: false,
      noSpecial: false,
      sheetLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "GET_INDEX_SHEET_MARK",
      "GET_SHEET_LINE_LIST",
      "GET_LABELLED_TYPE",
      "GET_SHEET_MARK_LIST",
      "GET_OLDSHEET_MARK_LIST",
      // "GET_LABELLED_TYPE",
    ]),
    objectiveScore() {
      let score = 0;
      this.questionList.forEach((index) => {
        if (index.type != 1) {
          score = Number(score) + Number(index.score);
        }
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }

      return Number(scoreStr);
    },
    subjectiveScore() {
      let score = 0;
      // let quesitonGroupArrStr = "";
      this.questionList.forEach((index) => {
        if (index.type == 1) {
          let tempScore = Number(index.score);
          if (index.isOption == 1) {
            tempScore = 0;
            let groupList = this.questionList.filter(
              (i) => index.examQuestionGroup == i.examQuestionGroup
            );
            groupList.map((item, i) => {
              if (
                index.examQuestionSheetValue == item.examQuestionSheetValue &&
                i == 0
              ) {
                tempScore = Number(index.score);
              }
            });
          }
          score = Number(score) + tempScore;
        }
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }

      return Number(scoreStr);
    },
  },
  watch: {
    GET_SHEET_LINE_LIST: {
      deep: true,
      handler() {
        this.noSpecial = false;
        // console.log(this.GET_SHEET_LINE_LIST);
        let array = this.GET_SHEET_LINE_LIST.filter(
          (item) =>
            item.type == 6 &&
            item.subType == this.specialLocationType &&
            !item.source
        );
        if (array.length > 0) {
          this.noSpecial = true;
        }
      },
    },
    // 监听下分数的变化
    // questionList: {
    //   deep: true,
    //   handler() {
    //     if (
    //       this.questionList.length == 0 ||
    //       this.GET_SHEET_LINE_LIST.length == 0
    //     ) {
    //       return;
    //     }

    //   },
    // },
    specialLocationType() {
      // console.log(this.specialLocationType);
      this.noSpecial = false;
      let array = this.GET_SHEET_LINE_LIST.filter(
        (item) =>
          item.type == 6 &&
          item.subType == this.specialLocationType &&
          !item.source
      );
      if (array.length > 0) {
        this.noSpecial = true;
      }
    },
  },
  created() {
    this.$store.commit("SET_LAST_QUESTION", 0);
    this.$store.commit("CLEAR_MARK_DATA");
    this.subjectOptions = getSubjectList();
    this.$store.commit("SET_LABELLED_TYPE", 1);
    // 获取基本信息
    this.getExamPaperSheet();
    // 获取答题卡信息
    this.exampapersheetmarkList();
    this.getShowVS();
  },
  methods: {
    getShowVS() {
      let version = "1";
      let temVs = getStore({ name: "template_version" });
      // console.log(temVs);
      if (!temVs || version != temVs) {
        this.versionUpdating = true;
      }
      setStore({ name: "template_version", content: version });
    },
    getChangeLists() {
      // 主观题
      let arr = JSON.parse(JSON.stringify(this.subQuestionList));
      // 过滤出选做题
      arr = arr.filter((item) => {
        let returnItem = "";
        if (item.isOption == 0) {
          returnItem = item;
        } else {
          let groupList = this.questionList.filter(
            (i) => item.examQuestionGroup == i.examQuestionGroup
          );
          // console.log(groupList.map((item) => item.examQuestionNumber));
          if (
            item.examQuestionSheetValue == groupList[0].examQuestionSheetValue
          ) {
            returnItem = groupList[0];
          }
        }
        return returnItem;
      });
      let oldArr = [...arr];

      // 将删除的选做题添加回去
      let addKey = 0;
      // 用来判断之前有几组选做题提添加了几次
      oldArr.forEach((item, index) => {
        let groupList = this.questionList.filter(
          (i) => item.examQuestionGroup == i.examQuestionGroup
        );
        if (item.examQuestionGroup == groupList[0].examQuestionGroup) {
          if (item.isOption) {
            addKey++;
            // console.log(item.examQuestionGroup);
          }
          groupList.map((itemG, indexG) => {
            if (
              itemG.examQuestionSheetValue != item.examQuestionSheetValue &&
              indexG
            ) {
              arr.splice(index + indexG + addKey - 1, 0, itemG);
            }
          });
        }
      });
      // 重新排序
      arr.map((item, index) => {
        item.examQuestionSheetOrder = index + 1 + this.objQuestionList.length;
      });
      this.subQuestionList = arr;
      // 拖拽完成后返回被打乱后的顺序 objQuestionList subQuestionList
      this.objQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1;
      });
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
      if (this.getQuestionBlank()) return;
      this.exampapersheetdetailUpdateV2list();
    },
    // 排序试题
    sortList() {
      // console.log("111111");
      // let arr = JSON.parse(JSON.stringify(this.questionList));
      this.objQuestionList = this.objQuestionList.sort(function (a, b) {
        return Number(a.examQuestionNumber) - Number(b.examQuestionNumber);
      });
      this.objQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1;
      });
      this.subQuestionList.map((item, index) => {
        item.examQuestionSheetOrder = index + 1 + this.objQuestionList.length;
      });
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
      this.exampapersheetdetailUpdateV2list();
      // this.$message({
      //   showClose: true,
      //   type: "success",
      //   message: "排序成功,如果需要应用客观题顺序,请点击下方保存!",
      // });
      // console.log(this.questionList);
    },
    showScore(item) {
      // console.log(item.examQuestionSheetValue);
      let returnType = true;
      // let arrNum = 0;
      if (item.isOption) {
        let groupList = this.questionList.filter(
          (i) => item.examQuestionGroup == i.examQuestionGroup
        );
        groupList.map((index, i) => {
          if (
            index.examQuestionSheetValue == item.examQuestionSheetValue &&
            i
          ) {
            returnType = false;
          }
        });
      }
      return returnType;
    },
    setJson() {
      // console.log(this.questionList);
      // console.log(this.GET_SHEET_MARK_LIST);
      // 循环总数据判断下 如果说右侧分数修改了  就同步至json

      this.GET_SHEET_MARK_LIST.forEach((item) => {
        let markObjective = item.markObjective || "[]";
        let markSubjective = item.markSubjective || "[]";
        let markObjectiveType = false;
        let markSubjectiveType = false;
        markObjective = JSON.parse(markObjective);
        markSubjective = JSON.parse(markSubjective);
        this.questionList.forEach((index) => {
          // 客观题
          markObjective.forEach((itemList) => {
            if (!itemList.questionNumList) {
              return;
            }

            itemList.questionNumList.map((i) => {
              if (i.value == index.examQuestionSheetValue) {
                if (
                  i.score != index.score ||
                  i.examQuestionNumber != index.examQuestionNumber ||
                  i.scoreMiss != index.scoreMiss
                ) {
                  markObjectiveType = true;
                }
                i.score = index.score;
                i.examQuestionNumber = index.examQuestionNumber;
                if (index.scoreMiss) {
                  i.scoreMiss = index.scoreMiss;
                }
              }
            });
          });
          // 主观题
          markSubjective.forEach((itemList) => {
            if (!itemList.questionNumList) {
              return;
            }

            itemList.questionNumList.map((i) => {
              if (i.value == index.examQuestionSheetValue) {
                if (
                  i.score != index.score ||
                  i.examQuestionNumber != index.examQuestionNumber ||
                  (i.scoreMiss && i.scoreMiss != index.scoreMiss)
                ) {
                  markSubjectiveType = true;
                }
                i.score = index.score;
                i.examQuestionNumber = index.examQuestionNumber;
                if (i.scoreMiss) {
                  i.scoreMiss = index.scoreMiss;
                }
              }
            });
          });
        });
        if (markObjectiveType) {
          let data = {
            examPaperId: item.examPaperId,
            id: item.id,
            markObjective: JSON.stringify(markObjective),
            sheetUuid: item.sheetUuid,
            onlySave: 1,
          };
          this.setSheetMark(data);
        }
        if (markSubjectiveType) {
          let data = {
            examPaperId: item.examPaperId,
            id: item.id,
            markSubjective: JSON.stringify(markSubjective),
            sheetUuid: item.sheetUuid,
            onlySave: 1,
          };
          this.setSheetMark(data);
        }
      });
    },
    // 修改分数后提交  限于 提交非当前页JSON文件
    setSheetMark(data) {
      saveExampapersheetmark(data)
        .then(() => {
          this.$store.commit("SET_MARK", data);
          //修改当前面的数据
          if (data.id == this.GET_INDEX_SHEET_MARK.id) {
            Object.assign(this.GET_INDEX_SHEET_MARK, data);
            this.$store.commit(
              "SET_INDEX_SHEET_MARK",
              this.GET_INDEX_SHEET_MARK
            );
            // 修改当前项的数据
            if (this.GET_LABELLED_TYPE == 4 && data.markObjective) {
              let array = JSON.parse(data.markObjective);
              this.$store.commit("SET_SHEET_LINE_LIST", array);
            }
            if (this.GET_LABELLED_TYPE == 5 && data.markSubjective) {
              let array = JSON.parse(data.markSubjective);
              this.$store.commit("SET_SHEET_LINE_LIST", array);
            }
          }
          // console.log(this.GET_SHEET_LINE_LIST);

          this.$refs.marksheet.setSheetLineList();
        })
        .catch(() => {
          this.$store.commit("SET_LIST_GO_OLD");
          this.$refs.marksheet.setSheetLineList();
          this.btnLoading = false;
        });
    },
    checkSpecial(val) {
      let data = {
        type: 6,
        subType: val,
        id: new Date().getTime(),
        caFalse: true,
      };
      let list = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      if (this.noSpecial) {
        list.push(data);
      } else {
        list = list.filter((item) => item.subType != val);
      }
      this.$store.commit("SET_SHEET_LINE_LIST", list);
      this.submitIndexImg(1);
      // console.log(this.GET_SHEET_LINE_LIST);
      // console.log(data);
    },
    goNext() {
      let array = this.examineSheetMarkList.map((item) => item.id);
      if (array.indexOf(this.indexId) < array.length - 1) {
        this.indexId = array[array.indexOf(this.indexId) + 1];
      } else {
        this.exampapersheetmarkFindApply();
      }
      // console.log(array.indexOf(this.indexId));
    },
    handleClose() {
      this.close();
    },
    close() {
      this.drawer = false;
    },
    // 获取题号顺序是否相同
    getQuestionSort() {
      let arr = [];
      this.questionList.map((index) => {
        if (index.selectType == 1) arr.push(index.examQuestionNumber);
      });
      let radixSortArr = radixSort([...arr]);
      let htmlArr = [];
      radixSortArr.map((item, index) => {
        if (arr[index] == item) {
          htmlArr.push(`<span>${arr[index]}</span>`);
        } else {
          htmlArr.push(`<span style="color:red">${arr[index]}</span>`);
        }
      });
      this.oldObjArr = `<div>${htmlArr.join(",")}</div>`;
      this.newObjArr = radixSort([...arr]).join(",");
      if (arr.join(",") == this.newObjArr) {
        this.getScore();
      } else {
        this.objectiveType = true;
      }
    },
    getScore() {
      this.objectiveType = false;
      let markExamNo = [];
      this.GET_OLDSHEET_MARK_LIST.map((item) => {
        if (item.markExamNo) {
          markExamNo = markExamNo.concat(JSON.parse(item.markExamNo));
        }
      });
      if (markExamNo.length == 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "未框选考号",
          message: h("p", null, [
            h("span", null, "当前试卷未框选 "),
            h("span", { style: "color: red" }, "考号"),
            h("span", null, "，请框选后提交！"),
          ]),
          showCancelButton: false,
          confirmButtonText: "确定",
        })
          .then(() => {
            // this.examine();
          })
          .catch(() => {});
        return;
      }

      let score = 0;
      // let templateId = 1;
      //获取修改分数的列表
      this.questionList.map((index) => {
        let tempScore = Number(index.score);
        if (index.isOption == 1) {
          tempScore = 0;
          let groupList = this.questionList.filter(
            (i) => index.examQuestionGroup == i.examQuestionGroup
          );
          groupList.map((item, i) => {
            if (
              index.examQuestionSheetValue == item.examQuestionSheetValue &&
              i == 0
            ) {
              tempScore = Number(index.score);
            }
          });
        }
        score = Number(score) + tempScore;
      });
      // console.log(this.questionList);
      // console.log(score);
      if (
        score != 150 &&
        score != 90 &&
        score != 100 &&
        score != 110 &&
        score != 300
      ) {
        const h = this.$createElement;
        this.$msgbox({
          title: "分数异常",
          message: h("p", null, [
            h("span", null, "当前试卷总分为 "),
            h("span", { style: "color: red" }, score + "分"),
            h("span", null, " 分数为非常规分数，是否继续保存？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.examine();
          })
          .catch(() => {});
      } else {
        this.examine();
      }
    },
    examine() {
      let data = {
        sheetUuid: this.$route.query.sheetId,
      };
      //
      // if (!this.$route.query.type) {
      //   this.exampapersheetmarkFindApply();
      //   return;
      // }

      this.btnLoading = true;
      exampapersheetmarkList(data)
        .then((res) => {
          this.btnLoading = false;
          let markExamNo = [];
          let markObjective = [];
          let markOther = [];
          let markSubjective = [];
          res.data.data.forEach((item) => {
            if (item.markExamNo) {
              markExamNo = markExamNo.concat(JSON.parse(item.markExamNo));
            }
            if (item.markOther) {
              markOther = markOther.concat(JSON.parse(item.markOther));
            }
            if (item.markObjective) {
              markObjective = markObjective.concat(
                JSON.parse(item.markObjective)
              );
            }
            if (item.markSubjective) {
              markSubjective = markSubjective.concat(
                JSON.parse(item.markSubjective)
              );
            }
          });

          this.examineSheetMarkList = markExamNo
            .concat(markObjective)
            .concat(markSubjective)
            .concat(markOther);
          // console.log(this.examineSheetMarkList);
          // 当前项到哪一步了  考号----客观题
          if (!this.indexId) {
            this.indexId = this.examineSheetMarkList[0].id;
          }
          this.drawer = true;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },

    // 导出答题卡
    exampapersheetExport() {
      let data = {
        sheetUuids: this.$route.query.sheetId,
      };
      // this.$message({
      //   message: "下载中，请稍后",
      //   type: "success",
      // });
      this.exportBtnLoading = true;
      exampapersheetExport(data)
        .then((res) => {
          this.exportBtnLoading = false;
          let name =
            this.examInformation.examName +
            this.examInformation.subjectName +
            "答题卡";
          fileDownloadByUrl(res.data.data.url, name);
          // console.log();
        })
        .catch(() => {
          this.exportBtnLoading = false;
        });
    },
    exampapersheetdetailUpdateV2list() {
      let scoreList = [];
      let questionList = [];
      let Array = [];
      this.questionList.map((item) => {
        if (
          Number(item.score) < Number(item.scoreMiss) &&
          item.selectType > 0
        ) {
          scoreList.push(item.examQuestionNumber);
        }
        let examQuestionNumber = item.examQuestionNumber.toString();
        if (
          examQuestionNumber.indexOf(",") != -1 ||
          examQuestionNumber.indexOf(";") != -1 ||
          examQuestionNumber.indexOf("，") != -1 ||
          examQuestionNumber.indexOf("；") != -1
        ) {
          Array.push(item.examQuestionNumber);
        }
        this.questionList.forEach((index) => {
          if (
            item.examQuestionNumber == index.examQuestionNumber &&
            item.examQuestionSheetValue != index.examQuestionSheetValue
          ) {
            questionList.push(index.examQuestionNumber.toString());
          }
        });
      });
      if (scoreList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${scoreList.join(",")}题，漏选大于得分!`,
        });
        return;
      }
      questionList = [...new Set(questionList)];
      // console.log(scoreList);
      if (questionList.length > 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: `第${questionList.join(",")}题，题号重复!`,
        });
        return;
      }
      if (Array.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "题号异常提示",
          message: h("p", null, [
            h("span", { style: "color: red" }, Array.join(" ") + "题"),
            h("span", null, "题号存在"),
            h("span", { style: "color: red" }, "；，, ;"),
            h("span", null, "等符号，请修改后重试 "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return;
      }
      this.qLoading = true;
      exampapersheetdetailUpdateV2list(
        this.$route.query.sheetId,
        this.questionList
      )
        .then(() => {
          this.qLoading = false;
          this.setJson();
        })
        .catch(() => {
          this.qLoading = false;
        });
    },
    getQuestionBlank() {
      let arr = [];
      this.questionList.map((item) => {
        let str = item.examQuestionNumber.toString();
        if (str.indexOf("  ") != -1) {
          arr.push(str);
        } else {
          let newStr = str.trim();
          if (newStr != str) {
            arr.push(str);
          }
        }
      });
      // console.log(arr);
      if (arr.length > 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "数据异常",
          message: h("p", null, [
            h("span", null, "试题"),
            h("span", { style: "color: red" }, arr.join(",")),
            h("span", null, "题号包含首尾空格或连续空格，请修改后提交！ "),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {})
          .catch(() => {});
        return true;
      } else {
        return false;
      }
      // console.log();
    },
    checkQuesion() {
      // 如果是选做题  将其他的试题分数也修改为 第一题的分数
      // let oldArr=JSON.parse(JSON.stringify(this.questionList))
      // debugger;
      this.questionList = new Array()
        .concat(this.objQuestionList)
        .concat(this.subQuestionList);
      if (this.getQuestionBlank()) return;
      this.questionList.map((index) => {
        if (index.isOption == 1) {
          let groupList = this.questionList.filter(
            (i) => index.examQuestionGroup == i.examQuestionGroup
          );
          groupList.map((item, i) => {
            if (
              index.examQuestionSheetValue == item.examQuestionSheetValue &&
              i > 0
            ) {
              index.score = Number(groupList[0].score);
            }
          });
        }
        // console.log(index.selectType);
        if (!index.answerArea || index.selectType == 0) {
          return;
        }
        let answerArea = JSON.parse(index.answerArea);
        let questionNumList = answerArea.questionNumList;
        // console.log(questionNumList);
        if (!questionNumList) {
          return;
        }
        questionNumList.map((item) => {
          this.questionList.map((i) => {
            if (item.value == i.examQuestionSheetValue) {
              item.examQuestionNumber = i.examQuestionNumber;
            }
          });
        });

        answerArea.questionNumList = questionNumList;
        index.answerArea = JSON.stringify(answerArea);
      });
      // }
      this.exampapersheetdetailUpdateV2list();
    },
    exampapersheetdetailV2page() {
      let data = {
        sheetUuid: this.$route.query.sheetId,
        // subjectId: this.examInformation.subjectId,
      };
      exampapersheetdetailV2page(data).then((res) => {
        this.questionList = res.data.data;
        // subQuestionList objQuestionList
        this.subQuestionList = res.data.data.filter((item) => item.type == 1);
        this.objQuestionList = res.data.data.filter((item) => item.type != 1);
      });
    },
    // 保存模板
    exampapersheetmarkFindApply() {
      let data = {
        examPaperId: this.examInformation.examPaperId,
        sheetUuid: this.$route.query.sheetId,
      };
      this.btnLoading = true;
      exampapersheetmarkFindApply(data)
        .then(() => {
          this.close();
          if (this.$route.query.examId == -1) {
            this.$message({
              message: "保存成功!",
              type: "success",
            });
            this.btnLoading = false;
          } else {
            this.$message({
              message: "保存成功，即将返回模板页",
              type: "success",
            });
            setTimeout(() => {
              this.btnLoading = false;
              let name = "ExamTemplate";
              if (this.$route.query.from) {
                name = this.$route.query.from;
              }
              this.$router.push({
                name: name,
                query: {
                  subjectId: this.examInformation.subjectId,
                  examId: this.$route.query.examId,
                },
              });
            }, 2000);
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 放大 缩小
    zoom(type) {
      // console.log("出发了");
      this.$refs.marksheet.setZoom(type);
    },
    exampaperSheetmarkClear(val) {
      let data = {
        sheetUuid: this.$route.query.sheetId,
      };
      if (val) {
        data.type = this.GET_LABELLED_TYPE;
      }
      let str = "清空后将无法恢复, 是否继续?";
      if (val) {
        str = "此操作会清空当前项，无法恢复，是否继续操作";
      }
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          exampaperSheetmarkClear(data).then(() => {
            if (!val) {
              this.$store.commit("SET_LABELLED_TYPE", 1);
            }
            this.exampapersheetmarkList();
            this.exampapersheetdetailV2page();
            this.$message({
              type: "success",
              message: "清空成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消清空",
          });
        });
    },
    // 获取定位类型 当选择点定位时 禁用线定位
    getLocationType(type) {
      // console.log(this.GET_SHEET_MARK_LIST);

      let disabled = false;
      let markLocationArr = [];
      this.GET_SHEET_MARK_LIST.forEach((item) => {
        // console.log(item.markLocation);
        if (!item.markLocation) {
          item.markLocation = "[]";
        }
        markLocationArr = markLocationArr.concat(JSON.parse(item.markLocation));
      });
      // console.log(markLocationArr);
      markLocationArr.forEach((item) => {
        if (item.subType != type) {
          disabled = true;
        }
      });
      // console.log(markLocationArr);
      // console.log(type);
      // console.log(disabled);
      return disabled;
    },
    showDis(val) {
      // console.log(this.GET_SHEET_LINE_LIST);
      let disabled = false;
      this.GET_SHEET_LINE_LIST.forEach((item) => {
        if (item.subType == val && item.source) {
          disabled = true;
        }
      });
      return disabled;
      // if(subType)
    },

    // 保存当前项图片的数据
    async submitIndexImg(type, id) {
      let data = {
        sheetUuid: this.$route.query.sheetId,
        id: this.GET_INDEX_SHEET_MARK.id,
        examPaperId: this.GET_INDEX_SHEET_MARK.examPaperId,
        cutId: id,
      };
      // 提交之前  做一次json完整性的判断
      this.btnLoading = true;
      let indexType = false;
      this.errString = "";
      if (this.GET_LABELLED_TYPE == 6) {
        this.GET_SHEET_LINE_LIST.map((index) => {
          // console.log(index);
          if (!index.calculate && index.subType == 1 && !index.caFalse) {
            indexType = true;
          }
          if (!index.calculate && index.subType == 3 && !index.caFalse) {
            indexType = true;
          }
          if (index.calculate && index.subType == 1 && !index.caFalse) {
            if (
              index.calculate.sizeWidth == 0 ||
              index.calculate.sizeHeight == 0
            ) {
              this.errString = "缺考识别区域宽高为零，";
            }
          }
        });
      }
      if (this.GET_LABELLED_TYPE == 3) {
        this.GET_SHEET_LINE_LIST.map((index) => {
          if (!index.calculate && index.subType == 1) {
            indexType = true;
          }
          if (index.calculate && index.subType == 1) {
            if (
              index.calculate.sizeWidth == 0 ||
              index.calculate.sizeHeight == 0
            ) {
              this.errString = "考号识别区域宽高为零，";
            }
          }
        });
      }
      if (this.GET_LABELLED_TYPE == 4) {
        this.GET_SHEET_LINE_LIST.map((index) => {
          if (!index.calculate) {
            indexType = true;
          }
          if (index.calculate) {
            // indexType = true;
            let examQuestionNumber = `选择题${
              index.questionNumList[0].examQuestionNumber
            }-${
              index.questionNumList[index.questionNumList.length - 1]
                .examQuestionNumber
            }`;
            // console.log(index.questionNumList);
            if (
              index.calculate.sizeWidth == 0 ||
              index.calculate.sizeHeight == 0
            ) {
              this.errString =
                this.errString + `${examQuestionNumber}识别区域宽高为零，`;
            }
          }
        });
      }
      if (this.GET_LABELLED_TYPE == 5) {
        this.GET_SHEET_LINE_LIST.map((index) => {
          if (
            index.chooseQuestionLocation &&
            !index.chooseQuestionLocation.calculate
          ) {
            indexType = true;
          }
          let examQuestionNumber = `选做题${
            index.questionNumList[0].examQuestionNumber
          }-${
            index.questionNumList[index.questionNumList.length - 1]
              .examQuestionNumber
          }`;

          if (
            index.chooseQuestionLocation &&
            index.chooseQuestionLocation.calculate
          ) {
            if (
              index.chooseQuestionLocation.calculate.sizeWidth == 0 ||
              index.chooseQuestionLocation.calculate.sizeHeight == 0
            ) {
              this.errString =
                this.errString + `${examQuestionNumber}识别区域宽高为零，`;
            }
          }
        });
      }
      if (indexType) {
        setTimeout(() => {
          this.submitIndexImg(type, id);
        }, 100);
        return;
      }
      // console.log(this.GET_SHEET_LINE_LIST);
      // 矫正主观题位置问题  因为异步修改数据未生效  最后同步一次json数据
      await new Promise((resolve) => {
        this.GET_SHEET_LINE_LIST.map((item, index) => {
          if (item.chooseQuestionLocation) {
            item.chooseQuestionLocation.leftTopWidthSize =
              item.chooseQuestionLocation.points[0].x;
            item.chooseQuestionLocation.leftTopHeightSize =
              item.chooseQuestionLocation.points[0].y;
          }
          if (item.leftTopWidthSize) {
            item.leftTopWidthSize = item.points[0].x;
            item.leftTopHeightSize = item.points[0].y;
          }
          // console.log(index);
          if (index == this.GET_SHEET_LINE_LIST.length - 1) {
            resolve();
          }
        });
        if (this.GET_SHEET_LINE_LIST.length == 0) {
          resolve();
        }
      });
      // console.log("11111111");
      this.markTypeList.forEach((item) => {
        if (item.value == this.GET_LABELLED_TYPE) {
          data[item.key] = JSON.stringify(this.GET_SHEET_LINE_LIST);
        }
      });
      this.saveExampapersheetmark(data, type);
    },
    // 提交需要保存的项
    saveExampapersheetmark(data, type) {
      this.btnLoading = true;
      if (this.$route.query.password) {
        data.password = "WENTONGCLOUD";
      }
      saveExampapersheetmark(data)
        .then(() => {
          let indexData = Object.assign({}, this.GET_INDEX_SHEET_MARK);
          delete data.password;
          Object.assign(indexData, data);
          // console.log(this.sheetMarkList);
          this.$store.commit("SET_INDEX_SHEET_MARK", indexData);
          // 提交成功后保存至原始数据
          this.$store.commit("EDIT_OLDSHEET_MARK_LIST");
          //
          if (!type) {
            this.checkSheet();
          }
          // 保存后 如果客观题或者主观题 发生改变  就重新获取一次主观题 客观题列表
          if (this.GET_LABELLED_TYPE == 4 || this.GET_LABELLED_TYPE == 5) {
            this.exampapersheetdetailV2page();
          }
          this.btnLoading = false;
          this.getErr();
        })
        .catch(() => {
          this.$store.commit("SET_LIST_GO_OLD");
          this.$refs.marksheet.setSheetLineList();
          this.btnLoading = false;
          this.getErr();
        });
    },
    getErr() {
      if (this.errString) {
        this.$alert(`${this.errString} 请调整后提交！`, "提示", {
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    // 切换答题卡
    checkSheet() {
      if (
        this.GET_INDEX_SHEET_MARK.id !=
        this.GET_SHEET_MARK_LIST[this.GET_SHEET_MARK_LIST.length - 1].id
      ) {
        try {
          // 如果不是在最后一页图  就跳转下一页
          this.GET_SHEET_MARK_LIST.map((item, i) => {
            // 判断当前是哪一张图
            if (item.id == this.GET_INDEX_SHEET_MARK.id) {
              // // 切换至下一张图
              this.$store.commit(
                "SET_INDEX_SHEET_MARK",
                this.GET_SHEET_MARK_LIST[i + 1]
              );
              this.markTypeList.forEach((index) => {
                if (index.value == this.GET_LABELLED_TYPE) {
                  this.$store.commit(
                    "SET_SHEET_LINE_LIST",
                    this.sheetMarkList[i + 1][index.key]
                      ? JSON.parse(this.sheetMarkList[i + 1][index.key])
                      : []
                  );
                  this.$refs.marksheet.checkImg();
                }
              });
              throw new Error();
            }
          });
        } catch {
          //
        }
      } else {
        // 当是最后一张图时  就开始判断  保存  开启下一步
        // let errItem = [];
        // this.GET_OLDSHEET_MARK_LIST.map((item, i) => {
        //   //    // 如果是当前项是定位点 判断下是否已提交
        //   if (this.GET_LABELLED_TYPE == 1) {
        //     if (!item.markLocation || item.markLocation.length < 10) {
        //       errItem.push(i + 1);
        //     }
        //   }
        //   if (this.GET_LABELLED_TYPE == 2) {
        //     if (!item.markTitle || item.markTitle.length < 10) {
        //       errItem.push(i + 1);
        //     }
        //   }
        // });
        // if (errItem.length > 0) {
        //   this.$message({
        //     message: `请返回第${errItem.join(",")}页，点击‘下一步’`,
        //     type: "warning",
        //   });
        //   return;
        // }
        if (this.GET_LABELLED_TYPE < 6) {
          this.$store.commit("SET_LABELLED_TYPE", this.GET_LABELLED_TYPE + 1);

          // 设置当前项的json
          this.setSheetList();
        }
      }
    },
    // 根据当前是哪一项设置当前项的json
    setSheetList() {
      this.$store.commit("SET_INDEX_SHEET_MARK", this.GET_SHEET_MARK_LIST[0]);
      this.markTypeList.forEach((item) => {
        if (item.value == this.GET_LABELLED_TYPE) {
          this.$store.commit(
            "SET_SHEET_LINE_LIST",
            this.sheetMarkList[0][item.key]
              ? JSON.parse(this.sheetMarkList[0][item.key])
              : []
          );
        }
      });
      if (
        this.questionList.length == 0 ||
        this.GET_SHEET_LINE_LIST.length == 0
      ) {
        return;
      }
      // 判断下  如果两组数据分数不统一 同步一次
      let sheetArr = JSON.parse(JSON.stringify(this.GET_SHEET_LINE_LIST));
      this.questionList.forEach((index) => {
        sheetArr.map((item) => {
          if (!item.questionNumList) {
            return;
          }
          item.questionNumList.map((i) => {
            if (i.value == index.examQuestionSheetValue) {
              i.score = index.score;
              if (i.scoreMiss) {
                i.scoreMiss = index.scoreMiss;
              }
            }
          });
        });
      });
      // console.log(sheetArr);
      this.$store.commit("SET_SHEET_LINE_LIST", sheetArr);
      this.$refs.marksheet.setSheetLineList();
    },
    // img切换
    checkImg(val) {
      if (this.btnLoading) {
        this.$message({
          type: "warning",
          message: "请等待请求完毕!",
        });
        return;
      }
      this.$store.commit("SET_INDEX_SHEET_MARK", val);
      this.markTypeList.forEach((item) => {
        if (item.value == this.GET_LABELLED_TYPE) {
          this.$store.commit(
            "SET_SHEET_LINE_LIST",
            val[item.key] ? JSON.parse(val[item.key]) : []
          );
        }
      });
      this.$refs.marksheet.checkImg();
    },
    exampapersheetmarkList() {
      let data = {
        sheetUuid: this.$route.query.sheetId,
      };
      exampapersheetmarkList(data).then((res) => {
        this.sheetMarkList = res.data.data;
        // 判断下 是否存在 点定位或者线定位
        let array = JSON.parse(JSON.stringify(this.sheetMarkList));
        let arr = [];
        array.forEach((item) => {
          if (!item.markLocation) {
            item.markLocation = "[]";
          }
          item.markLocation = JSON.parse(item.markLocation);
          arr = arr.concat(item.markLocation);
        });
        if (arr.length > 0) {
          if (arr[0].subType == 3) {
            this.locationType = 3;
          }
        }
        // console.log(this.sheetMarkList);
        this.$store.commit("SET_SHEET_MARK_LIST", this.sheetMarkList);
        this.$store.commit(
          "SET_OLDSHEET_MARK_LIST",
          JSON.parse(JSON.stringify(this.sheetMarkList))
        );
        this.setSheetList();
        this.$refs.marksheet.onloadImg();
      });
    },
    getExamPaperSheet() {
      getExamPaperSheet(this.$route.query.sheetId).then((res) => {
        // console.log(res);
        res.data.data.examName = res.data.data.name;
        res.data.data.subjectName = this.subjectOptions.filter(
          (i) => i.value == res.data.data.subjectId
        )[0].label;
        this.examInformation = res.data.data;
        this.exampapersheetdetailV2page();

        // this.indexUrl = this.examInformation.url1;
        // this.$refs.marksheet.onloadCanvas(this.indexUrl);
      });
    },
  },
};
</script>
<style lang="scss">
.qustion-order-list-sub.qustion-order-list {
  > div {
    &:first-child {
      width: 40%;
    }
  }
}
.sub-qustion-order-list.hidden-list {
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
  height: 0;
}
.sub-qustion-order-list {
  .question-box-list {
    display: flex;
    .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
    > div {
      margin-bottom: 12px;
      width: 120px;
      &:first-child {
        margin-right: 18px;
      }
    }
  }
}
.qustion-order-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  color: #5b625e;
  cursor: pointer;
  white-space: nowrap;
  .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  > div {
    width: 22%;
    flex-shrink: 0;
    &:first-child {
      width: 22%;
    }
    // margin-right: 18px;
    &:nth-child(3) {
      width: 18%;
    }
    &:last-child {
      margin-right: 0;
      // width: 50px;
    }
  }
}
</style>
<style lang="scss" scoped>
.thirdPartyAnswerSheet {
  min-width: 1540px;
  .qustion-order-list-box {
    max-height: 180px;
    // padding-right: 12px;
  }
  .btn-box {
    display: flex;
    margin: 12px auto;
    justify-content: center;
  }
  //检查的弹出框
  ::v-deep.el-drawer__header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #080a09;
  }
  ::v-deep.el-drawer__body {
    position: relative;
  }
  .btn-list-box {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    padding: 24px;
    background-color: #ffffff;
  }
  .poistion-box {
    padding: 24px;
    position: relative;
    height: calc(100vh - 165px);
    overflow-y: scroll;

    .img-box {
      margin: 24px 0;
      width: 100%;
      text-align: center;
      img {
        max-width: 100%;
      }
      // height: 260px;
    }
  }
  .location-operating-box {
    display: flex;
    align-items: flex-start;
    .check-sheet {
      width: 94px;
      // border-left: 10px solid #e2e2e2;
      height: calc(100vh - 150px);
      padding: 12px 0;
      text-align: center;
      position: relative;
      // overflow: hidden;
      .btn-check-sheet {
        // padding: 8px 4px;
        font-size: 12px;
        margin-bottom: 8px;
        padding: 12px 8px;
      }
      .zoom-box-btn {
        position: absolute;
        bottom: 12px;
        .el-button {
          margin-left: 0;
          margin-top: 26px;
        }
      }

      .check-list {
        font-size: 12px;
        padding: 8px 4px;
        margin: 0 auto;
        border-radius: 2px;
        border: 1px solid #aaaaaa;
        color: #aaaaaa;
        margin-bottom: 8px;
        display: inline-block;
        cursor: pointer;
      }
      .index-check {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #ffffff;
      }
    }
    .location-operating-area {
      // width: 100%;
      background: #f9f9f9;
      // border-bottom: 1px solid #e7e7e7;
      // display: flex;
      // justify-content: space-between;
      // flex-wrap: wrap;
      // align-items: flex-start;
    }
    .location-operating-box {
      display: flex;
      width: 100%;
      // align-items: center;
      justify-content: space-between;
    }
    .location-operating-tips {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 15px 24px;
      line-height: 14px;
      border-bottom: 1px solid #e7e7e7;
      color: #ff4141;
      .el-radio {
        margin-bottom: 0;
      }
    }
    .location-parameter {
      width: 380px;
      flex-shrink: 0;
      padding: 24px 24px 60px;
      // border: 1px solid #E7E7E7;
      border-left: 1px solid #e7e7e7;
      height: calc(100vh - 93px);
      position: relative;
      .special-check {
        // margin: 20px auto;
        // display: block;
        text-align: center;
        padding: 20px 0;
      }
      .location-parameter-btn {
        position: fixed;
        right: 0;
        bottom: 0;
        padding: 24px;
        width: 378px;
        background-color: #ffffff;
        z-index: 10;
        .el-button {
          width: 100%;
        }
      }
      .el-table {
        margin-bottom: 24px;
      }
      .qustion-list-box {
        .qustion-list {
          display: flex;
          margin-bottom: 12px;
          padding-right: 12px;
          > div {
            width: 30%;
            margin-right: 24px;
            ::v-deep .el-input__inner {
              padding: 0 5px;
            }
            &:first-child {
              width: 40%;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .location-parameter-item {
        margin-bottom: 12px;
        line-height: 14px;
      }
      .tips {
        color: red;
        font-size: 12px;
        margin-bottom: 12px;
        display: inline-block;
      }
      .location-parameter-title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 24px;
      }
      .tips-img {
        padding: 24px;
        border: 1px solid #dddddd;
        text-align: center;
      }
      .err-tips {
        margin: 24px 0;
        color: #ff0000;
      }
    }
  }
  .down-btn {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
}
</style>
